.admin-cc {
  color: #fff;
}

.sidebar {
  overflow-y: scroll !important;
}

.form-control.floating {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.focus-label {
  position: absolute;
  left: 0;
  top: 0;
  color: #495057;
  transition: 0.2s;
  cursor: text;
}

.form-control.floating:focus+.focus-label,
.form-control.floating:not(:placeholder-shown)+.focus-label {
  font-size: 12px;
  margin-top: -10px;
}

.new-focus .form-control {
  padding: 0 12px !important;
  color: #495057 !important;
}

.page-nation {
  display: flex;
  justify-content: flex-end;
}

.table thead tr th i {
  margin-left: 10px;
  font-size: 17px;
}

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-loading img {
  width: 15%;
}

.bar-icon {
  cursor: pointer;
}

.delete-action button {
  width: 100%;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('./assets/img/arr.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px !important;
}

select::-ms-expand {
  display: none;
}



/* scrollbar start*/

::-webkit-scrollbar {
  width: 3px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3157F6;
  border-radius: 10px;
}

/* scrollbar End*/

.page {
  display: grid;
  place-content: center;
  height: 80vh;
}

.page h1 {
  font-size: 40px;
}

.css-13cymwt-control{
  min-height: 44px !important;
}

.css-t3ipsp-control{
 min-height: 44px !important;
  border: 1px solid #fd7e14 !important;
  box-shadow: none !important;
}


.css-t3ipsp-control:hover{
 min-height: 44px !important;
  border: 1px solid #fd7e14 !important;
  box-shadow: none !important;
}

.css-1dimb5e-singleValue {
  margin-top: 2px !important;
}
.css-1jqq78o-placeholder{
  margin-top: 2px !important;
}
