@charset "UTF-8";

/****** UTILS ******/
/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
.css-equal-content,
.css-equal-heights {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.notifications .notification-list,
.equal-height-cards .pricing-box ul,
.experience-box .experience-list,
.personal-info,
.square-list,
.job-widget .job-post-det,
.job-list .job-list-footer ul,
.roles-menu ul,
.task-wrapper .task-list-body #task-list,
.team-members,
.attachments,
.my-video ul,
.call-icons .call-items,
.call-users ul,
.upload-list,
.custom-modal .modal-content .modal-body .chat-user-list,
.custom-modal .modal-content .modal-body .attach-files ul,
.sidebar-twocol.sidebar .sidebar-right ul,
.sidebar .sidebar-menu ul,
.two-col-bar .sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.css-equal-content {
  color: red;
  float: left;
}

.rating-list span a,
.rating-list,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .remove-icon,
.kanban-cont .kanban-list .kanban-header .kanban-action>a,
.account-page .main-wrapper,
.file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb,
.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-link,
.dash-widget .card-body .dash-widget-icon,
.error-page .main-wrapper,
.call-box .call-wrapper .call-items,
.call-box .call-wrapper,
.voice-call-avatar,
.files-list>li .files-cont .file-type .files-icon,
.chat-profile-img .edit-btn,
.punch-info .punch-hours,
.cat-upload,
.delete-table,
[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link,
.custom-modal .modal-content .modal-header,
.sidebar-twocol.sidebar .nav-link,
.avatar .avatar-title {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.job-list .job-list-det,
.rating-list span a,
.task-assign .task-complete-btn,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date a,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info a,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info,
.kanban-cont .kanban-list .kanban-header .kanban-action>a,
.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-info,
.board-view-header .pro-progress-bar,
.board-view-header .pro-teams .pro-team-members,
.board-view-header .pro-teams .pro-team-lead,
.leave-header,
.leave-item .leave-right,
.account-page,
.file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb,
.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-link,
.file-content .file-search .input-group-text,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-wrap .file-sidebar .file-search .input-group .input-group-text,
.file-wrap .file-sidebar .file-header,
.time-list .dash-stats-list,
.dash-section .dash-info-list .dash-card-container .dash-card-avatars,
.dash-section .dash-info-list .dash-card-container .dash-card-content,
.dash-section .dash-info-list .dash-card-container .dash-card-icon,
.dash-widget .card-body .dash-widget-icon,
.error-page .main-wrapper,
.error-page,
.call-box .call-wrapper .call-items,
.call-box .call-wrapper,
.voice-call-avatar,
.files-list>li .files-cont .file-type .files-icon,
.chat-profile-img .edit-btn,
.punch-info .punch-hours,
.cat-upload,
.asset-box .asset-history ul li,
.asset-box .assets-image ul,
.asset-box .asset-info,
.assign-head .assign-content,
.assign-head,
.assets-info li,
.assets-info.assets-image ul,
.delete-table,
.table-namesplit,
.table-imgname,
.split-head,
[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout=horizontal] body nav.greedy,
.sidebar-twocol.sidebar .sub-menu a,
.sidebar-twocol.sidebar .nav-link,
.sidebar .sidebar-menu ul li a,
.two-col-bar .sidebar-menu ul li a,
.header .user-menu.nav>li.flag-nav a,
.card .leave-inline-form,
.avatar .avatar-title {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer,
.kanban-cont .kanban-list .kanban-wrap .task-board-header,
.leave-header,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-wrap .file-sidebar .file-header,
.dash-statistics .stats-info p,
.att-statistics .stats-info p,
.assign-head,
.assets-info li,
.sidebar-twocol.sidebar .sub-menu a,
.card .card-title.with-switch {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.settings-icon span,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.notifications .notification-list,
.equal-height-cards .pricing-box ul,
.experience-box .experience-list,
.personal-info,
.square-list,
.job-widget .job-post-det,
.job-list .job-list-footer ul,
.roles-menu ul,
.task-wrapper .task-list-body #task-list,
.team-members,
.attachments,
.my-video ul,
.call-icons .call-items,
.call-users ul,
.upload-list,
.custom-modal .modal-content .modal-body .chat-user-list,
.custom-modal .modal-content .modal-body .attach-files ul,
.sidebar-twocol.sidebar .sidebar-right ul,
.sidebar .sidebar-menu ul,
.two-col-bar .sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.rating-list span a,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info,
.kanban-cont .kanban-list .kanban-header .kanban-action>a,
.board-view-header .pro-teams .pro-team-lead h4 .avatar-group,
.board-view-header .pro-teams,
.team-members,
.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-link,
.files-list>li .files-cont .file-type .files-icon,
.chat-profile-img .edit-btn,
.task-board-color .board-color-list .board-control,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.personal-info li,
.job-list .job-list-det,
.rating-list,
.avatar-dropdown .avatar-group .avatar,
.task-assign .task-complete-btn,
.chat-footer .message-bar .message-area .input-group,
.chat-footer .task-followers,
.chat-content-wrap .chat-wrap-inner .task-desc .task-textarea,
.chat-content-wrap .chat-wrap-inner .task-desc,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date a,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info a,
.chat-content-wrap .chat-wrap-inner .task-header,
.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-info,
.kanban-cont .kanban-list .kanban-wrap .task-board-header,
.kanban-cont,
.board-view-header .pro-progress-bar,
.board-view-header .pro-teams .pro-team-members,
.board-view-header .pro-teams .pro-team-lead,
.leave-header,
.leave-item .leave-right,
.leave-item .leave-row,
.account-page .main-wrapper,
.account-page,
.file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb,
.file-content .file-search .input-group-text,
.file-cont-wrap .file-cont-inner .file-cont-header .file-options,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-cont-wrap,
.file-wrap .file-sidebar .file-search .input-group .input-group-text,
.file-wrap .file-sidebar .file-header,
.file-wrap,
.time-list .dash-stats-list,
.time-list,
.dash-section .dash-info-list .dash-card-container .dash-card-avatars,
.dash-section .dash-info-list .dash-card-container .dash-card-content,
.dash-section .dash-info-list .dash-card-container .dash-card-icon,
.dash-section .dash-info-list .dash-card-container,
.dash-section .dash-info-list .dash-card,
.welcome-box,
.dash-statistics .stats-info p,
.dash-widget .card-body .dash-widget-info,
.dash-widget .card-body .dash-widget-icon,
.dash-widget .card-body,
.error-page .main-wrapper,
.error-page,
.call-box .call-wrapper .call-items,
.call-box .call-wrapper,
.voice-call-avatar,
.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble .chat-content .call-details,
.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder,
.att-statistics .stats-info p,
.punch-info .punch-hours,
.cat-upload,
.asset-box .asset-history ul li,
.asset-box .assets-image ul,
.asset-box .asset-info,
.assign-head .assign-content,
.assign-head,
.assets-info li,
.assets-info.assets-image ul,
.delete-table,
.table-namesplit,
.table-imgname,
.split-head,
[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout=horizontal] body nav.greedy,
.task-board-color .board-color-list,
.task-follower-list,
.sidebar-twocol.sidebar .sub-menu a,
.sidebar-twocol.sidebar .nav-link,
.sidebar-twocol.sidebar,
.sidebar .sidebar-menu ul li a,
.two-col-bar .sidebar-menu ul li a,
.sidebar .sidebar-menu ul li.menu-title,
.two-col-bar .sidebar-menu ul li.menu-title,
.header .user-menu.nav>li.flag-nav a,
.card .card-title.with-switch,
.card .leave-inline-form,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dash-section .dash-info-list .dash-card-container .dash-card-avatars,
.dash-widget .card-body .dash-widget-info,
.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer,
.kanban-cont .kanban-list .kanban-wrap .task-board-header,
.leave-header,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-wrap .file-sidebar .file-header,
.dash-statistics .stats-info p,
.att-statistics .stats-info p,
.assign-head,
.assets-info li,
.sidebar-twocol.sidebar .sub-menu a,
.card .card-title.with-switch {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.account-page .main-wrapper,
.file-cont-wrap,
.file-wrap,
.split-head,
.task-board-color .board-color-list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dash-section .dash-info-list .dash-card,
.dash-widget .card-body .dash-widget-info,
.voice-call-avatar,
.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder {
  -ms-flex-direction: column;
  flex-direction: column;
}

/******* BASE *******/
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot);
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons-Regular.woff) format("woff"), url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: 400;
  src: local("Circular Std Book"), url("../fonts/CircularStd-Book.woff") format("woff");
}

@font-face {
  font-family: "CircularStd";
  font-style: italic;
  font-weight: 400;
  src: local("Circular Std Book Italic"), url("../fonts/CircularStd-BookItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: 500;
  src: local("Circular Std Medium"), url("../fonts/CircularStd-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularStd";
  font-style: italic;
  font-weight: 500;
  src: local("Circular Std Medium Italic"), url("../fonts/CircularStd-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: 600;
  src: local("Circular Std Bold"), url("../fonts/CircularStd-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularStd";
  font-style: italic;
  font-weight: 600;
  src: local("Circular Std Bold Italic"), url("../fonts/CircularStd-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-weight: 800;
  src: local("Circular Std Black"), url("../fonts/CircularStd-Black.woff") format("woff");
}

@font-face {
  font-family: "CircularStd";
  font-style: italic;
  font-weight: 800;
  src: local("Circular Std Black Italic"), url("../fonts/CircularStd-BlackItalic.woff") format("woff");
}

h1 {
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  h1 {
    font-size: 27px;
  }
}

h2 {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  h2 {
    font-size: 23px;
  }
}

h3 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0.5rem;
}

@media (max-width: 1023px) {
  h3 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  h3 {
    font-size: 16px;
  }
}

h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0.5rem;
}

@media (max-width: 1023px) {
  h4 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  h4 {
    font-size: 16px;
  }
}

h5 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

@media (max-width: 1023px) {
  h5 {
    font-size: 14px;
  }
}

h6 {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0.5rem;
}

@media (max-width: 1023px) {
  h6 {
    font-size: 12px;
  }
}

body {
  font-family: "CircularStd", sans-serif;
  font-size: 15px;
  color: #1f1f1f;
  background-color: #f7f7f7;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  text-decoration: none;
  outline: none;
  color: #0d6efd;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:hover {
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:focus {
  outline: 0;
}

p {
  margin-bottom: 1rem;
}

strong {
  font-weight: 700;
}

/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
.css-equal-heights,
.css-equal-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.notifications .notification-list,
.equal-height-cards .pricing-box ul,
.experience-box .experience-list,
.personal-info,
.square-list,
.job-widget .job-post-det,
.job-list .job-list-footer ul,
.roles-menu ul,
.task-wrapper .task-list-body #task-list,
.team-members,
.attachments,
.my-video ul,
.call-icons .call-items,
.call-users ul,
.upload-list,
.custom-modal .modal-content .modal-body .chat-user-list,
.custom-modal .modal-content .modal-body .attach-files ul,
.sidebar-twocol.sidebar .sidebar-right ul,
.sidebar .sidebar-menu ul,
.two-col-bar .sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.css-equal-content {
  color: red;
  float: left;
}

.rating-list span a,
.rating-list,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .remove-icon,
.kanban-cont .kanban-list .kanban-header .kanban-action>a,
.account-page .main-wrapper,
.file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb,
.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-link,
.dash-widget .card-body .dash-widget-icon,
.error-page .main-wrapper,
.call-box .call-wrapper .call-items,
.call-box .call-wrapper,
.voice-call-avatar,
.files-list>li .files-cont .file-type .files-icon,
.chat-profile-img .edit-btn,
.punch-info .punch-hours,
.cat-upload,
.delete-table,
[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link,
.custom-modal .modal-content .modal-header,
.sidebar-twocol.sidebar .nav-link,
.avatar .avatar-title {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.job-list .job-list-det,
.rating-list span a,
.task-assign .task-complete-btn,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date a,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info a,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info,
.kanban-cont .kanban-list .kanban-header .kanban-action>a,
.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-info,
.board-view-header .pro-progress-bar,
.board-view-header .pro-teams .pro-team-members,
.board-view-header .pro-teams .pro-team-lead,
.leave-header,
.leave-item .leave-right,
.account-page,
.file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb,
.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-link,
.file-content .file-search .input-group-text,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-wrap .file-sidebar .file-search .input-group .input-group-text,
.file-wrap .file-sidebar .file-header,
.time-list .dash-stats-list,
.dash-section .dash-info-list .dash-card-container .dash-card-avatars,
.dash-section .dash-info-list .dash-card-container .dash-card-content,
.dash-section .dash-info-list .dash-card-container .dash-card-icon,
.dash-widget .card-body .dash-widget-icon,
.error-page .main-wrapper,
.error-page,
.call-box .call-wrapper .call-items,
.call-box .call-wrapper,
.voice-call-avatar,
.files-list>li .files-cont .file-type .files-icon,
.chat-profile-img .edit-btn,
.punch-info .punch-hours,
.cat-upload,
.asset-box .asset-history ul li,
.asset-box .assets-image ul,
.asset-box .asset-info,
.assign-head .assign-content,
.assign-head,
.assets-info li,
.assets-info.assets-image ul,
.delete-table,
.table-namesplit,
.table-imgname,
.split-head,
[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout=horizontal] body nav.greedy,
.sidebar-twocol.sidebar .sub-menu a,
.sidebar-twocol.sidebar .nav-link,
.sidebar .sidebar-menu ul li a,
.two-col-bar .sidebar-menu ul li a,
.header .user-menu.nav>li.flag-nav a,
.card .leave-inline-form,
.avatar .avatar-title {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer,
.kanban-cont .kanban-list .kanban-wrap .task-board-header,
.leave-header,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-wrap .file-sidebar .file-header,
.dash-statistics .stats-info p,
.att-statistics .stats-info p,
.assign-head,
.assets-info li,
.sidebar-twocol.sidebar .sub-menu a,
.card .card-title.with-switch {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.settings-icon span,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.notifications .notification-list,
.equal-height-cards .pricing-box ul,
.experience-box .experience-list,
.personal-info,
.square-list,
.job-widget .job-post-det,
.job-list .job-list-footer ul,
.roles-menu ul,
.task-wrapper .task-list-body #task-list,
.team-members,
.attachments,
.my-video ul,
.call-icons .call-items,
.call-users ul,
.upload-list,
.custom-modal .modal-content .modal-body .chat-user-list,
.custom-modal .modal-content .modal-body .attach-files ul,
.sidebar-twocol.sidebar .sidebar-right ul,
.sidebar .sidebar-menu ul,
.two-col-bar .sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.rating-list span a,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info,
.kanban-cont .kanban-list .kanban-header .kanban-action>a,
.board-view-header .pro-teams .pro-team-lead h4 .avatar-group,
.board-view-header .pro-teams,
.team-members,
.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-link,
.files-list>li .files-cont .file-type .files-icon,
.chat-profile-img .edit-btn,
.task-board-color .board-color-list .board-control,
.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.personal-info li,
.job-list .job-list-det,
.rating-list,
.avatar-dropdown .avatar-group .avatar,
.task-assign .task-complete-btn,
.chat-footer .message-bar .message-area .input-group,
.chat-footer .task-followers,
.chat-content-wrap .chat-wrap-inner .task-desc .task-textarea,
.chat-content-wrap .chat-wrap-inner .task-desc,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date a,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon,
.chat-content-wrap .chat-wrap-inner .task-header .task-due-date,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .remove-icon,
.chat-content-wrap .chat-wrap-inner .task-header .assignee-info a,
.chat-content-wrap .chat-wrap-inner .task-header,
.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-info,
.kanban-cont .kanban-list .kanban-wrap .task-board-header,
.kanban-cont,
.board-view-header .pro-progress-bar,
.board-view-header .pro-teams .pro-team-members,
.board-view-header .pro-teams .pro-team-lead,
.leave-header,
.leave-item .leave-right,
.leave-item .leave-row,
.account-page .main-wrapper,
.account-page,
.file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb,
.file-content .file-search .input-group-text,
.file-cont-wrap .file-cont-inner .file-cont-header .file-options,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-cont-wrap,
.file-wrap .file-sidebar .file-search .input-group .input-group-text,
.file-wrap .file-sidebar .file-header,
.file-wrap,
.time-list .dash-stats-list,
.time-list,
.dash-section .dash-info-list .dash-card-container .dash-card-avatars,
.dash-section .dash-info-list .dash-card-container .dash-card-content,
.dash-section .dash-info-list .dash-card-container .dash-card-icon,
.dash-section .dash-info-list .dash-card-container,
.dash-section .dash-info-list .dash-card,
.welcome-box,
.dash-statistics .stats-info p,
.dash-widget .card-body .dash-widget-info,
.dash-widget .card-body .dash-widget-icon,
.dash-widget .card-body,
.error-page .main-wrapper,
.error-page,
.call-box .call-wrapper .call-items,
.call-box .call-wrapper,
.voice-call-avatar,
.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble .chat-content .call-details,
.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder,
.att-statistics .stats-info p,
.punch-info .punch-hours,
.cat-upload,
.asset-box .asset-history ul li,
.asset-box .assets-image ul,
.asset-box .asset-info,
.assign-head .assign-content,
.assign-head,
.assets-info li,
.assets-info.assets-image ul,
.delete-table,
.table-namesplit,
.table-imgname,
.split-head,
[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link,
[data-layout=horizontal] body nav.greedy,
.task-board-color .board-color-list,
.task-follower-list,
.sidebar-twocol.sidebar .sub-menu a,
.sidebar-twocol.sidebar .nav-link,
.sidebar-twocol.sidebar,
.sidebar .sidebar-menu ul li a,
.two-col-bar .sidebar-menu ul li a,
.sidebar .sidebar-menu ul li.menu-title,
.two-col-bar .sidebar-menu ul li.menu-title,
.header .user-menu.nav>li.flag-nav a,
.card .card-title.with-switch,
.card .leave-inline-form,
.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dash-section .dash-info-list .dash-card-container .dash-card-avatars,
.dash-widget .card-body .dash-widget-info,
.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

.kanban-cont .kanban-list .kanban-header,
.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer,
.kanban-cont .kanban-list .kanban-wrap .task-board-header,
.leave-header,
.file-cont-wrap .file-cont-inner .file-cont-header,
.file-wrap .file-sidebar .file-header,
.dash-statistics .stats-info p,
.att-statistics .stats-info p,
.assign-head,
.assets-info li,
.sidebar-twocol.sidebar .sub-menu a,
.card .card-title.with-switch {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.account-page .main-wrapper,
.file-cont-wrap,
.file-wrap,
.split-head,
.task-board-color .board-color-list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dash-section .dash-info-list .dash-card,
.dash-widget .card-body .dash-widget-info,
.voice-call-avatar,
.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder {
  -ms-flex-direction: column;
  flex-direction: column;
}

.col {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  position: relative;
  min-height: 1px;
}

@media (min-width: 320px) {
  .col.col-xs-12 {
    width: 100%;
  }

  .col.col-xs-11 {
    width: 91.66666667%;
  }

  .col.col-xs-10 {
    width: 83.33333333%;
  }

  .col.col-xs-9 {
    width: 75%;
  }

  .col.col-xs-8 {
    width: 66.66666667%;
  }

  .col.col-xs-7 {
    width: 58.33333333%;
  }

  .col.col-xs-6 {
    width: 50%;
  }

  .col.col-xs-5 {
    width: 41.66666667%;
  }

  .col.col-xs-4 {
    width: 33.33333333%;
  }

  .col.col-xs-3 {
    width: 25%;
  }

  .col.col-xs-2 {
    width: 16.66666667%;
  }

  .col.col-xs-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 568px) {
  .col.col-sm-12 {
    width: 100%;
  }

  .col.col-sm-11 {
    width: 91.66666667%;
  }

  .col.col-sm-10 {
    width: 83.33333333%;
  }

  .col.col-sm-9 {
    width: 75%;
  }

  .col.col-sm-8 {
    width: 66.66666667%;
  }

  .col.col-sm-7 {
    width: 58.33333333%;
  }

  .col.col-sm-6 {
    width: 50%;
  }

  .col.col-sm-5 {
    width: 41.66666667%;
  }

  .col.col-sm-4 {
    width: 33.33333333%;
  }

  .col.col-sm-3 {
    width: 25%;
  }

  .col.col-sm-2 {
    width: 16.66666667%;
  }

  .col.col-sm-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 768px) {
  .col.col-md-12 {
    width: 100%;
  }

  .col.col-md-11 {
    width: 91.66666667%;
  }

  .col.col-md-10 {
    width: 83.33333333%;
  }

  .col.col-md-9 {
    width: 75%;
  }

  .col.col-md-8 {
    width: 66.66666667%;
  }

  .col.col-md-7 {
    width: 58.33333333%;
  }

  .col.col-md-6 {
    width: 50%;
  }

  .col.col-md-5 {
    width: 41.66666667%;
  }

  .col.col-md-4 {
    width: 33.33333333%;
  }

  .col.col-md-3 {
    width: 25%;
  }

  .col.col-md-2 {
    width: 16.66666667%;
  }

  .col.col-md-1 {
    width: 8.33333333%;
  }
}

@media (min-width: 1024px) {
  .col.col-lg-12 {
    width: 100%;
  }

  .col.col-lg-11 {
    width: 91.66666667%;
  }

  .col.col-lg-10 {
    width: 83.33333333%;
  }

  .col.col-lg-9 {
    width: 75%;
  }

  .col.col-lg-8 {
    width: 66.66666667%;
  }

  .col.col-lg-7 {
    width: 58.33333333%;
  }

  .col.col-lg-6 {
    width: 50%;
  }

  .col.col-lg-5 {
    width: 41.66666667%;
  }

  .col.col-lg-4 {
    width: 33.33333333%;
  }

  .col.col-lg-3 {
    width: 25%;
  }

  .col.col-lg-2 {
    width: 16.66666667%;
  }

  .col.col-lg-1 {
    width: 8.33333333%;
  }
}

/******* COMPONENTS ******/
.apply-btn {
  background: #00c5fb;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  border-color: transparent;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
}

.btn:hover {
  border-color: #cccccc;
}

.form-control:focus {
  border-color: #ff9b44;
  box-shadow: none;
}

.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: #cccccc;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: #cccccc;
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
  z-index: 3;
}

.page-item.disabled .page-link {
  background-color: #ffffff;
}

.personal-info li .text .avatar-box {
  position: relative;
  top: 0;
}

.chat-footer .message-bar .message-area .btn:hover {
  border-color: transparent;
}

.chat-footer .message-bar .message-area .btn i {
  color: #ffffff;
}

.btn.btn-assign:hover,
.btn.btn-assign:active {
  border-color: #ff9b44;
}

.btn.btn-assign:active {
  background-color: #ff9b44;
}

[data-layout-mode=orange] .top-nav-search .form-control::-moz-placeholder {
  color: #eeeeee !important;
}

.btn.add-btn:active {
  background: #ff9b44;
  color: #ffffff;
}

.header .has-arrow .dropdown-toggle.job-view-flag:after {
  border-bottom: 2px solid #333333;
  border-right: 2px solid #333333;
}

.top-nav-search .form-control.job-view-search::-moz-placeholder {
  color: #333333 !important;
}

[data-layout-mode=light] .top-nav-search .form-control::-moz-placeholder {
  color: #333333 !important;
}

table td a.download-offer:hover {
  color: #ffffff;
}

table td .dropdown.action-label a.btn-rounded.dropdown-toggle {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

table td .dropdown.action-label a.btn-rounded.dropdown-toggle::after {
  margin-left: 0;
}

.comp-section .card-title {
  margin-bottom: 0.5rem;
}

.comp-section .pagination {
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.setting-pref-btn {
  height: 44px;
  font-size: 16px;
  padding: 10px 15px;
}

.offcanvas-footer button.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
}

.files-cont .files-action .dropdown-action a:hover {
  border-color: transparent;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.custom_check {
  display: inline-block;
  position: relative;
  font-size: 14px !important;
  margin-bottom: 15px;
  padding-left: 0;
  cursor: pointer;
  font-weight: 400 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;
}

.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom_check input:checked~.checkmark {
  background-color: #ff9b44;
  border-color: #ff9b44;
}

.custom_check .checkmark {
  position: absolute;
  top: 3px;
  left: -6px;
  height: 18px;
  width: 18px;
  border: 1px solid #E4E4E8;
  background-color: #fff;
  border-radius: 5px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom_check input:checked~.checkmark:after {
  display: block;
}

.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 6 Free" !important;
  font-weight: 900;
  position: absolute;
  display: none;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 11px;
}

.continue-btn:focus,
.cancel-btn:focus {
  color: #ffffff !important;
}

.btn-info.download-offer,
.btn.btn-info {
  color: #ffffff;
}

.swal2-confirm {
  margin-right: 10px;
}

.card .card-header {
  padding: 20px;
}

.card .card-header .card-title {
  margin-bottom: 0;
}

.card h6.counter {
  font-size: 16px;
}

.card h6.card-text {
  font-size: 14px;
}

.select2-container {
  min-width: 100% !important;
  width: auto !important;
  z-index: 99;
  padding: 0 !important;
}

.btn.btn-outline-primary {
  border-color: #ff9b44;
}

.nav-pills .nav-link {
  color: #1f1f1f;
}

.nav-pills .nav-link.active {
  background: #ff9b44;
  color: #ffffff;
}

.card-buttons .card-link {
  color: #ff9b44;
}

.card .card-body {
  padding: 20px;
}

ul.bullets {
  padding-left: 20px;
}

ul.bullets li {
  list-style-type: disc;
}

.card ol {
  padding-left: 20px;
}

.progress-example .progress .progress-bar {
  background-color: #ff9b44;
}

/****** LAYOUT ******/
.avatar {
  background-color: transparent;
  color: #ffffff;
  display: inline-block;
  font-weight: 500;
  height: 34px;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 34px;
  position: relative;
  white-space: nowrap;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.avatar.avatar-xs {
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-flex;
}

.avatar>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.avatar .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #0d6efd;
  color: #ffffff;
}

.avatar.avatar-online::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #55ce63;
}

.avatar.avatar-offline::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #f62d51;
}

.avatar.avatar-away::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #ffbc34;
}

.avatar .border {
  border-width: 3px !important;
}

.avatar .rounded {
  border-radius: 6px !important;
}

.avatar .avatar-title {
  font-size: 18px;
}

.avatar .avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.avatar .avatar-xs .border {
  border-width: 2px !important;
}

.avatar .avatar-xs .rounded {
  border-radius: 4px !important;
}

.avatar .avatar-xs .avatar-title {
  font-size: 12px;
}

.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-sm .border {
  border-width: 3px !important;
}

.avatar-sm .rounded {
  border-radius: 4px !important;
}

.avatar-sm .avatar-title {
  font-size: 15px;
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.avatar-lg .border {
  border-width: 3px !important;
}

.avatar-lg .rounded {
  border-radius: 8px !important;
}

.avatar-lg .avatar-title {
  font-size: 24px;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xl .border {
  border-width: 4px !important;
}

.avatar-xl .rounded {
  border-radius: 8px !important;
}

.avatar-xl .avatar-title {
  font-size: 28px;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-xxl .border {
  border-width: 6px !important;
}

@media (min-width: 768px) {
  .avatar-xxl .border {
    border-width: 4px !important;
  }
}

.avatar-xxl .rounded {
  border-radius: 8px !important;
}

@media (min-width: 768px) {
  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }
}

.avatar-xxl .avatar-title {
  font-size: 30px;
}

@media (min-width: 768px) {
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}

@media (min-width: 768px) {

  .avatar-xxl.avatar-away::before,
  .avatar-xxl.avatar-offline::before,
  .avatar-xxl.avatar-online::before {
    border-width: 4px;
  }
}

.avatar-group .avatar+.avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

.avatar-group .avatar-xs+.avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-group .avatar-sm+.avatar-sm {
  margin-left: -0.625rem;
}

.avatar-group .avatar-lg+.avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.leave-employee-table .avatar {
  line-height: normal;
}

.leave-employee-table .avatar-xs {
  line-height: normal;
}

.avatar-group .avatar {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.btn.focus,
.btn:focus {
  box-shadow: unset;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.btn-white {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #272b41;
}

.bg-primary,
.badge-primary {
  background-color: #ff9b44 !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #fd8e2d !important;
}

.bg-success,
.badge-success {
  background-color: #55ce63 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #699834 !important;
}

.bg-info,
.badge-info {
  background-color: #009efb !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.bg-danger,
.badge-danger {
  background-color: #f62d51 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}

.bg-white {
  background-color: #ffffff;
}

.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}

.text-primary {
  color: #ff9b44 !important;
}

.dropdown-menu li>a.text-primary {
  color: #ff9b44 !important;
}

.dropdown-menu li>a.text-success {
  color: #699834 !important;
}

.dropdown-menu li>a.text-danger {
  color: #f62d51 !important;
}

.dropdown-menu li>a.text-info {
  color: #009efb !important;
}

.dropdown-menu li>a.text-warning {
  color: #ffbc34 !important;
}

.dropdown-menu li>a.text-purple {
  color: #7460ee !important;
}

.text-success {
  color: #699834 !important;
}

.text-danger {
  color: #f62d51 !important;
  }
  
  .text-dangerous {
    color: #f62d51 !important;
    white-space: nowrap;
    position: absolute !important;
    top: 100%;
}

.text-info {
  color: #009efb !important;
}

.text-warning {
  color: #ffbc34 !important;
}

.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

.btn-primary {
  background-color: #ff9b44;
  border: 1px solid #ff9b44;
  color: #ffffff !important;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
  color: #ffffff;
  background-color: #fd8e2d;
  border-color: #fd8e2d;
}

.btn-primary:focus .open>.dropdown-toggle.btn-primary,
.btn-primary:hover .open>.dropdown-toggle.btn-primary,
.btn-primary:active .open>.dropdown-toggle.btn-primary,
.btn-primary.active .open>.dropdown-toggle.btn-primary {
  background-color: #fd8e2d;
  border: 1px solid #fd8e2d;
}

.btn-primary.active.focus .open>.dropdown-toggle.btn-primary.focus,
.btn-primary.active.focus .open>.dropdown-toggle.btn-primary:focus,
.btn-primary.active.focus .open>.dropdown-toggle.btn-primary:hover,
.btn-primary.active:focus .open>.dropdown-toggle.btn-primary.focus,
.btn-primary.active:focus .open>.dropdown-toggle.btn-primary:focus,
.btn-primary.active:focus .open>.dropdown-toggle.btn-primary:hover,
.btn-primary.active:hover .open>.dropdown-toggle.btn-primary.focus,
.btn-primary.active:hover .open>.dropdown-toggle.btn-primary:focus,
.btn-primary.active:hover .open>.dropdown-toggle.btn-primary:hover,
.btn-primary .focus.focus .open>.dropdown-toggle.btn-primary.focus,
.btn-primary .focus.focus .open>.dropdown-toggle.btn-primary:focus,
.btn-primary .focus.focus .open>.dropdown-toggle.btn-primary:hover,
.btn-primary .focus:focus .open>.dropdown-toggle.btn-primary.focus,
.btn-primary .focus:focus .open>.dropdown-toggle.btn-primary:focus,
.btn-primary .focus:focus .open>.dropdown-toggle.btn-primary:hover,
.btn-primary .focus:hover .open>.dropdown-toggle.btn-primary.focus,
.btn-primary .focus:hover .open>.dropdown-toggle.btn-primary:focus,
.btn-primary .focus:hover .open>.dropdown-toggle.btn-primary:hover {
  background-color: #fd8e2d;
  border: 1px solid #fd8e2d;
}

.btn-primary:active:focus,
.btn-primary:active:hover {
  background-color: #fd8e2d;
  border: 1px solid #fd8e2d;
}

.btn-primary .active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled) {
  background-color: #fd8e2d;
  border-color: #fd8e2d;
  color: #ffffff;
}

.btn-primary .active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.btn-primary .show>.btn-primary.dropdown-toggle {
  background-color: #fd8e2d;
  border-color: #fd8e2d;
  color: #ffffff;
}

.btn-primary .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #ffffff;
}

.btn-secondary.active:focus :not(:disabled):not(.disabled),
.btn-secondary:active:focus :not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.show>.btn-success.dropdown-toggle {
  background-color: #699834;
  border-color: #699834;
  color: #ffffff;
}

.show>.btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.show>.btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #ffffff;
}

.show>.btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}

.show>.btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #ffffff;
}

.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}

.show>.btn-danger.dropdown-toggle {
  background-color: #e63333;
  border-color: #e63333;
  color: #ffffff;
}

.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}

.show>.btn-light.dropdown-toggle:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}

.show>.btn-outline-primary.dropdown-toggle {
  background-color: #ff9b44;
  border-color: #ff9b44;
}

.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.show>.btn-outline-success.dropdown-toggle {
  background-color: #55ce63;
  border-color: #55ce63;
}

.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.show>.btn-outline-info.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
}

.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.show>.btn-outline-danger.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  background-color: #55ce63;
  border: 1px solid #55ce63;
}

.btn-success:hover,
.btn-success:focus .btn-success.active,
.btn-success:active {
  background-color: #699834;
  border: 1px solid #699834;
  color: #ffffff;
}

.btn-success .open>.dropdown-toggle.btn-success {
  background-color: #699834;
  border: 1px solid #699834;
  color: #ffffff;
}

.btn-success .open>.dropdown-toggle.btn-success.focus,
.btn-success .open>.dropdown-toggle.btn-success:hover,
.btn-success .open>.dropdown-toggle.btn-success:focus {
  background-color: #699834;
  border: 1px solid #699834;
}

.btn-success.active:focus,
.btn-success.active.focus,
.btn-success.active:hover,
.btn-success.focus,
.btn-success:active:focus,
.btn-success:active:hover {
  background-color: #699834;
  border: 1px solid #699834;
}

.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled) {
  background-color: #699834;
  border-color: #699834;
  color: #ffffff;
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #55ce63;
  border-color: #55ce63;
  color: #ffffff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:focus,
.btn-info.active.focus,
.btn-info.active:hover,
.btn-info.focus,
.btn-info:active:focus,
.btn-info:active:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled) {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #ffffff;
}

.btn-info.active:not(:disabled):not(.disabled):focus,
.btn-info:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #ffffff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:focus,
.btn-warning.active.focus,
.btn-warning.active:hover,
.btn-warning.focus,
.btn-warning:active:focus,
.btn-warning:active:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled) {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #ffffff;
}

.btn-warning.active:not(:disabled):not(.disabled):focus,
.btn-warning:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #ffffff;
}

.btn-danger {
  background-color: #f62d51;
  border: 1px solid #f62d51;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active:focus,
.btn-danger.active.focus,
.btn-danger.active:hover,
.btn-danger.focus,
.btn-danger:active:focus,
.btn-danger:active:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled) {
  background-color: #e63333;
  border-color: #e63333;
  color: #ffffff;
}

.btn-danger.active:not(:disabled):not(.disabled):focus,
.btn-danger:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #ffffff;
}

.open>.dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.open>.dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.open>.dropdown-toggle.btn-danger {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #ff9b44;
  border-color: #ff9b44;
}

.btn-outline-primary:hover {
  background-color: #ff9b44;
  border-color: #ff9b44;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ff9b44;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #ff9b44;
  border-color: #ff9b44;
}

.btn-outline-success {
  color: #55ce63;
  border-color: #55ce63;
}

.btn-outline-success:hover {
  background-color: #55ce63;
  border-color: #55ce63;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #55ce63;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #55ce63;
  border-color: #55ce63;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:hover {
  color: #ffffff;
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #009efb;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #f62d51;
  border-color: #f62d51;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #f62d51;
  border-color: #f62d51;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #f62d51;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #f62d51;
  border-color: #f62d51;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ababab;
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus,
.pagination>.active>span,
.pagination>.active>span:hover,
.pagination>.active>span:focus {
  background-color: #ff9b44;
  border-color: #ff9b44;
}

.pagination>.active>a,
.pagination>.active>span {
  color: #ff9b44;
}

.pagination>li>a,
.pagination>li>span {
  color: #ff9b44;
}

.page-link:hover {
  color: #ff9b44;
}

.page-link:focus {
  box-shadow: unset;
}

.page-item.active .page-link {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #fff;
}

.dropdown-menu {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inherit;
  font-size: 14px;
  border-radius: 3px;
  transform-origin: left top 0;
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
}

.card {
  border: 1px solid #f0f0f0;
  margin-bottom: 1.875rem;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ff9b44;
}

.navbar-nav__.open--.dropdown-menu {
  border: 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #0d6efd;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: #0d6efd;
}

.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-lg {
  font-size: 20px !important;
}

.text-xl {
  font-size: 24px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-r-10 {
  margin-right: 10px;
}

.breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

@media (max-width: 575px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb.active {
  color: #6c757d;
}

.breadcrumb a {
  color: #333333;
}

.breadcrumb .breadcrumb-item a {
  color: #333333;
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.comp-section {
  margin: 0;
  padding: 10px 0;
}

.comp-section .btn {
  margin: 0 0 5px;
}

@media (max-width: 575px) {
  .comp-section .breadcrumb {
    display: flex;
  }
}

.breadcrumb-line .breadcrumb-item:before {
  content: "-";
}

.breadcrumb-line .breadcrumb-item:first-child:before {
  content: "";
}

.breadcrumb-dot .breadcrumb-item:before {
  content: "•";
}

.breadcrumb-dot .breadcrumb-item:first-child:before {
  content: "";
}

.breadcrumb-separatorless .breadcrumb-item:before {
  content: "";
}

.breadcrumb {
  margin: 0;
}

.breadcrumb-colored {
  margin: 0 0 10px;
  padding: 12px 16px;
  border-radius: 4px;
}

.breadcrumb-colored li a {
  color: #ffffff;
}

.breadcrumb-colored .breadcrumb-item.active {
  color: #ffffff;
  opacity: 0.8;
}

.breadcrumb-colored .breadcrumb-item+.breadcrumb-item::before {
  color: #ffffff;
}

.twitter-bs-wizard-pager-link {
  display: inline-block;
}

.page-header h5 {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 0px;
}

.page-wrapper {
  left: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
  margin: 0 0 0 230px;
  padding: 60px 0 0;
}

@media (max-width: 991px) {
  .page-wrapper {
    margin: 0;
    padding: 60px 0 0;
  }
}

.page-wrapper .content {
  padding: 30px;
}

@media (max-width: 575px) {
  .page-wrapper .content {
    padding: 15px;
  }
}

.page-wrapper .content .page-header {
  margin-bottom: 1.875rem;
}

.page-wrapper .content .page-header .page-title {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .page-wrapper .content .page-header .page-title {
    font-size: 18px;
  }
}

.logo2 {
  display: none;
}

@media (max-width: 991px) {
  .filter-row {
    margin-bottom: 15px;
  }
}

.card {
  border: 1px solid #ededed;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.card .leave-inline-form {
  min-height: 44px;
}

.card .leave-inline-form .input-group-text {
  background-color: #f7f7f7;
  border-color: #e3e3e3;
}

@media (max-width: 575px) {
  .card .leave-inline-form {
    display: block;
  }
}

.card .card-title {
  color: #1f1f1f;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.form-check-inline .form-check-label {
  line-height: 25px;
}

.bg-inverse-danger {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-inverse-success {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-inverse-info {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}

.bg-inverse-warning {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.progress-xs {
  height: 4px;
}

.progress .progress-bar {
  background-color: #25628F;
}

.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  width: 200%;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.onoffswitch-switch {
  background: #ffffff;
  display: block;
  height: 20px;
  margin: 5px;
  width: 20px;
  position: absolute;
  top: 0;
  right: 43px;
  bottom: 0;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.block {
  display: block !important;
}

.text-muted {
  color: #8e8e8e !important;
}

.input-block {
  margin-bottom: 1rem;
}

input[type=file].form-control {
  height: 44px;
}

.form-text {
  color: #999;
  font-size: 12px;
  margin: 7px 0;
}

.form-control {
  border-color: #e3e3e3;
  box-shadow: none;
  font-size: 15px;
  height: 44px;
  line-height: 2;
}

.form-control.search-message {
  height: 38px;
  margin-left: 5px;
  width: 180px;
  border-radius: 4px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.cal-icon {
  position: relative;
  width: 100%;
}

.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin: auto;
  position: absolute;
  top: 10px;
  right: 15px;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.form-focus {
  height: 50px;
  position: relative;
}

.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .cal-icon:after {
  top: 15px;
}

.form-focus .focus-label {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
  pointer-events: none;
  z-index: 1;
  color: #888888;
  margin-bottom: 0;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  top: -8px;
  left: 12px;
  transform-origin: left top;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform: translate3d(0, 22px, 0) scale(1);
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
}

.form-focus .form-control {
  height: 50px;
  padding: 21px 12px 6px;
}

.form-focus .form-control:focus {
  border-color: #ff9b44;
}

.form-focus.focused .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}

.onoffswitch {
  margin-left: auto;
  position: relative;
  width: 73px;
  -webkit-user-select: none;
}

.onoffswitch .onoffswitch-checkbox {
  display: none;
}

.onoffswitch .onoffswitch-inner:after {
  content: "OFF";
  padding-right: 14px !important;
  background-color: #cccccc;
  color: #ffffff;
  text-align: right;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  width: 50%;
}

.onoffswitch .onoffswitch-inner:before {
  background-color: #55ce63;
  color: #ffffff;
  content: "ON";
  padding-left: 14px;
}

.onoffswitch .onoffswitch-inner:after {
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  width: 50%;
}

.onoffswitch .onoffswitch-inner:before {
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  width: 50%;
}

.onoffswitch .onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 20px;
}

.onoffswitch .onoffswitch-checkbox .onoffswitch-inner {
  display: block;
  margin-left: -100%;
  width: 200%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.onoffswitch .onoffswitch-checkbox:checked+.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 20px;
}

.onoffswitch .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner:before {
  background-color: #55ce63;
  color: #ffffff;
  content: "ON";
  padding-left: 14px;
}

.onoffswitch .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.status-toggle {
  float: right;
}

.status-toggle .check {
  display: block;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  margin: 0;
  padding: 0;
}

.status-toggle .check:checked+.checktoggle {
  background-color: #55ce63;
}

.status-toggle .check:checked+.checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}

.status-toggle .checktoggle {
  background-color: #e0001a;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
  border-radius: 12px;
}

.status-toggle .checktoggle:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: translate(5px, -50%);
  -webkit-transform: translate(5px, -50%);
  -ms-transform: translate(5px, -50%);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.settings-icon {
  z-index: 99999;
  position: fixed;
  right: 20px;
  bottom: 20px;
}

@media (max-width: 991px) {
  .settings-icon {
    display: none;
  }
}

.settings-icon span {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  cursor: pointer;
  color: #ffffff;
  font-size: 24px;
  background-color: #ff9b44;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.header {
  background: #ff9b44;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  border-bottom: 1px solid transparent;
  height: 60px;
  z-index: 1044;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.header .header-left {
  float: left;
  height: 60px;
  position: relative;
  text-align: center;
  width: 230px;
  z-index: 1;
  padding: 0 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}

@media (max-width: 991px) {
  .header .header-left {
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .header #toggle_btn {
    color: #999;
    float: left;
    font-size: 26px;
    line-height: 70px;
    padding: 0 10px;
  }

  .header #toggle_btn .bar-icon {
    display: inline-block;
    width: 21px;
  }

  .header #toggle_btn .bar-icon span {
    background-color: #ffffff;
    display: block;
    float: left;
    height: 2px;
    width: 21px;
    margin: 0 0 5px;
    border-radius: 50px;
  }

  .header #toggle_btn .bar-icon span:nth-child(2) {
    width: 15px;
  }
}

.header .page-title-box {
  float: left;
  height: 60px;
  margin-bottom: 0;
  padding: 17px 20px;
  border-radius: 0;
}

@media (max-width: 991px) {
  .header .page-title-box {
    display: none;
  }
}

.header .page-title-box h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.header .mobile_btn {
  display: none;
  float: left;
}

@media (max-width: 991px) {
  .header .mobile_btn {
    color: #ffffff;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    width: 60px;
    z-index: 10;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.header .responsive-search {
  display: none;
  color: #ffffff;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
}

@media (max-width: 991px) {
  .header .responsive-search {
    display: block;
  }
}

.header .top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 230px;
}

@media (max-width: 1199px) {
  .header .top-nav-search form {
    width: 145px;
  }
}

@media (max-width: 991px) {
  .header .top-nav-search form {
    display: none;
  }
}

.header .top-nav-search form .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #ffffff;
  height: 40px;
  padding: 10px 50px 10px 15px;
  border-radius: 50px;
}

.header .top-nav-search form .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.header .top-nav-search form .btn {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  min-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 15px;
}

@media (max-width: 991px) {
  .header .top-nav-search.active form {
    display: block;
    position: absolute;
    left: 0;
  }

  .header .top-nav-search.active form .form-control {
    background-color: #ffffff;
    border-color: #e3e3e3;
    color: #333333;
  }

  .header .top-nav-search.active form .btn {
    background-color: transparent;
    border-color: transparent;
    color: #999;
    min-height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 15px;
  }
}

.header .user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
}

@media (max-width: 575px) {
  .header .user-menu {
    display: none;
  }
}

.header .user-menu .dropdown-toggle::after {
  border: 0;
}

.header .user-menu.nav>li>a {
  color: #ffffff;
  font-size: 15px;
  line-height: 60px;
  height: 60px;
  padding: 0 15px;
}

@media (max-width: 1199px) {
  .header .user-menu.nav>li>a {
    padding: 0 9px;
  }
}

.header .user-menu.nav>li>a .badge {
  color: #ffffff;
  font-weight: 700;
  background-color: #7460ee;
  position: absolute;
  top: 6px;
  right: 3px;
}

.header .user-menu.nav>li>a i {
  font-size: 20px;
  line-height: 60px;
}

.header .user-menu.nav>li.flag-nav a img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.header .user-menu.nav>li.flag-nav a .dropdown-menu {
  max-height: 150px;
  min-width: 120px;
  overflow-y: auto;
}

.header .user-menu.nav>li.flag-nav a .dropdown-menu .dropdown-item {
  padding: 5px 15px;
}

.header .user-menu.nav>li.flag-nav .dropdown-menu {
  max-height: 150px;
  min-width: 120px;
  overflow-y: auto;
}

.header .user-menu.nav>li.flag-nav .dropdown-menu .dropdown-item {
  color: #171717;
  padding: 5px 15px;
}

@media (max-width: 991px) {
  .header .has-arrow .dropdown-toggle>span:nth-child(2) {
    display: none;
  }

  .header .has-arrow .dropdown-toggle:after {
    display: none !important;
  }
}

.header .has-arrow .dropdown-toggle[aria-expanded=true]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}

.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  content: "";
  display: inline-block;
  pointer-events: none;
  width: 8px;
  height: 8px;
  vertical-align: 2px;
  transform-origin: 66% 66%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.header .user-img {
  display: inline-block;
  position: relative;
}

.header .user-img img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
}

.header .user-img .status {
  border: 2px solid #ffffff;
  height: 10px;
  width: 10px;
  background-color: #55ce63;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 1px;
  border-radius: 50%;
  display: inline-block;
}

.header .mobile-user-menu {
  color: #ffffff;
  display: none;
  float: right;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  text-align: right;
  width: 60px;
  z-index: 10;
  padding: 0 20px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 575px) {
  .header .mobile-user-menu {
    display: block;
  }
}

.header .mobile-user-menu a {
  color: #ffffff;
  padding: 0;
}

.header .mobile-user-menu a::after {
  display: none;
}

.header .mobile-user-menu .dropdown-menu {
  padding: 0;
}

.header .mobile-user-menu .dropdown-menu a {
  color: #333333;
  border-bottom: 1px solid #d5d5d5;
  line-height: 40px;
  padding: 0 10px;
}

#layout-position .radio input:checked+label {
  background: #fd8e2d;
  color: #fff;
}

@media (min-width: 992px) {
  .mini-sidebar .header-left {
    width: 60px;
    padding: 0 5px;
  }

  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 30px;
    width: auto;
  }

  .mini-sidebar .menu-arrow {
    display: none !important;
  }

  .mini-sidebar .chat-user {
    display: none !important;
  }

  .mini-sidebar.expand-menu .menu-arrow {
    display: block !important;
  }

  .mini-sidebar.expand-menu .chat-user {
    display: block !important;
  }

  .mini-sidebar.expand-menu .badge {
    display: inline-block !important;
  }

  .mini-sidebar .noti-dot:before {
    display: none;
  }
}

.sidebar,
.two-col-bar {
  background-color: #34444c;
  border-right: 1px solid transparent;
  margin-top: 0;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 230px;
  z-index: 1001;
}

@media (max-width: 991px) {

  .sidebar,
  .two-col-bar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
  }
}

.sidebar .sidebar-inner,
.two-col-bar .sidebar-inner {
  height: 100%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar .sidebar-menu,
.two-col-bar .sidebar-menu {
  padding: 10px 0;
}

.sidebar .sidebar-menu ul,
.two-col-bar .sidebar-menu ul {
  font-size: 15px;
  position: relative;
  width: 100%;
}

.sidebar .sidebar-menu ul li,
.two-col-bar .sidebar-menu ul li {
  position: relative;
}

.sidebar .sidebar-menu ul li.submenu .noti-dot:before,
.two-col-bar .sidebar-menu ul li.submenu .noti-dot:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 5px solid #ff9b44;
  background-color: #ff9b44;
  z-index: 10;
  position: absolute;
  top: 15px;
  right: 37px;
  border-radius: 30px;
}

.sidebar .sidebar-menu ul li.submenu .submenu a span,
.two-col-bar .sidebar-menu ul li.submenu .submenu a span {
  margin-left: 0;
}

.sidebar .sidebar-menu ul li.menu-title,
.two-col-bar .sidebar-menu ul li.menu-title {
  color: #ebebeb;
  font-size: 14px;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap;
}

.sidebar .sidebar-menu ul li.menu-title a,
.two-col-bar .sidebar-menu ul li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}

.sidebar .sidebar-menu ul li.menu-title a i,
.two-col-bar .sidebar-menu ul li.menu-title a i {
  font-size: 16px;
}

.sidebar .sidebar-menu ul li a,
.two-col-bar .sidebar-menu ul li a {
  justify-content: flex-start;
  position: relative;
  font-size: 15px;
  color: #b7c0cd;
  height: auto;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 8px 15px;
}

.sidebar .sidebar-menu ul li a:hover,
.two-col-bar .sidebar-menu ul li a:hover {
  color: #ffffff;
}

.sidebar .sidebar-menu ul li a span,
.two-col-bar .sidebar-menu ul li a span {
  display: inline-block;
  margin-left: 15px;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar .sidebar-menu ul li a span.chat-user,
.two-col-bar .sidebar-menu ul li a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .sidebar-menu ul li a .menu-arrow,
.two-col-bar .sidebar-menu ul li a .menu-arrow {
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 16px;
  top: 11px;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  right: 15px;
}

.sidebar .sidebar-menu ul li a .menu-arrow::before,
.two-col-bar .sidebar-menu ul li a .menu-arrow::before {
  content: "\f105";
}

.sidebar .sidebar-menu ul li a i,
.two-col-bar .sidebar-menu ul li a i {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar .sidebar-menu ul li a i.mail-label,
.two-col-bar .sidebar-menu ul li a i.mail-label {
  font-size: 16px;
  margin-right: 5px;
}

.sidebar .sidebar-menu ul li a.subdrop .menu-arrow,
.two-col-bar .sidebar-menu ul li a.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.sidebar .sidebar-menu ul li a .chat-avatar-sm,
.two-col-bar .sidebar-menu ul li a .chat-avatar-sm {
  display: inline-block;
  float: left;
  margin-left: 0 !important;
  margin-right: 10px;
  position: relative;
  width: 24px;
}

.sidebar .sidebar-menu ul li a .chat-avatar-sm.user-img .status,
.two-col-bar .sidebar-menu ul li a .chat-avatar-sm.user-img .status {
  bottom: 0;
  right: -3px;
}

.sidebar .sidebar-menu ul li a .chat-avatar-sm.user-img .status,
.two-col-bar .sidebar-menu ul li a .chat-avatar-sm.user-img .status {
  border: 2px solid #ffffff;
  height: 10px;
  margin: 0;
  width: 10px;
  border-radius: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.sidebar .sidebar-menu ul li a .chat-avatar-sm.user-img .status.online,
.two-col-bar .sidebar-menu ul li a .chat-avatar-sm.user-img .status.online {
  background-color: #55ce63;
}

.sidebar .sidebar-menu ul li a .chat-avatar-sm.user-img .status.offline,
.two-col-bar .sidebar-menu ul li a .chat-avatar-sm.user-img .status.offline {
  background-color: #f62d51;
}

.sidebar .sidebar-menu ul li a span,
.two-col-bar .sidebar-menu ul li a span {
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
  margin-left: 15px;
  white-space: nowrap;
}

.sidebar .sidebar-menu ul li a span.badge,
.two-col-bar .sidebar-menu ul li a span.badge {
  margin-left: auto;
}

.sidebar .sidebar-menu ul li.active a,
.two-col-bar .sidebar-menu ul li.active a {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
}

.sidebar .sidebar-menu ul ul,
.two-col-bar .sidebar-menu ul ul {
  display: none;
}

.sidebar .sidebar-menu ul ul a,
.two-col-bar .sidebar-menu ul ul a {
  display: block;
  font-size: 14px;
  position: relative;
  padding: 9px 10px 9px 50px;
}

.sidebar .sidebar-menu ul ul a.active,
.two-col-bar .sidebar-menu ul ul a.active {
  color: #ff9b44;
  text-decoration: underline;
}

.slide-nav .sidebar {
  margin-left: 0;
}

.two-col-bar {
  background-color: transparent;
}

.greedy>button {
  display: none;
}

.sidebar-overlay {
  display: none;
  z-index: 90;
  height: 100%;
  width: 100%;
  z-index: 1040;
  position: fixed;
  top: 60px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.sidebar-overlay.opened {
  display: block;
}

.card-radio .form-check-input:checked+.form-check-label {
  border-color: #405189 !important;
}

.card-radio .form-check-label {
  background-color: #405189;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-right: 32px;
}

.img-switch .card-radio .form-check-input:checked+.form-check-label::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(64, 81, 137, 0.5);
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #405189;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.img-switch .card-radio .form-check-input:checked+.form-check-label::after {
  content: "✓";
  font-family: "Line Awesome Free";
  position: absolute;
  top: 4px;
  right: 5px;
  font-size: 10px;
  background: #fff;
  font-weight: 900;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #405189;
}

.bg-vertical-gradient {
  background: linear-gradient(to right, #405189, #0ab39c);
}

.bg-vertical-gradient-2 {
  background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
}

.bg-vertical-gradient-3 {
  background: linear-gradient(to right, #f43b48 0%, #453a94 100%);
}

.bg-vertical-gradient-4 {
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
}

@media (min-width: 992px) {
  .mini-sidebar .sidebar {
    width: 60px;
  }

  .mini-sidebar .sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }

  .mini-sidebar .sidebar .sidebar-menu>ul>li>a i {
    font-size: 30px;
    width: 30px;
  }

  .mini-sidebar.expand-menu .sidebar {
    width: 230px;
  }

  .mini-sidebar.expand-menu .sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li>a i {
    font-size: 30px;
    width: 30px;
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li.menu-title {
    visibility: visible;
  }
}

@media (min-width: 991px) {
  .mini-sidebar .page-wrapper {
    margin-left: 60px;
  }
}

.sidebar-twocol .slide-nav .sidebar {
  margin-left: 0;
}

.sidebar-twocol.sidebar {
  background-color: transparent;
  border-right: 0;
  margin-top: 0;
  z-index: 1001;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media (max-width: 991px) {
  .sidebar-twocol.sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
  }
}

.sidebar-twocol.sidebar .slimScrollDiv {
  width: 70px !important;
}

.sidebar-twocol.sidebar .nav-link {
  padding: 5px;
  margin-bottom: 15px;
  color: #ffffff;
}

.sidebar-twocol.sidebar .nav-link.active {
  background: #fff;
  color: #34444c;
}

.sidebar-twocol.sidebar .sidebar-left {
  background: #ff9b44;
  width: 70px;
  padding: 10px;
}

.sidebar-twocol.sidebar .sidebar-right {
  background: #ffffff;
  width: 190px;
  height: 100%;
  -webkit-box-shadow: 0 4px 4px rgba(209, 209, 209, 0.25);
  -moz-box-shadow: 0 4px 4px rgba(209, 209, 209, 0.25);
  box-shadow: 0 4px 4px rgba(209, 209, 209, 0.25);
  padding: 0 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  overflow: auto;
}

.sidebar-twocol.sidebar .sidebar-right .tab-content {
  padding-top: 20px;
}

.sidebar-twocol.sidebar .sidebar-right p {
  font-size: 10px;
  color: #9e9e9e;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}

.sidebar-twocol.sidebar .sidebar-right ul li {
  padding: 7px 0;
}

.sidebar-twocol.sidebar .sidebar-right ul li a {
  font-weight: 400;
  font-size: 14px;
  color: #38414A;
}

.sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #ff9b44;
}

.sidebar-twocol.sidebar .sidebar-right .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 18px;
  top: 0px;
  position: relative;
  font-weight: 700;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
}

.sidebar-twocol.sidebar .sidebar-right .menu-arrow:before {
  content: "\f105";
}

.sidebar-twocol.sidebar li a.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

@media (min-width: 991px) {
  .sidebar-twocol .mini-sidebar .page-wrapper {
    margin-left: 60px;
  }

  .sidebar-twocol .mini-sidebar #toggle_btn {
    margin-left: 10px;
  }

  .sidebar-twocol .mini-sidebar .sidebar-right {
    display: none;
  }

  .sidebar-twocol .expand-menu .sidebar-right {
    display: block;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
}

.greedys {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  height: 60px;
}

.greedys .viewmoremenu {
  background: transparent;
  color: #fff;
  border: 0;
  min-width: 150px;
}

.sidebar .sidebar-horizantal {
  display: none !important;
}

.mini-sidebar .sidebar .sidebar-menu ul li a span {
  display: none;
}

.mini-sidebar.expand-menu .sidebar .sidebar-menu ul li a span {
  display: block;
}

.sidebar-horizantal ul li.submenu a i {
  height: 20px;
  line-height: 19px;
}

.sidebar-horizantal ul li.submenu a span.menu-arrow {
  top: 12px;
}

.select2-results__option {
  padding: 6px 15px;
}

.select2-container .select2-selection.select2-selection--single {
  border: 1px solid #dcdcdc;
  height: 44px;
}

.select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-dropdown {
  z-index: 1055;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 44px;
  right: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #dcdcdc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  margin-left: -10px;
  margin-top: -2px;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #272b41;
  font-size: 15px;
  font-weight: normal;
  line-height: 44px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #dcdcdc;
  border-width: 0 6px 6px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ff9b44;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #dcdcdc;
  min-height: 46px;
}

.select2-dropdown {
  border-color: #dcdcdc;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}

.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}

.nav-tabs {
  border-bottom: 1px solid #dedfe0;
}

.nav-tabs.nav-tabs-bottom li {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs li a {
  color: #888888;
  border-bottom-width: 2px;
}

.nav-tabs li a.active {
  border-color: transparent;
  border-bottom-color: #ff9b44;
  background-color: transparent;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.content-full {
  height: 100%;
  position: relative;
  width: 100%;
}

.content-full .display-table {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}

.content-full .display-table .table-row {
  display: table-row;
  height: 100%;
}

.content-full .display-table .table-row .table-body {
  position: relative;
  height: 100%;
  width: 100%;
}

.content-full .display-table .table-row .table-body .table-content {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-img {
  padding: 30px;
  position: relative;
  text-align: center;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-img .edit-profile-img {
  height: 120px;
  margin: 0 auto;
  position: relative;
  width: 120px;
  cursor: pointer;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-img .edit-profile-img img {
  height: auto;
  margin: 0;
  width: 120px;
  border-radius: 50%;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-img .edit-profile-img .change-img {
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  display: none;
  height: 100%;
  line-height: 120px;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-img .edit-profile-img .edit-btn {
  height: 36px;
  width: 36px;
  border-radius: 40px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-img .user-name {
  color: #333333;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-info {
  padding: 15px;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-info .user-det-list {
  list-style: none;
  padding: 0;
}

.content-full .display-table .table-row .table-body .table-content .chat-profile-info .user-det-list li {
  padding: 6px 15px;
}

.nav-tabs .nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}

.nav-tabs .nav-tabs-solid .active {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #ffffff;
}

.nav-tabs .nav-link:hover {
  background-color: #eeeeee;
  border-color: transparent;
  color: #333333;
}

.nav-tabs.nav-tabs-top li a {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top li a.active {
  border-top-color: #ff9b44;
  border-radius: 0;
}

.nav-tabs.nav-tabs-bottom li a {
  border-width: 0 0 2px 0;
}

.nav-tabs.nav-tabs-bottom li a.active {
  border-bottom-color: #ff9b44;
  background: transparent;
  border-radius: 0;
}

.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded li a.active {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #ffffff;
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid li a.active {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #ffffff;
  border-radius: 0;
}

.page-menu .nav-tabs li a {
  font-size: 16px;
}

@media (max-width: 768px) {
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 5px 0;
    border-radius: 3px;
  }
}

.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.custom-modal .modal-content .btn-close {
  background-color: #58151c;
  border: 0;
  color: #ffffff;
  font-size: 13px;
  height: 25px;
  line-height: 20px;
width: 25px;
  z-index: 99;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  top: 15px !important;
  right: 10px;
}

.custom-modal .modal-content .alert .btn-close:after {
  content: url(../img/icons/close.svg);
  position: absolute;
  top: 5px;
  bottom: 0;
  right: 0;
  left: 0;
}
.custom-modal .modal-content .modal-header {
  border: 0;
  padding: 30px 30px 0;
}

.custom-modal .modal-content .modal-header .modal-title {
  font-size: 22px;
}

.custom-modal .modal-content .modal-header .btn-close {
  background-color: #a0a0a0;
  border: 0;
  color: #ffffff;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  opacity: 1;
  width: 20px;
  z-index: 99;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 10px !important;
  right: 10px;
  border-radius: 50%;
}

.custom-modal .modal-content .modal-body {
  padding: 30px;
}

.custom-modal .modal-content .modal-body .attach-files ul li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.custom-modal .modal-content .modal-body .attach-files ul li img {
  width: 50px;
}

.custom-modal .modal-content .modal-body .attach-files ul li .file-remove {
  color: #fb1612;
  position: absolute;
  top: -7px;
  right: -6px;
}

.custom-modal .modal-content .modal-body .status-toggle {
  float: left;
}

.custom-modal .modal-content .modal-body .input-block {
  margin-bottom: 1rem;
  float: left;
  width: 100%;
}

.custom-modal .modal-content .modal-body .input-block.col-sm-4 {
  width: 33.3%;
}

.custom-modal .modal-content .modal-body .input-block.col-sm-6 {
  width: 50%;
}

.custom-modal .modal-content .modal-body .add-group-btn .btn-primary {
  margin-top: 15px;
}

.custom-modal .modal-content .modal-body .chat-user-list .chat-block {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  background-color: #ffffff;
  padding: 10px;
}

.custom-modal .modal-content .modal-body .chat-user-list .chat-block .user-name {
  color: #333333;
}

.custom-modal .modal-content .modal-body .chat-user-list .chat-block .designation {
  color: #9e9e9e;
  font-size: 12px;
}

.custom-modal .modal-content .modal-body .chat-user-list .chat-block .online-date {
  color: #9e9e9e;
  font-size: 12px;
}

.custom-modal .modal-content .modal-body .chat-user-list .chat-block:hover {
  background-color: #f7f7f7;
}

.submit-section {
  text-align: center;
  margin-top: 40px;
  float: left;
  width: 100%;
}

@media (max-width: 575px) {
  .submit-section {
    margin-top: 20px;
  }
}

.submit-section .submit-btn {
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  border-radius: 50px;
  padding: 10px 20px;
}

@media (max-width: 575px) {
  .submit-section .submit-btn {
    margin: 10px 0 0;
    font-size: 14px;
  }
}

.task-follower-list {
  margin-top: 15px;
}

.task-follower-list span {
  position: relative;
}

.task-follower-list span i {
  cursor: pointer;
  color: #fb1612;
  position: absolute;
  top: -5px;
  right: 5px;
}

.task-board-color .board-color-list {
  height: 45px;
}

@media (max-width: 991px) {
  .task-board-color .board-color-list {
    margin-bottom: 40px;
  }
}

.task-board-color .board-color-list .board-control {
  position: relative;
  min-height: 1.5rem;
  cursor: pointer;
  width: 36px;
  height: 36px;
  margin-bottom: 10px;
  margin: 0 1rem 10px 0;
}

.task-board-color .board-color-list .board-control .board-indicator {
  width: 36px;
  height: 36px;
}

.task-board-color .board-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.task-board-color .board-control-input:checked~.board-indicator:before {
  content: "\f00c";
  display: block;
  width: 16px;
  height: 16px;
  color: #ffffff;
  border: none;
  font-family: FontAwesome;
  text-align: center;
  line-height: 16px;
  border-radius: 2px;
  position: relative;
  top: 10px;
  left: 10px;
}

.task-board-color .board-primary .board-indicator {
  background: #ff9b44;
}

.task-board-color .board-success .board-indicator {
  background: #4caf50;
}

.task-board-color .board-info .board-indicator {
  background: #42a5f5;
}

.task-board-color .board-purple .board-indicator {
  background: #7460ee;
}

.task-board-color .board-warning .board-indicator {
  background: #ffb300;
}

.task-board-color .board-danger .board-indicator {
  background: #ef5350;
}

.leave-duallist {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  display: block;
  padding: 15px;
}

.leave-duallist select.form-control[multiple] {
  height: 200px;
  padding: 7px;
}

.leave-duallist select.form-control[multiple] option {
  border-radius: 3px;
  padding: 7px 12px;
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

.continue-btn,
.cancel-btn {
  background-color: #ffffff;
  border: 1px solid #ff9b44;
  color: #ff9b44 !important;
  display: block;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 50px;
  padding: 10px 20px;
}

.continue-btn:hover,
.cancel-btn:hover {
  color: #fff !important;
}

.table {
  padding: 0;
  background: #ffffff;
}

.table .table> :not(:first-child) {
  border-top: 0;
}

.table th {
  white-space: nowrap;
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
}

.table td {
  border-top: 1px solid #e2e5e8;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.5rem;
}

.table td a {
  color: #333333;
}

.table td .pro-avatar {
  height: 1.65rem;
  margin-right: 5px;
  width: auto;
  border-radius: 0.25rem;
}

.table td h2 {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.table td h2 a {
  color: #333333;
}

.table td a {
  color: #333333;
}

.table> :not(:first-child) {
  border-top: 0;
}

.user-add-shedule-list h2 a {
  padding: 10px;
  display: inline-block;
}

.user-add-shedule-list h2 a span {
  color: #888888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.user-add-shedule-list a {
  border: 1px dashed #aaa;
  color: #aaa;
  border-radius: 5px;
  display: inline-block;
  padding: 10px;
}

.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered th {
  border: 1px solid #dee2e6;
}

.filter-row .select2-container .select2-selection.select2-selection--single {
  height: 50px;
}

.filter-row .btn {
  min-height: 50px;
  text-transform: uppercase;
  padding: 12px;
}

.card-table .card-header {
  border-bottom: 1px solid #dddddd;
  padding: 15px;
}

.card-table .card-body {
  padding: 0;
}

.card-table .card-footer {
  text-align: center;
}

.card-table .card-footer a {
  color: #333333;
}

.dropdown-action .dropdown-toggle::after {
  border: 0;
}

.action-icon {
  color: #777;
  font-size: 18px;
  display: inline-block;
}

.table-inbox td {
  padding: 0.5rem;
}

.table-inbox .starred.fa-star {
  color: #ffd200;
}

.unread .name,
.unread .subject,
.unread .maildate {
  color: #000000;
  font-weight: 600;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #f5f5f5;
}

.custom-table tr.holiday-completed {
  color: #aaa;
}

.custom-table td {
  padding: 10px 20px;
}

.leave-table .l-name {
  width: 200px;
}

.leave-table .l-days {
  width: 140px;
}

.review-section {
  margin: 0 0 30px;
}

.review-section .review-header {
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  text-align: center;
  margin: 0;
  padding: 15px;
}

.review-section .review-header h3 {
  font-size: 1.4rem;
  margin-bottom: 3px;
}

.form-control-sm.form-control {
  height: 30px;
  appearance: auto;
  border-radius: 0;
}

.note-editor.note-frame .note-statusbar {
  border-top: 0;
}

.bootstrap-datetimepicker-widget {
  font-size: 13px;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #7638ff;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(255, 218, 218, 0.49) !important;
  color: #FF0000 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.bg-success-light {
  background-color: #E1FFED !important;
  color: #26af48 !important;
}

.card-two {
  border: 1px solid #DBDFEA;
  border-radius: 5px;
}

.badge-soft-primary {
  color: #405189;
  background-color: rgba(64, 81, 137, 0.1);
}

.badge-soft-secondary {
  color: #3577f1;
  background-color: rgba(53, 119, 241, 0.1);
}

.badge-soft-success {
  color: #0ab39c;
  background-color: rgba(10, 179, 156, 0.1);
}

.badge-soft-info {
  color: #299cdb;
  background-color: rgba(41, 156, 219, 0.1);
}

.badge-soft-warning {
  color: #f7b84b;
  background-color: rgba(247, 184, 75, 0.1);
}

.badge-soft-danger {
  color: #f06548;
  background-color: rgba(240, 101, 72, 0.1);
}

.badge-soft-dark {
  color: #212529;
  background-color: rgba(33, 37, 41, 0.1);
}

.badge-soft-light {
  color: #f3f6f9;
  background-color: rgba(243, 246, 249, 0.1);
}

.badge-outline-primary {
  color: #405189;
  border: 1px solid #405189;
  background-color: transparent;
}

.badge-outline-secondary {
  color: #3577f1;
  border: 1px solid #3577f1;
  background-color: transparent;
}

.badge-outline-success {
  color: #0ab39c;
  border: 1px solid #0ab39c;
  background-color: transparent;
}

.badge-outline-info {
  color: #299cdb;
  border: 1px solid #299cdb;
  background-color: transparent;
}

.badge-outline-warning {
  color: #f7b84b;
  border: 1px solid #f7b84b;
  background-color: transparent;
}

.badge-outline-danger {
  color: #f06548;
  border: 1px solid #f06548;
  background-color: transparent;
}

.badge-outline-dark {
  color: #212529;
  border: 1px solid #212529;
  background-color: transparent;
}

.badge-outline-light {
  color: #f3f6f9;
  border: 1px solid #f3f6f9;
  background-color: transparent;
}

.badge-gray-outline {
  background-color: #ffffff;
  border: 1px solid #EFF2F5;
  color: gray;
}

.badge-gradient-primary {
  background: linear-gradient(135deg, #405189 0, #0ab39c 100%);
}

.badge-gradient-secondary {
  background: linear-gradient(135deg, #3577f1 0, #299cdb 100%);
}

.badge-gradient-success {
  background: linear-gradient(135deg, #0ab39c 0, #f7b84b 100%);
}

.badge-gradient-danger {
  background: linear-gradient(135deg, #f06548 0, #3577f1 100%);
}

.badge-gradient-warning {
  background: linear-gradient(135deg, #f7b84b 0, #eb990a 100%);
}

.badge-gradient-info {
  background: linear-gradient(135deg, #299cdb 0, #0ab39c 100%);
}

.badge-gradient-dark {
  background: linear-gradient(135deg, #212529 0, #405189 100%);
}

li.previous {
  margin-right: 10px;
}

.card-title {
  margin-bottom: 0;
  border-radius: 10px;
}

.cal-icon {
  position: relative;
  width: 100%;
}

.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}

.cal-icon-info:after {
  font-family: "Feather";
  content: "\e926";
  color: gray;
  font-weight: 400;
  font-size: 18px;
  top: 8px;
}

[data-layout-width=boxed] body,
[data-layout-width=boxed] .header {
  max-width: 1300px;
  margin: auto;
}

[data-layout-width=boxed] .sidebar ul .menu-title {
  display: none !important;
}

[data-layout-width=boxed] .sidebar ul li span {
  display: none !important;
}

[data-layout-width=boxed] .sidebar ul ul li {
  display: none !important;
}

[data-layout-width=boxed] .page-wrapper {
  margin: 0 0 0 230px;
}

[data-layout-width=boxed] .main-wrapper {
  position: relative;
}

[data-layout-width=boxed] .main-wrapper #toggle_btn {
  display: none;
}

[data-layout-width=boxed] .sidebar {
  position: absolute;
  width: 230px;
}

[data-layout-width=boxed] .sidebar .noti-dot {
  display: none !important;
}

[data-layout-width=boxed] .sidebar ul .menu-title {
  display: block !important;
}

[data-layout-width=boxed] .sidebar ul li span {
  display: block !important;
}

[data-layout-width=boxed] .sidebar ul ul li {
  display: block !important;
}

[data-sidebar-size=sm-hover] #toggle_btn {
  display: none;
}

[data-sidebar-size=sm-hover] .sidebar ul .menu-title {
  display: none !important;
}

[data-sidebar-size=sm-hover] .sidebar ul li span {
  display: none !important;
}

[data-sidebar-size=sm-hover] .sidebar ul ul li {
  display: none !important;
  width: 100%;
}

[data-sidebar-size=sm-hover] .two-col-bar:hover .sidebar-right {
  display: block;
}

[data-sidebar-size=sm-hover] .two-col-bar .sidebar-left {
  display: block;
}

[data-sidebar-size=sm-hover] .two-col-bar .sidebar-right {
  display: none;
}

[data-sidebar-size=sm-hover] .page-wrapper {
  margin: 0 0 0 60px;
}

[data-sidebar-size=sm-hover] .sidebar {
  width: 60px;
}

[data-sidebar-size=sm-hover] .sidebar .noti-dot {
  display: none !important;
}

[data-sidebar-size=sm-hover] .sidebar .sidebar-menu ul li span {
  display: none !important;
}

[data-sidebar-size=sm-hover] .sidebar:hover {
  width: 230px;
}

[data-sidebar-size=sm-hover] .sidebar:hover ul .menu-title {
  display: inline-block !important;
}

[data-sidebar-size=sm-hover] .sidebar:hover ul li span {
  display: inline-block !important;
}

[data-sidebar-size=sm-hover] .sidebar:hover ul ul li {
  display: inline-block !important;
}

[data-sidebar-size=md] .page-wrapper {
  margin: 0 0 0 200px;
}

[data-sidebar-size=md] .sidebar {
  width: 200px;
}

[data-sidebar-size=md] .sidebar .menu-title {
  justify-content: center;
}

[data-sidebar-size=md] .sidebar .sidebar-menu ul li a {
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

[data-sidebar-size=md] #toggle_btn {
  display: none;
}

[data-layout-position=scrollable] .main-wrapper {
  position: relative;
}

[data-layout-position=scrollable] .main-wrapper .sidebar {
  position: absolute;
}

[data-layout-position=scrollable] .slimScrollDiv,
[data-layout-position=scrollable] .slimscroll {
  overflow: auto !important;
  height: auto !important;
}

[data-sidebar=dark][data-layout-position=scrollable] .slimscroll {
  background-color: #34444c;
}

[data-layout=twocolumn] .two-col-bar {
  display: block;
}

[data-layout=twocolumn] #sidebar {
  display: none;
}

@media (max-width: 991px) {
  [data-layout=twocolumn] #sidebar {
    display: block;
  }
}

[data-layout=twocolumn] #sidebar-view,
[data-layout=twocolumn] #sidebar-size,
[data-layout=twocolumn] #layout-width,
[data-layout=twocolumn] #layout-position {
  display: none;
}

[data-layout=twocolumn] .mini-sidebar .sidebar .sidebar-right {
  display: none;
}

[data-layout=twocolumn] .mini-sidebar.expand-menu .sidebar .sidebar-right {
  display: block;
}

[data-layout-width=boxed][data-sidebar-size=md] .sidebar:hover {
  width: 200px;
}

[data-layout-width=boxed][data-sidebar-size=md] .sidebar ul .menu-title {
  display: block !important;
  text-align: center;
}

[data-layout-width=boxed][data-sidebar-size=md] .sidebar ul li span {
  display: block !important;
}

[data-layout-width=boxed][data-sidebar-size=md] .sidebar ul ul li {
  display: block !important;
}

[data-layout-mode=light][data-topbar=dark] body .header {
  background: #263238;
}

[data-layout-mode=light][data-topbar=dark] body .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

[data-layout-mode=light] .header {
  background: #ffffff;
  border-color: transparent;
}

[data-layout-mode=light] .header .page-title-box h3 {
  color: #333333;
}

[data-layout-mode=light] .header .logo {
  display: none;
}

[data-layout-mode=light] .header .logo2 {
  display: inline-block;
  line-height: 60px;
}

[data-layout-mode=light] .header #toggle_btn .bar-icon span {
  background-color: #333333;
}

[data-layout-mode=light] .header .top-nav-search form .form-control {
  border: 1px solid #d5d5d5;
  color: #333333;
  height: 40px;
  padding: 10px 50px 10px 15px;
  border-radius: 50px;
}

[data-layout-mode=light] .header .top-nav-search form .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #333333;
}

[data-layout-mode=light] .header .top-nav-search form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333333;
}

[data-layout-mode=light] .header .top-nav-search form .form-control::placeholder {
  color: #333333;
}

[data-layout-mode=light] .header .top-nav-search form .btn {
  color: #333333;
}

[data-layout-mode=light] .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #333333;
  border-right: 2px solid #333333;
}

[data-layout-mode=light] nav.greedy button {
  color: #333333 !important;
}

[data-layout-mode=light] .sidebar-twocol.sidebar .sidebar-left {
  background: #fff;
  border-right: 1px solid #d5d5d5;
}

[data-layout-mode=light] .sidebar-twocol.sidebar .sidebar-left .nav-link {
  color: #333333;
}

[data-layout-mode=light] .sidebar-twocol.sidebar .sidebar-left .nav-link.active {
  background: #34444c;
  color: #fff;
}

[data-layout-mode=light] .sidebar {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

[data-layout-mode=light] .sidebar .sidebar-menu ul ul {
  display: none;
  width: 200px;
}

[data-layout-mode=light] .sidebar .sidebar-menu ul li a:hover {
  color: #34444c;
}

[data-layout-mode=light] .sidebar .sidebar-menu .greedy ul li a:hover {
  color: #34444c;
}

[data-layout-mode=light] .user-menu.nav>li>a {
  color: #333333;
}

[data-layout-mode=light] .mini-sidebar .header-left .logo2 img {
  height: auto;
  max-height: 30px;
  width: auto;
}

[data-layout-mode=light] .header .header-left .logo {
  display: none;
  line-height: 60px;
}

[data-layout-mode=light] .sidebar-menu ul li a:hover,
[data-layout-mode=light] .two-col-bar .sidebar-menu ul li a:hover {
  color: #333333;
}

[data-topbar=light][data-layout-mode=blue] .header {
  background: #00c5fb;
  background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
  border-color: transparent;
}

[data-topbar=light][data-layout-mode=blue] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-topbar=light][data-layout-mode=blue] .header .user-menu.nav>li>a {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .page-title-box h3 {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .logo2 {
  display: none !important;
}

[data-topbar=light][data-layout-mode=blue] .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .top-nav-search form .form-control {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .top-nav-search form .form-control::placeholder {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .top-nav-search form .btn {
  color: #fff;
}

[data-layout-mode=blue] .header {
  background: #00c5fb !important;
  background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%) !important;
  border-color: transparent;
}

[data-layout-mode=blue] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-layout-mode=blue] .header .user-menu.nav>li>a {
  color: #fff !important;
}

[data-layout-mode=blue] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-layout-mode=blue] .header .page-title-box h3 {
  color: #fff;
}

[data-layout-mode=blue] .header .logo2 {
  display: none;
}

[data-layout-mode=blue] .page-item.active .page-link {
  background-color: #00c5fb;
  border-color: #00c5fb;
}

[data-layout-mode=blue] .nav-tabs.nav-tabs-solid li a.active {
  background-color: #00c5fb;
  border-color: #00c5fb;
}

[data-layout-mode=blue] .sidebar-twocol.sidebar .sidebar-left {
  background: #34444c;
}

[data-layout-mode=blue] .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #00c5fb;
}

[data-layout-mode=blue] #layout-position .radio input:checked+label {
  background: #00c5fb;
  color: #fff;
}

[data-layout-mode=blue] .dash-widget .card-body .dash-widget-icon {
  background-color: rgba(0, 197, 251, 0.2);
  color: #00c5fb;
}

[data-layout-mode=blue] .bg-primary,
[data-layout-mode=blue] .badge-primary {
  background-color: #00c5fb !important;
}

[data-layout-mode=blue] .sidebar .sidebar-menu ul ul a.active {
  color: #00c5fb;
  text-decoration: underline;
}

[data-layout-mode=blue] .sidebar ul li.submenu .noti-dot:before {
  border: 5px solid #00c5fb;
}

[data-layout-mode=blue] .btn-primary {
  background-color: #00c5fb;
  border: 1px solid #00c5fb;
}

[data-layout-mode=blue] .roles-menu ul li.active a {
  border-color: #00c5fb;
  color: #00c5fb;
}

[data-layout-mode=blue] .settings-icon span {
  background-color: #00c5fb;
}

[data-topbar=light][data-layout-mode=maroon] .header {
  background: #f43b48;
  background: linear-gradient(to right, #f43b48 0%, #453a94 100%);
  border-color: transparent;
}

[data-topbar=light][data-layout-mode=maroon] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-topbar=light][data-layout-mode=maroon] .header .user-menu.nav>li>a {
  color: #fff;
}

[data-topbar=light][data-layout-mode=maroon] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-topbar=light][data-layout-mode=maroon] .header .page-title-box h3 {
  color: #fff;
}

[data-topbar=light][data-layout-mode=maroon] .header .logo2 {
  display: none;
}

[data-layout-mode=maroon] .header {
  background: #f43b48;
  background: linear-gradient(to right, #f43b48 0%, #453a94 100%);
  border-color: transparent;
}

[data-layout-mode=maroon] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-layout-mode=maroon] .header .user-menu.nav>li>a {
  color: #fff;
}

[data-layout-mode=maroon] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-layout-mode=maroon] .header .page-title-box h3 {
  color: #fff;
}

[data-layout-mode=maroon] .header .logo2 {
  display: none;
}

[data-layout-mode=maroon] .page-item.active .page-link {
  background-color: #f43b48;
  border-color: #f43b48;
}

[data-layout-mode=maroon] .nav-tabs.nav-tabs-solid li a.active {
  background-color: #f43b48;
  border-color: #f43b48;
}

[data-layout-mode=maroon] .sidebar-twocol.sidebar .sidebar-left {
  background: #34444c;
}

[data-layout-mode=maroon] .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #f43b48;
}

[data-layout-mode=maroon] .bg-primary,
[data-layout-mode=maroon] .badge-primary {
  background-color: #f43b48 !important;
}

[data-layout-mode=maroon] .dash-widget .card-body .dash-widget-icon {
  background-color: rgba(244, 59, 72, 0.2);
  color: #f43b48;
}

[data-layout-mode=maroon] #layout-position .radio input:checked+label {
  background: #f43b48;
  color: #fff;
}

[data-layout-mode=maroon] .sidebar .sidebar-menu ul ul a.active {
  color: #f43b48;
  text-decoration: underline;
}

[data-layout-mode=maroon] .sidebar ul li.submenu .noti-dot:before {
  border: 5px solid #f43b48;
}

[data-layout-mode=maroon] .btn-primary {
  background-color: #f43b48;
  border: 1px solid #f43b48;
}

[data-layout-mode=maroon] .roles-menu ul li.active a {
  border-color: #f43b48;
  color: #f43b48;
}

[data-layout-mode=maroon] .settings-icon span {
  background-color: #f43b48;
}

[data-layout-mode=purple] .header {
  background: #667eea;
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
  border-color: transparent;
}

[data-layout-mode=purple] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-layout-mode=purple] .header .user-menu.nav>li>a {
  color: #fff;
}

[data-layout-mode=purple] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-layout-mode=purple] .header .page-title-box h3 {
  color: #fff;
}

[data-layout-mode=purple] .header .logo2 {
  display: none;
}

[data-layout-mode=purple] .page-item.active .page-link {
  background-color: #667eea;
  border-color: #667eea;
}

[data-layout-mode=purple] .nav-tabs.nav-tabs-solid li a.active {
  background-color: #667eea;
  border-color: #667eea;
}

[data-layout-mode=purple] .sidebar-twocol.sidebar .sidebar-left {
  background: #34444c;
}

[data-layout-mode=purple] .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #667eea;
}

[data-layout-mode=purple] .bg-primary,
[data-layout-mode=purple] .badge-primary {
  background-color: #667eea !important;
}

[data-layout-mode=purple] #layout-position .radio input:checked+label {
  background: #667eea;
  color: #fff;
}

[data-layout-mode=purple] .dash-widget .card-body .dash-widget-icon {
  background-color: rgba(102, 126, 234, 0.2);
  color: #667eea;
}

[data-layout-mode=purple] .sidebar .sidebar-menu ul ul a.active {
  color: #667eea;
  text-decoration: underline;
}

[data-layout-mode=purple] .sidebar ul li.submenu .noti-dot:before {
  border: 5px solid #667eea;
}

[data-layout-mode=purple] .bg-primary,
[data-layout-mode=purple] .badge-primary {
  background-color: #667eea !important;
}

[data-layout-mode=purple] .btn-primary {
  background-color: #667eea;
  border: 1px solid #667eea;
}

[data-layout-mode=purple] .roles-menu ul li.active a {
  border-color: #667eea;
  color: #667eea;
}

[data-layout-mode=purple] .settings-icon span {
  background-color: #667eea;
}

[data-layout-style=detached][data-layout=horizontal] .main-wrapper {
  max-width: 100%;
  padding: 0;
}

[data-layout-style=detached][data-layout=horizontal] .main-wrapper .sidebar {
  top: 60px;
  border-radius: 0;
  left: 0;
  position: relative;
}

[data-layout-style=detached] .main-wrapper {
  max-width: 95%;
  margin: 0 auto;
  padding-left: 1.5rem;
  position: relative;
}

[data-layout-style=detached] .main-wrapper .sidebar {
  position: fixed;
  top: 80px;
  border-radius: 10px;
  left: 50px;
}

[data-layout-style=detached] .main-wrapper .sidebar.sidebar-twocol {
  top: 30px;
  left: 50px;
  position: absolute;
}

@media (max-width: 991px) {
  [data-layout-style=detached] .main-wrapper .sidebar {
    left: 0;
  }
}

[data-layout-style=detached] .sidebar .sidebar-menu,
[data-layout-style=detached] .two-col-bar .sidebar-menu {
  padding: 0;
}

[data-layout-style=detached] #toggle_btn {
  display: none;
}

[data-layout-mode=dark] body {
  background-color: #263238;
  color: #bbc4cc;
}

[data-layout-mode=dark] body .stats-box {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .card {
  color: #bbc4cc;
  background-color: #263238 !important;
}

[data-layout-mode=dark] body .card .card-body {
  background-color: #263238;
}

[data-layout-mode=dark] body .disabled>.page-link,
[data-layout-mode=dark] body .page-link.disabled {
  --bs-pagination-disabled-color: #6c757d;
}

[data-layout-mode=dark] body .w-sidebar ul a {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .card .card-title {
  color: #bbc4cc;
}

[data-layout-mode=dark] body.mini-sidebar .sidebar {
  background-color: #34444c;
}

[data-layout-mode=dark] body .header .logo {
  display: block;
}

[data-layout-mode=dark] body .profile-view .profile-basic .profile-info-left .user-name {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .personal-info li .title {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .experience-box .experience-list li .experience-content {
  background-color: #2e3840;
  padding: 10px;
}

[data-layout-mode=dark] body .experience-box .experience-list li .experience-user {
  border-radius: 50px;
}

[data-layout-mode=dark] body .sidebar-twocol.sidebar .sidebar-left {
  background: #16191c;
}

[data-layout-mode=dark] body .sidebar-twocol.sidebar .sidebar-left .nav-link {
  color: #bbc4cc;
  background: #2e3840;
}

[data-layout-mode=dark] body .sidebar-twocol.sidebar .sidebar-left .nav-link.active {
  color: #2e3840;
  background: #fff;
}

[data-layout-mode=dark] body .sidebar-twocol.sidebar .sidebar-right {
  background: #2e3840;
}

[data-layout-mode=dark] body .sidebar-twocol.sidebar .sidebar-right ul li a {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #ffffff;
}

[data-layout-mode=dark] body .dash-statistics .stats-info {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body table.table {
  background-color: #16191c;
  border: 1px solid #2e3840;
  background: #16191c;
  --bs-table-bg: $__grayblacks;
}

[data-layout-mode=dark] body table.table th {
  color: #bbc4cc;
  border-top: 1px solid #2e3840;
}

[data-layout-mode=dark] body table.table td {
  border-top: 1px solid transparent;
  color: #bbc4cc;
}

[data-layout-mode=dark] body table.table .btn-white {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .chat-main-row .chat-main-wrapper .chat-window .fixed-header,
[data-layout-mode=dark] body .chat-footer {
  background-color: #16191c;
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] body .video-window .fixed-header .nav li a {
  color: #fff;
}

[data-layout-mode=dark] body .sidebar .sidebar-menu ul li a {
  color: #bbc4cc;
  background: transparent;
}

[data-layout-mode=dark] body .two-col-bar .sidebar-menu ul li a {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .sidebar ul li span {
  display: inline-block;
  color: #bbc4cc;
}

[data-layout-mode=dark] body .contact-list>li {
  background-color: #16191c;
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] body .btn-white {
  background-color: #16191c;
  border: 1px solid #2e3840;
  color: #fff;
}

[data-layout-mode=dark] body .page-item .page-link {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .dataTables_length .form-control {
  background-color: #16191c;
  color: #fff;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #2c3034;
}

[data-layout-mode=dark] body .nav-tabs.nav-tabs-solid {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .select2-container--default .select2-selection--single {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .modal-body .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: 1px solid #e3e3e3;
}

[data-layout-mode=dark] body .activity-box .activity-list li {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .breadcrumb .breadcrumb-item a {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .breadcrumb-item.active {
  --bs-breadcrumb-item-active-color: #6c757d;
}

[data-layout-mode=dark] body .page-wrapper .content .page-header .page-title {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .leave-info-box {
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] body .card-header {
  background-color: #16191c;
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] body .faq-card .card .card-header {
  background-color: #2e3840;
}

[data-layout-mode=dark] body .faq-card .card .card-header a {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .custom-table td {
  color: #fff !important;
}

[data-layout-mode=dark] body .table td a {
  color: #777;
}

[data-layout-mode=dark] .view-icons .btn {
  background-color: #16191c;
  border: 1px solid #2e3840;
  color: #bbc4cc;
}

[data-layout-mode=dark] .header {
  background: #263238;
  border-color: #2e3840;
}

[data-layout-mode=dark] .header .user-menu.nav>li>a {
  color: #bbc4cc;
}

[data-layout-mode=dark] .header #toggle_btn .bar-icon span {
  background-color: #bbc4cc;
}

[data-layout-mode=dark] .header .page-title-box h3 {
  color: #bbc4cc;
}

[data-layout-mode=dark] .header .logo2 {
  display: none;
}

[data-layout-mode=dark] .due-info,
[data-layout-mode=dark] .assigned-info {
  color: #fff;
}

[data-layout-mode=dark] .task-wrapper .task-list-body #task-list li .task-container {
  background: #263238;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .task-wrapper .task-list-body #task-list li .task-container .task-label {
  color: #bbc4cc;
}

[data-layout-mode=dark] .task-wrapper .task-list-body #task-list li.completed .task-container {
  background: #16191c;
}

[data-layout-mode=dark] .task-chat-contents {
  background-color: #263238;
}

[data-layout-mode=dark] .sidebar {
  background-color: #16191c;
}

[data-layout-mode=dark] .welcome-box {
  background-color: #16191c;
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] .chat-contents .chat-content-wrap .chats .chat-right .chat-body .chat-content {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .chat-contents .chat-content-wrap .chats .chat-left .chat-content {
  background-color: #16191c;
  border: 1px solid #2e3840;
  padding: 10px !important;
}

[data-layout-mode=dark] .chat-contents .chat-content-wrap .chats .chat-left .chat-content .chat-time {
  color: #fff;
}

[data-layout-mode=dark] .chat-sidebar .chat-contents {
  background-color: #263238;
}

[data-layout-mode=dark] .chat-footer .message-bar .message-area .input-group .form-control {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .chat-line .chat-date {
  background-color: #16191c;
  top: 9px;
  left: -15px;
}

[data-layout-mode=dark] .search-box .input-group {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .chat-main-row .chat-main-wrapper .chat-window {
  background-color: transparent;
}

[data-layout-mode=dark] .dash-section .dash-info-list .dash-card {
  background-color: #16191c;
  border: 1px solid #2e3840;
  color: #575757;
}

[data-layout-mode=dark] .card {
  border: 1px solid #16191c;
  background: #16191c;
}

[data-layout-mode=dark] .time-list .dash-stats-list h4 {
  color: #bbc4cc;
}

[data-layout-mode=dark] .topics,
[data-layout-mode=dark] .w-sidebar {
  background-color: #16191c;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .topics .topic-title a,
[data-layout-mode=dark] .w-sidebar .topic-title a {
  color: #bbc4cc;
}

[data-layout-mode=dark] .topics .topics .topics-list li a,
[data-layout-mode=dark] .w-sidebar .topics .topics-list li a {
  color: #bbc4cc;
}

[data-layout-mode=dark] .roles-menu ul {
  border: 1px solid #16191c;
  background: #16191c;
}

[data-layout-mode=dark] .roles-menu ul li a {
  color: #bbc4cc;
}

[data-layout-mode=dark] .list-group-item,
[data-layout-mode=dark] .activity-box,
[data-layout-mode=dark] .punch-info .punch-hours,
[data-layout-mode=dark] .punch-det,
[data-layout-mode=dark] .att-statistics .stats-info,
[data-layout-mode=dark] .stats-info {
  border: 1px solid #2e3840;
  background: #16191c;
  color: #bbc4cc;
}

[data-layout-mode=dark] .form-control {
  border: 1px solid #2e3840;
  background: #16191c;
  color: #bbc4cc;
}

[data-layout-mode=dark] .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
}

[data-layout-mode=dark] .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

[data-layout-mode=dark] .form-control::placeholder {
  color: #fff;
}

[data-layout-mode=dark] .project-title a {
  color: #fff;
}

[data-layout-mode=dark] .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #bbc4cc;
}

[data-layout-mode=dark] .profile-widget {
  border: 1px solid #2e3840;
  background: #16191c;
}

[data-layout-mode=dark] .profile-widget .user-name a {
  color: #fff;
}

[data-layout-mode=dark] .stats-info h6 {
  color: #bbc4cc;
}

[data-layout-mode=dark] .modal-body .form-control {
  border-color: #e3e3e3;
  box-shadow: none;
  background-color: transparent;
  color: #bbc4cc;
}

[data-layout-mode=dark] .chat-main-row .chat-main-wrapper .chat-sidebar {
  border-left: 1px solid #2e3840;
}

[data-layout-mode=dark] .nav-tabs {
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] .chat-line {
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] .file-wrap .file-sidebar .file-header {
  background-color: #16191c;
  border-bottom: 1px solid #2e3840;
  color: #bbc4cc;
}

[data-layout-mode=dark] .file-wrap .file-sidebar .file-pro-list {
  background-color: #16191c;
}

[data-layout-mode=dark] .file-wrap .file-sidebar .file-pro-list .file-scroll .file-menu li a {
  color: #bbc4cc;
}

[data-layout-mode=dark] .file-cont-wrap .file-cont-inner .file-cont-header {
  background-color: #16191c;
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] .file-cont-wrap .file-cont-inner .file-cont-header span {
  color: #bbc4cc;
}

[data-layout-mode=dark] .file-content .file-body {
  background-color: #16191c;
}

[data-layout-mode=dark] .file-content .file-body .file-scroll .file-content-inner {
  padding: 15px;
  width: 100%;
}

[data-layout-mode=dark] .file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb {
  background-color: #263238;
}

[data-layout-mode=dark] .file-content .file-search {
  background-color: #263238;
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] .file-content .file-search .form-control {
  background-color: #263238;
  color: #bbc4cc;
}

[data-layout-mode=dark] .file-wrap .file-sidebar .file-search {
  background-color: #263238;
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] .file-wrap .file-sidebar .file-search .form-control {
  background-color: #16191c;
}

[data-layout-mode=dark] .file-cont-wrap .file-cont-inner .file-cont-header .file-options a {
  color: #bbc4cc;
}

[data-layout-mode=dark] .file-wrap {
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .file-wrap .file-sidebar {
  border-right: 1px solid #2e3840;
}

[data-layout-mode=dark] .file-wrap .file-sidebar .file-search .input-group .form-control {
  color: #bbc4cc;
  background-color: #263238;
}

[data-layout-mode=dark] .table-bordered td {
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .nav-tabs.nav-tabs-bottom li a.active {
  color: #fff;
}

[data-layout-mode=dark] .offcanvas {
  background-color: #263238;
  color: #bbc4cc;
}

[data-layout-mode=dark] .offcanvas .sidebar-headerset {
  border-bottom: 1px solid #2e3840;
}

[data-layout-mode=dark] .offcanvas .settings-mains .layout-head h5 {
  color: #ced4da;
}

[data-layout-mode=dark] .offcanvas .settings-mains .layout-head h6 {
  color: #ced4da;
}

[data-layout-mode=dark] .offcanvas .card-radio .form-check-label {
  border-color: #ced4da;
}

[data-layout-mode=dark] .offcanvas .offcanvas-footer {
  border-top: 1px solid #2e3840 !important;
}

[data-layout-mode=dark] .review-section .review-header {
  background-color: #263238;
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .table-bordered th {
  border: 1px solid #2e3840;
}

[data-layout-mode=dark] .page-title {
  color: #bbc4cc;
}

[data-layout-mode=light][data-sidebar=dark] nav.greedy button {
  color: #fff !important;
}

[data-sidebar=gradient] .sidebar {
  background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%);
}

[data-sidebar=gradient] .sidebar-twocol.sidebar .sidebar-left {
  background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%);
}

[data-sidebar=gradient] .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #405189;
}

[data-sidebar=gradient][data-layout-position=scrollable] .slimScrollDiv,
[data-sidebar=gradient] [data-layout-position=scrollable].slimscroll [data-layout-position=scrollable] .slimScrollDiv,
[data-sidebar=gradient] [data-layout-position=scrollable].slimscroll {
  background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%);
}

[data-layout-position=scrollable][data-layout-style=detached] .main-wrapper .sidebar {
  position: absolute;
  left: 30px;
}

[data-layout-position=scrollable][data-layout-style=detached] .main-wrapper .sidebar .slimscroll {
  background: #34444c;
  border-radius: 10px;
}

[data-sidebar=gradient][data-layout-position=scrollable][data-layout-style=detached] .slimscroll {
  background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%) !important;
  border-radius: 10px !important;
}

[data-sidebar=gradient][data-layout-position=scrollable][data-layout-style=detached] .slimScrollDiv {
  background: transparent !important;
}

[data-sidebar=gradient-2] .sidebar {
  background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
}

[data-sidebar=gradient-2] .sidebar-twocol.sidebar .sidebar-left {
  background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
}

[data-sidebar=gradient-2] .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #00c5fb;
}

[data-sidebar=gradient-2] #layout-position .radio input:checked+label {
  background: #00c5fb;
  color: #fff;
}

[data-sidebar=gradient-3] .sidebar {
  background: linear-gradient(to right, #f43b48 0%, #453a94 100%);
}

[data-sidebar=gradient-3] .sidebar-twocol.sidebar .sidebar-left {
  background: linear-gradient(to right, #f43b48 0%, #453a94 100%);
}

[data-sidebar=gradient-3] .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #f43b48;
}

[data-sidebar=gradient-3] #layout-position .radio input:checked+label {
  background: #f43b48;
  color: #fff;
}

[data-sidebar=gradient-4] .sidebar {
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
}

[data-sidebar=gradient-4] .sidebar-twocol.sidebar .sidebar-left {
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
}

[data-sidebar=gradient-4] .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #667eea;
}

[data-sidebar=gradient-4] #layout-position .radio input:checked+label {
  background: #667eea;
  color: #fff;
}

[data-sidebar-size=sm-hover] [data-layout=twocolumn] .page-wrapper {
  margin: 0;
}

[data-sidebar-size=sm-hover] [data-layout=twocolumn] .page-wrapper .sidebar .sidebar-left {
  display: block;
}

[data-sidebar-size=sm-hover] [data-layout=twocolumn] .page-wrapper .sidebar .sidebar-right {
  display: none;
}

[data-layout=horizontal] body .sidebar {
  width: 100% !important;
  bottom: inherit;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul li span {
  display: inline-block !important;
}

[data-layout=horizontal] body .sidebar .slimScrollDiv,
[data-layout=horizontal] body .sidebar .sidebar-inner {
  height: auto !important;
  overflow: inherit !important;
}

[data-layout=horizontal] body .sidebar .hidden {
  display: none !important;
}

[data-layout=horizontal] body .sidebar .sidebar-menu {
  padding: 0 !important;
  height: 60px;
  display: flex;
  align-items: center;
}

[data-layout=horizontal] body .sidebar .sidebar-vertical {
  display: none !important;
}

[data-layout=horizontal] body .sidebar .sidebar-horizantal {
  display: flex !important;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul li a {
  flex-direction: inherit;
  text-align: left;
}

[data-layout=horizontal] body .sidebar .sidebar-menu .menu-title {
  display: none !important;
}

[data-layout=horizontal] body .sidebar ul li span {
  display: inline-block !important;
}

[data-layout=horizontal] body .sidebar .hidden-links .submenu ul {
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #d5d5d5;
}

[data-layout=horizontal] body .sidebar .hidden-links li a:hover {
  color: #333333;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul li.submenu .noti-dot:before {
  display: none;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul li a .menu-arrow {
  right: 0;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul ul {
  display: none;
  width: 200px;
  background-color: #34444c;
  position: absolute;
  top: 45px;
  left: 0;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul ul li a .menu-arrow {
  right: 15px;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul ul a {
  padding: 8px 15px;
}

[data-layout=horizontal] body .sidebar .list-inline-item li a:hover {
  color: #fff;
}

[data-layout=horizontal] body #sidebar-view,
[data-layout=horizontal] body #sidebar-size,
[data-layout=horizontal] body #layout-width,
[data-layout=horizontal] body #layout-position {
  display: none;
}

[data-layout=horizontal] body #toggle_btn {
  display: none;
}

[data-layout=horizontal] body ul.hidden-links {
  position: absolute !important;
  right: 0;
  background: #fff;
  width: 100%;
  top: 60px;
  justify-content: start;
  align-items: start !important;
  flex-wrap: wrap;
  border-top: 1px dashed #e3e3e3;
  padding: 12px 3px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
}

[data-layout=horizontal] body ul.hidden-links ul {
  left: 0 !important;
  position: absolute !important;
  top: 40px !important;
  background-color: #fff !important;
  width: 100% !important;
  z-index: 9999;
}

[data-layout=horizontal] body ul.hidden-links ul li a {
  width: 100%;
}

[data-layout=horizontal] body ul.hidden-links ul li a:hover {
  color: #333333;
}

[data-layout=horizontal] body ul.hidden-links>li {
  width: 20%;
}

[data-layout=horizontal] body ul.hidden-links>li a {
  display: block;
  padding: 5px 15px;
}

[data-layout=horizontal] body ul.hidden-links li a .menu-arrow {
  right: 10px !important;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul li a {
  padding: 10px 15px;
}

[data-layout=horizontal] body .greedy ul.list-inline-item.list-unstyled.links {
  width: 95%;
}

[data-layout=horizontal] body .greedy>button {
  display: block;
}

[data-layout=horizontal] body nav.greedy button {
  align-self: stretch;
  transition: all 0.4s ease-out;
  padding: 5px 0 0 5px;
  outline: 0;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  color: #fff;
  min-width: 100px;
  margin-right: 20px;
}

[data-layout=horizontal] body nav.greedy {
  position: relative;
  height: 45px;
  justify-content: start;
}

[data-layout=horizontal] body nav.greedy ul.hidden-links.hidden {
  display: none;
}

[data-layout=horizontal] body .page-wrapper {
  margin: 0;
  padding-top: 120px;
}

[data-layout=horizontal] body .sidebar .sidebar-menu ul ul ul {
  position: absolute;
  right: -200px;
  top: 0;
  left: auto;
}

[data-layout=horizontal] body .sidebar ul ul li {
  display: inline-block !important;
  width: 100%;
}

[data-layout=horizontal] body .hidden-links .menu-title {
  display: inline-block !important;
  color: #333333 !important;
}

[data-sidebar=dark] .sidebar-twocol.sidebar .sidebar-left {
  background-color: #34444c;
}

[data-sidebar=dark] .sidebar-twocol.sidebar .sidebar-left .nav-link {
  color: #333;
  background: transparent;
}

[data-sidebar=dark] .sidebar-twocol.sidebar .sidebar-left .nav-link.active {
  color: #fff;
  background: #333;
}

[data-sidebar=dark] .sidebar {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-color: #34444c;
}

[data-sidebar=dark] .sidebar .sidebar-menu .greedy ul li a:hover {
  color: #fff;
}

[data-sidebar=dark] .sidebar .sidebar-menu ul li a:hover {
  color: #fff;
}

[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link {
  padding: 5px;
  margin-bottom: 15px;
  color: #fff;
}

[data-sidebar=dark] .two-col-bar .sidebar .sidebar-left .nav-link.active {
  background: #fff;
  color: #34444c;
}

[data-topbar=light] body .header {
  background: #ffffff;
  border-color: transparent;
}

[data-topbar=light] body .header .page-title-box h3 {
  color: #333333;
}

[data-topbar=light] body .header .top-nav-search form .form-control {
  border: 1px solid #d5d5d5;
  color: #333;
}

[data-topbar=light] body .header .top-nav-search form .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #333;
}

[data-topbar=light] body .header .top-nav-search form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333;
}

[data-topbar=light] body .header .top-nav-search form .form-control::placeholder {
  color: #333;
}

[data-topbar=light] body .header .top-nav-search form .btn {
  color: #333;
}

[data-topbar=light] body .header .header-left .logo2 {
  display: inline-block;
  line-height: 60px;
}

[data-topbar=light] body .header .header-left .logo {
  display: none;
  line-height: 60px;
}

[data-topbar=light] body .header #toggle_btn .bar-icon span {
  background-color: #333333;
}

[data-topbar=light] body .header .user-menu.nav>li>a {
  color: #333333;
}

[data-topbar=light] body .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
}

[data-sidebar=light] body .sidebar-twocol.sidebar .sidebar-right {
  background: #fff;
}

[data-sidebar=light] body .sidebar-twocol.sidebar .sidebar-right ul li a.active {
  color: #333;
}

[data-sidebar=light] body .sidebar-twocol.sidebar .sidebar-left .nav-link {
  color: #34444c;
  background: #fff;
}

[data-sidebar=light] body .sidebar-twocol.sidebar .sidebar-left .nav-link.active {
  color: #333333;
  background: #fff;
}

[data-sidebar=light] body .sidebar {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

[data-sidebar=light] body .sidebar ul li span {
  display: inline-block;
  color: #333333;
}

[data-sidebar=light] body .sidebar ul li span:hover {
  display: inline-block !important;
}

[data-sidebar=light] body .sidebar .sidebar-menu {
  padding: 10px 0;
  background: #ffffff;
}

[data-sidebar=light] body .sidebar .list-inline-item li a:hover i {
  color: #333 !important;
}

[data-sidebar=light] body .greedys .viewmoremenu {
  color: #333;
}

[data-sidebar=light] body nav.greedy button {
  color: #333 !important;
}

[data-sidebar=light] body .sidebar-twocol.sidebar .sidebar-left {
  background: #fafafa;
}

[data-sidebar=light] body .sidebar-twocol.sidebar .sidebar-left .nav-link.active {
  background: #34444c;
  color: #fff;
}

[data-sidebar=light] body .mini-sidebar.expand-menu .sidebar ul li span {
  display: block !important;
}

[data-sidebar=light] body .mini-sidebar .sidebar {
  background-color: #ffffff;
}

[data-sidebar=light] body .mini-sidebar .sidebar ul li span {
  display: none !important;
}

[data-sidebar=light] body nav.greedy button {
  color: #333333;
}

[data-sidebar=light] body .sidebar-menu ul li a:hover,
[data-sidebar=light] body .two-col-bar .sidebar-menu ul li a:hover {
  color: #333333;
}

[data-sidebar=light] body .sidebar .sidebar-menu ul li.active a,
[data-sidebar=light] body .two-col-bar .sidebar-menu ul li.active a {
  color: #333333;
  background-color: transparent;
}

[data-topbar=dark] .header {
  background: #263238;
  border-color: #2e3840;
}

[data-topbar=dark] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-topbar=dark] .header .top-nav-search form .form-control {
  border: 1px solid #bbc4cc;
  color: #bbc4cc;
}

[data-topbar=dark] .header .top-nav-search form .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #bbc4cc;
}

[data-topbar=dark] .header .top-nav-search form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbc4cc;
}

[data-topbar=dark] .header .top-nav-search form .form-control::placeholder {
  color: #bbc4cc;
}

[data-topbar=dark] .header .top-nav-search form .btn {
  color: #bbc4cc;
}

[data-topbar=dark] .header .user-menu.nav>li>a {
  color: #bbc4cc;
}

[data-topbar=dark] .header #toggle_btn .bar-icon span {
  background-color: #bbc4cc;
}

[data-topbar=dark] .header .page-title-box h3 {
  color: #bbc4cc;
}

[data-topbar=dark] .header .logo2 {
  display: none;
}

[data-layout-mode=orange] body .header {
  background: #ff9b44;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
}

[data-layout-mode=orange] body .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-layout-mode=orange] body .header .user-menu.nav>li>a {
  color: #fff;
}

[data-layout-mode=orange] body .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-layout-mode=orange] body .header .page-title-box h3 {
  color: #fff;
}

[data-layout-mode=orange] body .header .header-left .logo2 {
  display: none;
}

[data-layout-mode=orange] body .header .top-nav-search form .form-control {
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #ffffff;
  height: 40px;
  padding: 10px 50px 10px 15px;
  border-radius: 50px;
}

[data-layout-mode=orange] body .header .top-nav-search form .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

[data-layout-mode=orange] body .header .top-nav-search form .btn {
  color: rgba(255, 255, 255, 0.7);
}

[data-layout-mode=orange] body .sidebar-twocol.sidebar .sidebar-left {
  background-color: #34444c;
}

[data-layout-mode=orange][data-topbar=light] body .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

[data-layout-mode=orange][data-topbar=dark] body .header {
  background: #263238;
}

[data-layout-mode=orange][data-topbar=dark] body .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.two-col-bar {
  display: none;
}

[data-layout-mode=dark] body .header .user-menu.nav>li.flag-nav .dropdown-menu {
  background-color: #34444c;
}

[data-layout-mode=dark] body .header .user-menu.nav>li.flag-nav .dropdown-menu .dropdown-item {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .header .user-menu.nav>li.flag-nav .dropdown-menu .dropdown-item:hover {
  background: #bbc4cc;
  color: #34444c;
}

[data-layout-mode=dark] body .header .dropdown-menu {
  background-color: #34444c;
}

[data-layout-mode=dark] body .header .dropdown-menu .notification-title {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .header .dropdown-menu .topnav-dropdown-header {
  border-bottom-color: #bdbdbd;
}

[data-layout-mode=dark] body .header .dropdown-menu .notification-list li {
  border-bottom-color: #bdbdbd;
}

[data-layout-mode=dark] body .header .dropdown-menu .notification-list li .noti-title {
  color: #fff;
}

[data-layout-mode=dark] body .header .dropdown-menu .notification-list li .list-item .list-body .message-author {
  color: #fff;
}

[data-layout-mode=dark] body .header .dropdown-menu .notification-list li .list-item .list-body .message-content {
  color: #fff;
}

[data-layout-mode=dark] body .header .dropdown-menu .topnav-dropdown-footer a {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .header .dropdown-menu .dropdown-item {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .header .dropdown-menu .dropdown-item:hover {
  background: #bbc4cc;
  color: #34444c;
}

[data-layout-mode=dark] body table.table th {
  background-color: #34444c;
}

[data-layout-mode=dark] body table.table td {
  background-color: #34444c;
}

[data-layout-mode=dark] body .custom-modal .modal-content {
  background-color: #34444c;
}

[data-layout-mode=dark] body .select2-container--default .select2-results>.select2-results__options {
  background-color: #34444c;
}

[data-layout-mode=dark] body .select2-container--default .select2-results .select2-results__option--highlighted[aria-selected] {
  background-color: #bbc4cc;
}

[data-layout-mode=dark] body .dropdown.action-label .dropdown-menu {
  background-color: #000;
}

[data-layout-mode=dark] body .dropdown.dropdown-action .dropdown-menu {
  background-color: #000;
}

[data-layout-mode=dark] body .dropdown.dropdown-action .dropdown-menu .dropdown-item {
  color: #bbc4cc !important;
}

[data-layout-mode=dark] body .dropdown.dropdown-action .dropdown-menu .dropdown-item:hover {
  background: #bbc4cc;
  color: #34444c !important;
}

[data-layout-mode=dark] body .card-table .card-footer a {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .profile-view .profile-img {
  background-color: #34444c;
}

[data-layout-mode=dark] body .profile-view .profile-img-wrap {
  border-radius: 50%;
}

[data-layout-mode=dark] body .bootstrap-datetimepicker-widget {
  background-color: #34444c;
  color: #bbc4cc;
}

[data-layout-mode=dark] body .custom-modal .modal-content .modal-body .chat-user-list .chat-block {
  background-color: transparent;
}

[data-layout-mode=dark] body .custom-modal .modal-content .modal-body .chat-user-list .chat-block .user-name {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .list-body .message-author {
  color: #bbc4cc;
}

[data-layout-mode=dark] body .list-body .message-content {
  color: #bbc4cc;
}

[data-topbar=light][data-layout-mode=purple] .header {
  background: #667eea;
  background: linear-gradient(to right, #667eea 0%, #764ba2 100%);
  border-color: transparent;
}

[data-topbar=light][data-layout-mode=purple] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-topbar=light][data-layout-mode=purple] .header .user-menu.nav>li>a {
  color: #fff;
}

[data-topbar=light][data-layout-mode=purple] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-topbar=light][data-layout-mode=purple] .header .page-title-box h3 {
  color: #fff;
}

[data-topbar=light][data-layout-mode=purple] .header .logo2 {
  display: none;
}

[data-topbar=light][data-layout-mode=purple] .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

[data-topbar=light][data-layout-mode=purple] .header .top-nav-search form .form-control {
  color: #fff;
}

[data-topbar=light][data-layout-mode=purple] .header .top-nav-search form .form-control::placeholder {
  color: #fff;
}

[data-topbar=light][data-layout-mode=purple] .header .top-nav-search form .btn {
  color: #fff;
}

[data-topbar=light][data-layout-mode=purple] .header .header-left .logo2 {
  display: none;
}

[data-topbar=light][data-layout-mode=blue] .header {
  background: #00c5fb;
  background: linear-gradient(to right, #00c5fb 0%, #0253cc 100%);
  border-color: transparent;
}

[data-topbar=light][data-layout-mode=blue] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-topbar=light][data-layout-mode=blue] .header .user-menu.nav>li>a {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .page-title-box h3 {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .logo2 {
  display: none !important;
}

[data-topbar=light][data-layout-mode=blue] .header .has-arrow .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .top-nav-search form .form-control {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .top-nav-search form .form-control::placeholder {
  color: #fff;
}

[data-topbar=light][data-layout-mode=blue] .header .top-nav-search form .btn {
  color: #fff;
}

[data-topbar=light][data-layout-mode=maroon] .header {
  background: #f43b48;
  background: linear-gradient(to right, #f43b48 0%, #453a94 100%);
  border-color: transparent;
}

[data-topbar=light][data-layout-mode=maroon] .header .header-left .logo {
  display: block;
  line-height: 60px;
}

[data-topbar=light][data-layout-mode=maroon] .header .user-menu.nav>li>a {
  color: #fff;
}

[data-topbar=light][data-layout-mode=maroon] .header #toggle_btn .bar-icon span {
  background-color: #fff;
}

[data-topbar=light][data-layout-mode=maroon] .header .page-title-box h3 {
  color: #fff;
}

[data-topbar=light][data-layout-mode=maroon] .header .logo2 {
  display: none;
}

[data-layout-mode=maroon] .add-btn {
  background-color: #f43b48;
}

[data-layout-mode=blue] .add-btn {
  background-color: #00c5fb;
  border-color: #00c5fb;
}

.apexcharts-legend.apexcharts-align-center.position-right {
  display: none;
}

.apexcharts-toolbar {
  display: none !important;
}

#sales_charts,
.chart-set {
  overflow: hidden;
}

.apexcharts-canvas {
  width: 100% !important;
}

/****** PAGES ******/
.activity-box {
  position: relative;
}

.activity-box .activity-list {
  list-style: none;
  position: relative;
}

.activity-box .activity-list::before {
  content: "";
  width: 2px;
  background: #dddddd;
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 23px;
}

.activity-box .activity-list li {
  background-color: #ffffff;
  position: relative;
  border: 1px solid #ededed;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0 10px;
  padding: 10px;
}

.activity-box .activity-list li::before {
  background: #dddddd;
  bottom: 0;
  content: "";
  width: 2px;
  position: absolute;
  top: 12px;
  left: 22px;
}

.activity-box .activity-list li .activity-user {
  height: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  left: 8px;
}

.activity-box .activity-list li .activity-user .avatar {
  height: 32px;
  line-height: 32px;
  width: 32px;
  margin: 0;
}

.activity-box .activity-list li .activity-content {
  position: relative;
  margin: 0 0 0 40px;
  padding: 0;
}

.activity-box .activity-list li .activity-content .timeline-content {
  color: #9e9e9e;
}

.activity-box .activity-list li .activity-content .timeline-content a {
  color: #616161;
}

.activity-box .activity-list li .time {
  color: #bdbdbd;
  display: block;
  font-size: 13px;
}

.total-widget {
  background: #fff;
  margin-bottom: 25px;
  padding: 25px;
  border-radius: 6px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.5s;
  position: relative;
  top: 0;
  border: 1px solid transparent;
}

.total-widget:hover {
  position: relative;
  top: -5px;
  border-color: #ff9b44;
}

.total-widget h6 {
  font-size: 18px;
  color: #333333;
}

.total-widget h4 {
  font-size: 32px;
  color: #ff9b44;
  margin: 0;
}

.split-head li {
  margin: 0 5px;
}

@media (max-width: 991px) {
  .split-head li {
    margin: 10px;
  }
}

.split-head li .btn-whiteline {
  border: 1px solid #E6E9E8;
  color: #68616D;
  font-size: 16px;
  background: #fff;
}

.split-head li .btn {
  padding: 12px;
  border-radius: 6px;
}

.split-head li .btn:hover {
  background: #ff9b44;
  color: #fff;
}

.split-head li .bookingrange {
  border: 1px solid #E6E9E8;
}

.table-imgname img {
  width: 30px;
  border-radius: 5px;
}

.table-imgname span {
  color: #68616D;
}

.table.table-new {
  background: transparent;
}

.table.table-new tbody {
  background: #fff;
}

.table-namesplit .table-profileimage img {
  width: 27px;
  height: 27px;
  border-radius: 50px;
  min-width: 27px;
}

.table-namesplit .table-name span {
  font-size: 13px;
  color: #68616D;
  font-weight: 400;
}

.table-namesplit .table-name p {
  color: #878787;
  margin: 0;
  font-size: 11px;
}

.delete-table {
  background: #f5f5f5;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  color: #68616D;
}

.delete-table:hover {
  background-color: #FF7444;
}

.delete-table:hover img {
  filter: brightness(0) invert(1);
}

.table-new thead tr th {
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #1C2D40;
  background: #EDF3F9;
}

.table-new thead tr th:first-child {
  border-radius: 10px 0 0 0;
}

.table-new thead tr th:last-child {
  border-radius: 0 10px 0 0;
}

.table .btn-assign {
  font-size: 12px;
  padding: 3px;
}

.btn-assign {
  background-color: #FF7444;
  border: 1px solid #FF7444;
  color: #fff !important;
  font-weight: 500;
  min-width: 80px;
}

.btn-assign:hover {
  background: #fd8e2d;
}

.assets-info {
  background: #fff;
  margin-bottom: 25px;
  padding: 20px;
  border: 1px solid #E6E9E8;
  border-radius: 8px;
  width: 100%;
}

.assets-info.assets-image h2 {
  border: 0;
}

.assets-info.assets-image ul {
  margin-top: 25px;
}

.assets-info.assets-image ul li {
  margin: 0 10px;
  border: 0;
  padding: 0;
}

.assets-info h2 {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  padding-bottom: 10px;
  border-bottom: 1px solid #E6E9E8;
}

.assets-info li {
  border-bottom: 1px solid #E6E9E8;
  padding: 15px 0;
}

@media (max-width: 479px) {
  .assets-info li {
    flex-direction: column;
  }
}

.assets-info li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.assets-info li span {
  color: #000000;
}

.assets-info li p {
  margin: 0;
}

.assign-head {
  margin-bottom: 30px;
}

.assign-head .assign-content h6 {
  margin: 0;
  font-size: 18px;
  margin-left: 20px;
}

table.table-new.dataTable>thead .sorting:after,
table.table-new.dataTable>thead .sorting_asc:after,
table.table-new.dataTable>thead .sorting_desc:after,
table.table-new.dataTable>thead .sorting_asc_disabled:after,
table.table-new.dataTable>thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\f0d7";
  font-family: "FontAwesome";
  top: 24px;
  color: #C1CCDB;
  font-size: 12px;
  opacity: 1;
}

table.table-new.dataTable>thead .sorting:hover:after,
table.table-new.dataTable>thead .sorting_asc:hover:after,
table.table-new.dataTable>thead .sorting_desc:hover:after,
table.table-new.dataTable>thead .sorting_asc_disabled:hover:after,
table.table-new.dataTable>thead .sorting_desc_disabled:hover:after {
  color: #8291A5;
}

table.table-new.dataTable>thead .sorting:focus:after,
table.table-new.dataTable>thead .sorting_asc:focus:after,
table.table-new.dataTable>thead .sorting_desc:focus:after,
table.dataTable>thead .sorting_asc_disabled:focus:after,
table.table-new.dataTable>thead .sorting_desc_disabled:focus:after {
  color: #8291A5;
}

table.table-new.dataTable>thead .sorting:before,
table.table-new.dataTable>thead .sorting_asc:before,
table.table-new.dataTable>thead .sorting_desc:before,
table.table-new.dataTable>thead .sorting_asc_disabled:before,
table.table-new.dataTable>thead .sorting_desc_disabled:before {
  right: 0.5em;
  content: "\f0d8";
  font-family: "FontAwesome";
  top: 13px;
  color: #C1CCDB;
  font-size: 12px;
  opacity: 1;
}

table.table-new.dataTable>thead .sorting:hover:before,
table.table-new.dataTable>thead .sorting_asc:hover:before,
table.table-new.dataTable>thead .sorting_desc:hover:before,
table.table-new.dataTable>thead .sorting_asc_disabled:hover:before,
table.table-new.dataTable>thead .sorting_desc_disabled:hover:before {
  color: #8291A5;
}

table.table-new.dataTable>thead .sorting:focus:before,
table.table-new.dataTable>thead .sorting_asc:focus:before,
table.table-new.dataTable>thead .sorting_desc:focus:before,
table.table-new.dataTable>thead .sorting_asc_disabled:focus:before,
table.table-new.dataTable>thead .sorting_desc_disabled:focus:before {
  color: #8291A5;
}

.file-upload-popup {
  position: relative;
}

.file-upload-popup .file-upload-popupcontent input {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 88px;
  opacity: 0;
  z-index: 999;
}

.file-upload-popup .file-upload-popupcontent span {
  background: #F2F2F2;
  position: absolute;
  right: 0;
  top: 0;
  width: 88px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
}

.selectnew .select2-container .select2-selection--single {
  border: 1px solid #dcdcdc;
  height: 44px;
}

.selectnew .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #9597A9;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 0;
  transform: rotate(45deg);
}

.selectnew .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #9597A9;
  border-width: 2px 0 0 2px;
  padding: 3px;
  margin-top: -2px;
}

.custom-modal .modal-content .modal-header .btn-close {
  background-color: #FF7444;
  height: 30px;
  width: 30px;
  border: 1px solid #FF7444;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom-modal .modal-content .modal-header .btn-close:hover {
  background-color: transparent;
  color: #FF7444;
}

.submit-btns {
  width: 430px;
  max-width: 100%;
  padding: 12px;
  background: #FF7444;
}

.table-newdatatable {
  margin-top: 25px;
}

.table-newdatatable .dataTables_length {
  display: none;
}

.table-newdatatable .dataTables_info {
  display: none;
}

.asset-box h5 {
  font-size: 18px;
  margin-bottom: 15px;
}

.asset-box .asset-info {
  margin-bottom: 30px;
}

.asset-box .asset-info .asset-info-img {
  margin-right: 30px;
}

.asset-box .asset-info .asset-info-img img {
  width: 200px;
  border-radius: 6px;
}

.asset-box .asset-info .asset-info-det h6 {
  font-size: 18px;
  margin-bottom: 7px;
}

.asset-box .asset-info .asset-info-det p {
  font-size: 14px;
  color: #595959;
}

.asset-box .asset-info .asset-info-det ul li {
  font-size: 14px;
  margin-bottom: 15px;
}

.asset-box .asset-info .asset-info-det ul li span {
  float: right;
  color: #595959;
  margin-left: 15px;
}

.asset-box .asset-info .asset-info-det ul li:last-child {
  margin-bottom: 0;
}

.asset-box .assets-image h2 {
  border: 0;
}

.asset-box .assets-image ul {
  margin-top: 25px;
}

.asset-box .assets-image ul li {
  margin: 0 10px;
  border: 0;
  padding: 0;
}

.asset-box .assets-image ul li:first-child {
  margin-left: 0;
}

.asset-box .assets-image ul li img {
  border-radius: 4px;
}

.asset-box .asset-history ul li {
  margin-bottom: 28px;
}

.asset-box .asset-history ul li .aset-img {
  margin-right: 28px;
  min-width: 25px;
}

.asset-box .asset-history ul li h6 {
  font-size: 14px;
  margin-bottom: 5px;
}

.asset-box .asset-history ul li p {
  font-size: 14px;
  margin-bottom: 0;
}

.cals-icon {
  position: relative;
}

.cals-icon .icon-text {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.cals-icon .form-control {
  padding: 10px 10px 10px 30px;
}

.cat-upload {
  position: relative;
}

.cat-upload {
  width: 87px;
  height: 80px;
  color: #D9D9D9;
  border: 1px solid #E6E9E8;
  border-radius: 6px;
}

.cat-upload .upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  opacity: 0;
  margin: 0;
  padding: 5px;
  position: absolute;
  top: -3px;
  right: -3px;
}

.cat-upload-view {
  position: relative;
}

.cat-upload-view .img-close {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fb1612;
}

@media (max-width: 992px) {
  .assets-image {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .asset-box .asset-info {
    display: block;
  }

  .asset-box .asset-info .asset-info-img {
    margin-bottom: 15px;
  }
}

.punch-det {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin: 0 0 20px;
  padding: 10px 15px;
}

.punch-det h6 {
  line-height: 20px;
  margin: 0;
}

.punch-det p {
  color: #727272;
  font-size: 14px;
  margin: 0;
}

.punch-info {
  margin: 0 0 20px;
}

.punch-info .punch-hours {
  background-color: #f9f9f9;
  border: 5px solid #e3e3e3;
  font-size: 18px;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  border-radius: 50%;
}

.punch-btn-section {
  text-align: center;
  margin: 0 0 20px;
}

.punch-btn-section .punch-btn {
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
  padding: 8px 40px;
  border-radius: 50px;
}

.punch-btn-section .stats-box {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  margin: 0 0 15px;
  padding: 5px;
}

.punch-btn-section .stats-box p {
  font-size: 12px;
  margin: 0;
}

.punch-btn-section .stats-box h6 {
  margin: 0;
}

.recent-activity .res-activity-list {
  height: 328px;
  list-style-type: none;
  overflow-y: auto;
  position: relative;
  margin: 0;
  padding: 0 0 0 30px;
}

.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}

.recent-activity .res-activity-list li {
  margin: 0 0 15px;
  position: relative;
}

.recent-activity .res-activity-list li:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #ff9b44;
  z-index: 2;
  background: #ffffff;
  border-radius: 100%;
  margin: 0 0 0 15px;
  position: absolute;
  top: 0;
  left: -45px;
}

.recent-activity p {
  font-size: 13px;
  margin: 0;
}

.recent-activity .res-activity-time {
  color: #bbb;
  font-size: 12px;
}

.att-statistics .progress {
  height: 4px;
}

.att-statistics .stats-info {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  text-align: center;
  border-radius: 4px;
  margin: 0 0 5px;
  padding: 15px;
}

.att-statistics .stats-info p {
  font-size: 12px;
  margin: 0 0 5px;
}

.chat-contents {
  display: table-row;
  height: 100%;
}

.chat-contents .chat-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}

.chat-contents .chat-content-wrap .chats {
  padding: 30px 15px;
}

.chat-contents .chat-content-wrap .chats .chat-body {
  padding: 0 10px 0 48px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble:first-child .chat-content {
  border-top-right-radius: 20px !important;
}

.chat-contents .chat-content-wrap .chats .chat-bubble:last-child .chat-content {
  border-bottom-right-radius: 20px !important;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-content .chat-time {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  font-size: 12px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-content .attach-list {
  color: #a3a3a3;
  line-height: 24px;
  list-style: none;
  margin: 0;
  padding: 5px 0 0;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-content .attach-list .task-attach-img {
  margin: 0 0 20px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-content .chat-img-group p {
  margin: 0 0 20px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-content p {
  margin: 0;
}

.chat-contents .chat-content-wrap .chats .chat-bubble:hover .chat-action-btns {
  display: block;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-action-btns {
  display: none;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-action-btns ul {
  list-style: none;
  margin: 15px 15px 0 15px;
  padding: 0;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-action-btns ul li {
  display: inline-block;
  margin-left: 5px;
  font-size: 18px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-action-btns ul li a {
  color: #8c8c8c;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach {
  position: relative;
  float: right;
  border-radius: 4px;
  margin: 0 0 12px 12px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder {
  background: rgba(69, 81, 97, 0.6);
  border-radius: 4px;
  color: #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 13px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder .chat-img-name {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.chat-contents .chat-content-wrap .chats .chat-bubble .chat-img-attach .chat-placeholder .chat-file-desc {
  font-size: 11px;
}

.chat-contents .chat-content-wrap .chats .chat-right .chat-body {
  padding: 0 10px 0 48px;
}

.chat-contents .chat-content-wrap .chats .chat-right .chat-body .chat-content {
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
  clear: both;
  color: #727272;
  display: block;
  float: right;
  max-width: 60%;
  position: relative;
  margin: 0;
  padding: 8px 15px;
  border-radius: 20px 2px 2px 20px;
}

.chat-contents .chat-content-wrap .chats .chat-right .chat-body .chat-content .chat-time {
  text-align: right;
}

.chat-contents .chat-content-wrap .chats .chat-right .chat-body .chat-content.img-content {
  background-color: transparent;
  border: 0;
  color: #76838f;
  padding: 0;
  text-align: right;
}

.chat-contents .chat-content-wrap .chats .chat-right .chat-body .chat-time {
  text-align: right;
}

.chat-contents .chat-content-wrap .chats .chat-right .chat-body .chat-action-btns {
  float: right;
}

.chat-contents .chat-content-wrap .chats .chat-left {
  width: 100%;
  float: left;
}

.chat-contents .chat-content-wrap .chats .chat-left .chat-avatar {
  float: left;
}

.chat-contents .chat-content-wrap .chats .chat-left .chat-avatar .avatar {
  line-height: 30px;
  height: 30px;
  width: 30px;
}

.chat-contents .chat-content-wrap .chats .chat-left .chat-body {
  margin: 0;
  padding: 0 20px 0 0;
  display: block;
  overflow: hidden;
}

.chat-contents .chat-content-wrap .chats .chat-left .chat-content {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  color: #888888;
  float: left;
  position: relative;
  max-width: 60%;
  padding: 8px 15px;
  border-radius: 2px 20px 20px 2px;
}

@media (max-width: 575px) {
  .chat-contents .chat-content-wrap .chats .chat-left .chat-content {
    max-width: 80%;
  }
}

.chat-contents .chat-content-wrap .chats .chat-left .chat-bubble:first-child .chat-content {
  border-top-left-radius: 20px;
}

.chat-contents .chat-content-wrap .chats .chat-left .chat-bubble:last-child .chat-content {
  border-bottom-left-radius: 20px;
}

.chat-contents .chat-content-wrap .chats .chat-left .chat-img-attach {
  float: left;
  margin: 0 12px 12px 0;
}

.chat-sidebar .chat-line .chat-date {
  background-color: #ffffff;
  top: 10px;
}

.chat-line {
  border-bottom: 1px solid #eaeaea;
  position: relative;
  text-align: center;
  width: 100%;
  margin: 7px 0 20px;
}

.chat-line .chat-date {
  background-color: #f7f7f7;
  color: #727272;
  font-size: 12px;
  padding: 0 11px;
  position: relative;
  top: 7px;
  left: -15px;
}

.chat-main-row {
  overflow: auto;
  padding-bottom: inherit;
  padding-top: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.chat-main-row .chat-main-wrapper {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}

.chat-main-row .chat-main-wrapper .message-view {
  display: table-cell;
  height: 100%;
  float: none;
  position: static;
  vertical-align: top;
  width: 75%;
  padding: 0;
}

@media (max-width: 991px) {
  .chat-main-row .chat-main-wrapper .message-view {
    width: 100%;
  }
}

.chat-main-row .chat-main-wrapper .chat-sidebar {
  background-color: #ffffff;
  border-left: 1px solid #eaeaea;
  width: 300px !important;
}

.chat-sidebar .chat-contents {
  background-color: #ffffff;
}

.chat-sidebar .chat-contents .chats {
  padding: 15px 15px 30px;
}

.chat-sidebar .chat-contents .chats .chat-left {
  margin: 0 0 20px;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-avatar {
  float: left;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-avatar .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-body {
  margin: 0 0 0 50px;
  padding: 0;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble {
  display: block;
  width: 100%;
  float: left;
  margin: 0 0 10px;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble .chat-content {
  border: 0;
  padding: 0;
  background-color: transparent;
  max-width: 100%;
  border-radius: 0 !important;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble .chat-content .task-chat-user {
  color: #333333;
  font-size: 13px;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble .chat-content .chat-time {
  color: #888888;
  display: inline-block;
  font-size: 11px;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble .chat-content .call-details {
  margin: 10px 0 0px;
}

.chat-sidebar .chat-contents .chats .chat-left .chat-body .chat-bubble .chat-content .call-details .call-info {
  margin-left: 10px;
  width: 100%;
}

.video-window .fixed-header {
  padding: 0 !important;
  border: 0;
}

.video-window .fixed-header .nav li a {
  padding: 18px 15px;
}

.chat-profile-img {
  padding: 30px;
  position: relative;
  text-align: center;
}

.chat-profile-img .edit-profile-img {
  height: 120px;
  margin: 0 auto;
  position: relative;
  width: 120px;
  cursor: pointer;
}

.chat-profile-img .edit-profile-img img {
  height: auto;
  margin: 0;
  width: 120px;
  border-radius: 50%;
}

.chat-profile-img .edit-profile-img .change-img {
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  display: none;
  height: 100%;
  line-height: 120px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.chat-profile-img .edit-profile-img:hover .change-img {
  display: block !important;
}

.chat-profile-img .edit-btn {
  height: 36px;
  width: 36px;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 40px;
}

.chat-profile-img .edit-btn i {
  font-size: 13px;
}

.files-list {
  list-style: none;
  padding-left: 0;
}

.files-list>li {
  border-bottom: 1px solid #eaeaea;
  margin: 2px 0 0;
  padding: 10px;
  border-radius: inherit;
}

.files-list>li .files-cont {
  position: relative;
}

.files-list>li .files-cont .file-type {
  height: 48px;
  position: absolute;
  width: 48px;
}

.files-list>li .files-cont .file-type .files-icon {
  background-color: #f5f5f5;
  border: 1px solid #dddddd;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
  border-radius: 4px;
}

.files-list>li .files-cont .file-type .files-icon i {
  color: #777;
  font-size: 20px;
}

.files-list>li .files-cont .files-info {
  padding: 0 30px 0 50px;
}

.files-list>li .files-cont .files-info .text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.files-list>li .files-cont .files-info .file-author a {
  color: #ff9b44;
  font-size: 12px;
  text-decoration: underline;
}

.files-list>li .files-cont .files-info .file-author .file-date {
  color: #888888;
  font-size: 12px;
}

.files-list>li .files-cont .files-action {
  display: none;
  height: 30px;
  list-style: none;
  padding-left: 0;
  text-align: right;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
}

.files-list>li .files-cont .files-action a {
  color: #777;
}

.files-list>li:hover .files-action {
  display: block;
}

.search-box {
  margin-top: 5px;
}

@media (max-width: 991px) {
  .search-box {
    display: none;
  }
}

.search-box .input-group {
  background-color: #f7f7f7;
  border: 1px solid #e3e3e3;
  border-radius: 50px;
}

.search-box .input-group .form-control {
  background: transparent;
  border: none;
}

.search-box .input-group .btn {
  background-color: transparent;
  border: 0;
}

.upload-drop-zone {
  background-color: #ffffff;
  border: 2px dashed #e3e3e3;
  color: #cccccc;
  height: 200px;
  line-height: 200px;
  margin-bottom: 20px;
  text-align: center;
}

.upload-drop-zone .upload-text {
  font-size: 24px;
  margin-left: 10px;
}

.upload-list .file-size {
  color: #888888;
}

.upload-list .file-list {
  background-color: #ffffff;
  border-top: 1px solid #e3e3e3;
  padding: 10px 0;
}

.upload-list .file-list:first-child {
  border-top: none;
}

.upload-list .upload-wrap {
  position: relative;
  margin: 0 0 5px;
  padding: 0 20px 0 0;
}

.upload-list .file-name {
  padding-right: 15px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  color: #888888;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.upload-list .file-close {
  border: none;
  background: none;
  color: #cccccc;
  position: absolute;
  top: 2px;
  right: 0;
}

.upload-list .progress {
  margin-bottom: 5px;
}

.upload-list .upload-process {
  font-size: 10px;
  color: #888888;
}

.voice-call-avatar {
  flex: 2;
}

.voice-call-avatar .call-avatar {
  width: 150px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 3px;
  padding: 3px;
  border-radius: 100%;
}

.voice-call-avatar .call-avatar .username {
  font-size: 18px;
  text-transform: uppercase;
}

.voice-call-avatar .call-avatar .call-timing-count {
  padding: 5px;
}

@media (max-width: 768px) {
  .voice-call-avatar .call-avatar {
    width: 120px;
    height: 120px;
  }
}

.call-users {
  z-index: 99;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.call-users ul li {
  float: left;
  width: 80px;
  margin-left: 10px;
}

.call-users ul li img {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 2px;
}

.call-users ul li .call-mute {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #ffffff;
  display: none;
  border: 3px solid transparent;
  border-radius: 6px;
  position: absolute;
  top: 0;
}

.call-users ul li a:hover .call-mute {
  display: block;
}

.call-icons {
  text-align: center;
  position: relative;
}

.call-icons .call-items {
  display: inline-block;
  border-radius: 5px;
}

.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.call-icons .call-items .call-item a {
  color: #777;
  border: 1px solid #dddddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  font-size: 20px;
  border-radius: 50px;
}

.call-icons .end-call {
  position: absolute;
  top: 7px;
  right: 0;
}

@media (max-width: 575px) {
  .call-icons .end-call {
    margin-top: 10px;
    position: inherit;
  }
}

.call-icons .end-call a {
  background-color: #f06060;
  color: #ffffff;
  display: inline-block;
  line-height: 10px;
  text-transform: uppercase;
  padding: 8px 25px;
  border-radius: 50px;
}

.chat-sidebar .chats {
  padding: 15px 15px 30px;
}

.chat-sidebar .chat-left .chat-avatar {
  float: left;
}

.chat-sidebar .chat-left .chat-avatar .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.chat-sidebar .chat-left .chat-body {
  margin: 0 0 0 50px;
  padding: 0;
}

.user-video {
  overflow: auto;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.user-video img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.my-video {
  z-index: 99;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.my-video ul li {
  float: left;
  width: 120px;
  margin-right: 10px;
}

@media (max-width: 575px) {
  .my-video ul li {
    width: 80px;
  }
}

.my-video ul li img {
  border: 3px solid #ffffff;
  border-radius: 6px;
}

.call-box {
  display: block;
  background: #ff9b44;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  position: sticky;
  top: 0;
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
}

.call-box.incoming-box .call-end,
.call-box.incoming-box .call-start {
  margin: 0 10px !important;
}

.call-box .call-avatar {
  height: 150px;
  max-width: 150px;
  min-width: 150px;
  position: relative;
  width: 100%;
  border-radius: 100%;
}

.call-box .call-wrapper {
  height: calc(100vh - 60px);
  text-align: center;
}

.call-box .call-wrapper .call-user {
  margin-bottom: 50px;
}

.call-box .call-wrapper .call-user h4 {
  color: #ffffff;
}

.call-box .call-wrapper .call-user .call-box .call-wrapper .call-user span {
  display: block;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
}

.call-box .call-wrapper .call-avatar {
  cursor: pointer;
  animation: ripple 2s infinite;
  margin: 0 0 50px;
  border-radius: 50%;
}

.call-box .call-wrapper .call-items .call-item {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  color: #ffffff;
  line-height: 0;
  margin: 0 5px;
  padding: 15px;
  border-radius: 100%;
}

@media (max-width: 575px) {
  .call-box .call-wrapper .call-items .call-item {
    padding: 10px;
  }
}

.call-box .call-wrapper .call-items .call-item.call-start {
  background: #55ce63;
  border: 1px solid #55ce63;
  color: #ffffff;
  line-height: 0;
  margin: 30px 20px 0;
  padding: 20px;
  border-radius: 100%;
}

@media (max-width: 575px) {
  .call-box .call-wrapper .call-items .call-item.call-start {
    padding: 10px;
  }
}

.call-box .call-wrapper .call-items .call-item:first-child {
  margin-top: -30px;
}

.call-box .call-wrapper .call-items .call-item.call-end {
  background: #f06060;
  border: 1px solid #f06060;
  color: #ffffff;
  line-height: 0;
  margin: 30px 20px 0;
  padding: 20px;
  border-radius: 100%;
}

@media (max-width: 575px) {
  .call-box .call-wrapper .call-items .call-item.call-end {
    margin: 0 10px;
    padding: 10px;
  }
}

.call-box .call-wrapper .call-items .call-item:last-child {
  margin-top: -30px;
}

.leave-delete-btn {
  font-weight: 500;
  min-height: 30px;
  margin: 0 0 0 10px;
  padding: 2px 15px;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
  }
}

@keyframes ripple {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  100% {
    -moz-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
  }
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none;
  height: auto;
  border-radius: 3px;
  margin: 0 3px !important;
  padding: 6px 12px;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #ff9b44 !important;
  color: #ffffff !important;
  text-shadow: none !important;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}

th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  border-color: #f3f3f3;
  padding: 10px 0;
}

.fc-event {
  border: none;
  cursor: move;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  margin: 1px 7px;
  padding: 5px;
}

.contact-box {
  display: inline-table;
  height: 100%;
  position: relative;
  width: 100%;
  padding: 30px 15px;
}

.contact-list {
  list-style: none;
  width: 96%;
  margin: 0;
  padding: 0;
}

.contact-list>li {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  padding: 10px;
}

.contact-list>li .contact-cont {
  position: relative;
}

.contact-list>li .contact-cont .user-img {
  display: inline-block;
  position: relative;
}

.contact-list>li .contact-cont .user-img .status {
  background-color: #263238;
  display: inline-block;
  border: 2px solid #ffffff;
  height: 10px;
  margin: 0;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.contact-list>li .contact-cont .contact-info {
  padding: 0 30px 0 50px;
}

.contact-list>li .contact-cont .contact-info .text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact-list>li .contact-cont .contact-info .contact-date {
  color: #888888;
  font-size: 12px;
}

.contact-list>li .contact-cont .contact-action {
  height: 30px;
  list-style: none;
  padding-left: 0;
  text-align: right;
  width: 30px;
  position: absolute;
  top: 10px;
  right: 0;
}

.contact-list>li .contact-cont .contact-action .action-icon {
  color: #777;
  font-size: 18px;
  display: inline-block;
}

.bg-soft-dark {
  background-color: rgba(64, 81, 137, 0.18) !important;
}

.bg-soft-light {
  background-color: rgba(243, 246, 249, 0.18) !important;
}

.contact-alphapets {
  background-color: #ffffff;
  border-left: 1px solid #dddddd;
  height: 100%;
  overflow: hidden;
  padding-bottom: 114px;
  width: 50px;
  position: fixed;
  top: 114px;
  right: 0;
  bottom: 0;
}

.contact-alphapets .alphapets-inner {
  height: 100%;
  overflow: auto;
}

.contact-alphapets .alphapets-inner a {
  display: block;
  text-align: center;
  padding: 2px;
  color: #333333;
}

@media (max-width: 991px) {
  .top-action-left .btn-group {
    margin-bottom: 15px;
  }
}

.bg-maroon {
  background-color: #f43b48 !important;
}

.bg-blue {
  background-color: #00c5fb !important;
}

.bg-orange {
  background-color: #ff9b44 !important;
}

.card-radio .form-check-input {
  display: none;
}

.form-check .form-check-input {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #405189;
  border-color: #405189;
}

.card-radio .form-check-input:checked+.form-check-label {
  border-color: #3BB800 !important;
}

.card-radio .form-check-label {
  background-color: var(--vz-card-bg);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-right: 32px;
  padding: 10px;
}

.form-check label {
  cursor: pointer;
}

.card-radio .form-check-input:checked+.form-check-label:before {
  content: "✓";
  font-family: "Line Awesome Free";
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 10px;
  background: #3BB800;
  font-weight: 900;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #F4F4F9;
  transform: translate(0%);
}

.comp-section {
  margin: 0 0 30px;
  padding: 30px 0;
}

.comp-section .section-title {
  font-size: 18px;
  text-decoration: underline;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-example .progress .progress-lg {
  height: 18px;
}

.tab-content {
  padding-top: 20px;
}

.page-title {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
}

.notification-list .status-toggle {
  float: right;
}

.notification-list .status-toggle .check {
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  margin: 0;
  padding: 0;
}

.notification-list .status-toggle .check:checked+.checktoggle {
  background-color: #55ce63;
}

.notification-list .status-toggle .check:checked+.checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
  -webkit-transform: translate(calc(-100% - 5px), -50%);
  -ms-transform: translate(calc(-100% - 5px), -50%);
}

.notification-list .status-toggle .checktoggle {
  background-color: #e0001a;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
  border-radius: 12px;
}

.notification-list .status-toggle .checktoggle:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
  transform: translate(5px, -50%);
  -webkit-transform: translate(5px, -50%);
  -ms-transform: translate(5px, -50%);
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

textarea.form-control {
  resize: vertical;
}

.switch {
  cursor: pointer;
  position: relative;
}

.switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}

.switch input:checked+span {
  background-color: #55ce63;
}

.switch input:checked+span:after {
  left: 31px;
}

.switch span {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 30px;
}

.switch span:after {
  content: "";
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  bottom: 1px;
  left: 6px;
  border-radius: 30px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.mailview-header {
  border-bottom: 1px solid #dddddd;
  margin: 0 0 20px;
  padding: 0 0 15px;
}

.mailview-header .mail-view-title {
  font-weight: 500;
  font-size: 24px;
}

.mailview-header .mail-view-action {
  float: right;
}

.mailview-header .clearfix {
  margin-top: 10px;
}

.sender-info .sender-img {
  float: left;
  margin-right: 10px;
  width: 40px;
}

.sender-info .sender-name {
  display: block;
}

.sender-info .mail-sent-time {
  float: right;
}

.attachments li {
  border: 1px solid #eeeeee;
  float: left;
  width: 200px;
  margin: 0 0 10px 10px;
}

.attachments li .attach-file {
  color: #777;
  font-size: 70px;
  text-align: center;
  min-height: 153px;
  padding: 10px;
}

.attachments li .attach-file i {
  line-height: 133px;
}

.attachments li .attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}

.attachments li .attach-info .attach-filename {
  color: #777;
  font-weight: 700;
}

.mailview-footer {
  border-top: 1px solid #dddddd;
  margin: 20px 0 0;
  padding: 15px 0 0;
}

@media (max-width: 575px) {
  .mailview-footer .left-action {
    text-align: center;
    margin-bottom: 15px;
  }
}

.mailview-footer .right-action {
  text-align: right;
}

@media (max-width: 575px) {
  .mailview-footer .right-action {
    text-align: center;
  }
}

.error-page {
  background-color: rgba(255, 155, 68, 0.2);
  color: #1f1f1f;
}

.error-page .main-wrapper {
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
}

.error-page .main-wrapper .error-box {
  max-width: 480px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;
}

.error-page .main-wrapper .error-box h1 {
  color: #ff9b44;
  font-size: 10em;
}

.error-page .main-wrapper .error-box p {
  margin-bottom: 30px;
}

.error-page .main-wrapper .error-box .btn-custom {
  background: #ff9b44;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  color: #ffffff;
}

.error-page .main-wrapper .error-box .btn-custom:hover {
  border-color: transparent;
}

.error-page .main-wrapper .error-box .btn {
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
  border-radius: 50px;
}

.dash-widget .card-body {
  flex-flow: wrap;
}

.dash-widget .card-body .dash-widget-icon {
  background-color: rgba(255, 155, 68, 0.2);
  color: #ff9b44;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  text-align: center;
  width: 60px;
  border-radius: 100%;
}

.dash-widget .card-body .dash-widget-info {
  text-align: right;
  width: calc(100% - 70px);
}

.dash-widget .card-body .dash-widget-info h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}

.dash-widget .card-body .dash-widget-info span {
  font-size: 16px;
}

.dash-statistics .stats-list {
  height: auto;
}

.dash-statistics .stats-info {
  margin-bottom: 5px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  text-align: center;
  padding: 15px;
  border-radius: 4px;
}

.dash-statistics .stats-info p {
  font-size: 12px;
  margin-bottom: 5px;
}

.dash-statistics .progress {
  height: 4px;
}

.punch-status .stats-box {
  margin-bottom: 0;
}

.stats-box {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  margin: 0 0 15px;
  padding: 5px;
}

.stats-box p {
  margin: 0;
  font-size: 12px;
}

.leave-info-box {
  border: 1px solid #e5e5e5;
  margin: 0 0 15px;
  padding: 15px;
}

.welcome-box {
  background-color: #ffffff;
  border-bottom: 1px solid #ededed;
  position: relative;
  margin: -30px -30px 30px;
  padding: 20px;
}

.welcome-box .welcome-img {
  margin-right: 15px;
}

.welcome-box .welcome-img img {
  width: 60px;
  border-radius: 8px;
}

.welcome-box .welcome-det h3 {
  margin-bottom: 10px;
}

.welcome-box .welcome-det p {
  color: #777;
  font-size: 18px;
  margin-bottom: 0;
}

.dash-section {
  margin-bottom: 30px;
}

.dash-section .dash-sec-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.dash-section .dash-info-list .dash-card {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  color: #575757;
  flex-grow: 1;
  height: auto;
  border-radius: 8px;
  padding: 15px;
}

.dash-section .dash-info-list .dash-card-container {
  flex-direction: row;
  flex-grow: 1;
}

.dash-section .dash-info-list .dash-card-container .dash-card-icon {
  flex-direction: row;
  font-size: 26px;
  padding-right: 15px;
}

.dash-section .dash-info-list .dash-card-container .dash-card-icon i {
  width: 30px;
}

.dash-section .dash-info-list .dash-card-container .dash-card-content {
  flex-direction: row;
}

.dash-section .dash-info-list .dash-card-container .dash-card-content p {
  font-size: 22px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .dash-section .dash-info-list .dash-card-container .dash-card-content p {
    font-size: 16px;
  }
}

.dash-section .dash-info-list .dash-card-container .dash-card-avatars {
  flex-grow: 1;
  padding: 0 15px;
}

.dash-section .dash-info-list .dash-card-container .dash-card-avatars .e-avatar {
  background-color: #ffffff;
  border: 3px solid #e2e5e8;
  height: 45px;
  position: relative;
  width: 45px;
  margin-left: -20px;
  border-radius: 50%;
}

.dash-section .dash-info-list .dash-card-container .dash-card-avatars .e-avatar:first-of-type {
  margin-left: 0 !important;
}

.dash-section .dash-info-list .dash-card-container .dash-card-avatars .e-avatar img {
  border-radius: 50%;
  width: 100%;
}

.dash-section .dash-info-list+.dash-info-list {
  margin-top: 15px;
}

.time-list {
  flex-grow: 1;
  margin-bottom: 20px;
}

.time-list .dash-stats-list {
  flex-flow: column wrap;
  flex-grow: 1;
  padding: 0 15px;
}

.time-list .dash-stats-list h4 {
  color: #1f1f1f;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0;
}

.time-list .dash-stats-list p {
  color: #777;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
  text-transform: uppercase;
}

.time-list .dash-stats-list+.dash-stats-list {
  border-left: 1px solid #eaeaea;
}

.request-btn {
  text-align: center;
}

.dash-sidebar h5 {
  color: inherit;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.load-more a {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  display: inline-block;
  font-size: 14px;
  padding: 5px 15px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.file-wrap {
  border: 1px solid #e0e3e4;
  position: relative;
}

.file-wrap.file-sidebar-toggle .file-sidebar {
  display: none;
}

@media (max-width: 991px) {
  .file-wrap.file-sidebar-toggle .file-sidebar {
    left: 0;
    display: block;
  }
}

.file-wrap.file-sidebar-toggle .file-cont-wrap {
  margin: 0;
}

.file-wrap .file-sidebar {
  border-right: 1px solid #e0e3e4;
  z-index: 5;
  width: 300px;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (max-width: 991px) {
  .file-wrap .file-sidebar {
    position: absolute;
    left: -331px;
  }
}

.file-wrap .file-sidebar .file-header {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e3e4;
  color: #979797;
  height: 72px;
  padding: 0 15px;
}

.file-wrap .file-sidebar .file-header span {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.file-wrap .file-sidebar .file-header .file-side-close {
  display: none;
  background-color: #eeeeee;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  color: #bbb;
  font-size: 14px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
  position: absolute;
  right: 15px;
}

@media (max-width: 991px) {
  .file-wrap .file-sidebar .file-header .file-side-close {
    display: block;
  }
}

.file-wrap .file-sidebar .file-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}

.file-wrap .file-sidebar .file-search .input-group {
  width: 100%;
}

.file-wrap .file-sidebar .file-search .input-group .form-control {
  background-color: #ffffff;
  border-radius: 50px;
  padding-left: 36px;
}

.file-wrap .file-sidebar .file-search .input-group .input-group-text {
  color: #666;
  pointer-events: none;
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: 0;
  background: transparent;
}

.file-wrap .file-sidebar .file-pro-list {
  background-color: #ffffff;
  padding: 15px;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll {
  max-height: calc(100vh - 259px);
  min-height: 497px;
  overflow-y: auto;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .file-menu {
  display: inline-block;
  margin: 0 0 10px;
  padding: 0;
  width: 100%;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .file-menu li {
  display: inline-block;
  width: 100%;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .file-menu li.active,
.file-wrap .file-sidebar .file-pro-list .file-scroll .file-menu li:hover {
  background: rgba(33, 33, 33, 0.05);
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .file-menu li a {
  color: #333333;
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
  padding: 10px 15px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .file-menu li+li {
  margin-top: 2px;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .show-more {
  text-align: center;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .show-more a {
  position: relative;
  padding: 0 20px 0 15px;
}

.file-wrap .file-sidebar .file-pro-list .file-scroll .show-more a:before {
  content: "\f107";
  font-size: 18px;
  line-height: inherit;
  font-family: "FontAwesome";
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0;
}

.file-cont-wrap {
  margin-left: 300px;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (max-width: 991px) {
  .file-cont-wrap {
    margin: 0;
  }
}

.file-cont-wrap .file-cont-inner {
  flex: 0 0 100%;
  max-width: 100%;
}

.file-cont-wrap .file-cont-inner .file-cont-header {
  background: #ffffff;
  border-bottom: 1px solid #e0e3e4;
  height: 72px;
  padding: 0 15px;
}

.file-cont-wrap .file-cont-inner .file-cont-header .file-options a {
  color: #333333;
}

.file-cont-wrap .file-cont-inner .file-cont-header span {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
}

.file-cont-wrap .file-cont-inner .file-cont-header .btn-file {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.file-cont-wrap .file-cont-inner .file-cont-header .btn-file input {
  cursor: pointer;
  direction: ltr;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.file-content {
  flex: 0 0 100%;
  max-width: 100%;
}

.file-content .file-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding: 10px 15px;
}

.file-content .file-search .input-group-text {
  bottom: 0;
  color: #666;
  pointer-events: none;
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0px;
  border: 0;
  background: transparent;
}

.file-content .file-search .form-control {
  background-color: #ffffff;
  padding-left: 36px;
  border-radius: 50px;
}

.file-content .file-body {
  background-color: #f5f5f6;
}

.file-content .file-body .file-scroll {
  max-height: calc(100vh - 259px);
  min-height: 497px;
  overflow-y: auto;
}

.file-content .file-body .file-scroll .file-content-inner {
  padding: 15px;
  width: 100%;
}

.file-content .file-body .file-scroll .file-content-inner .card-file {
  padding: 10px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.file-content .file-body .file-scroll .file-content-inner .card-file .card-body {
  padding: 15px 0 0;
}

.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file {
  position: absolute;
  top: 10px;
  right: 10px;
}

.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-link {
  color: #777;
  width: 24px;
  height: 24px;
}

.file-content .file-body .file-scroll .file-content-inner .card-file .dropdown-file .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inherit;
  background-color: #ffffff;
  font-size: 13px;
  transform-origin: left top 0;
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  border-radius: 3px;
}

.file-content .file-body .file-scroll .file-content-inner .card-file .card-file-thumb {
  background-color: #f5f5f5;
  color: #777;
  font-size: 48px;
  height: 120px;
}

.file-content .file-body .file-scroll .file-content-inner .card-file h6 {
  margin-bottom: 0;
  font-size: 14px;
}

.file-content .file-body .file-scroll .file-content-inner .card-file h6 a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  color: #333333;
}

.file-content .file-body .file-scroll .file-content-inner .card-file span {
  font-size: 12px;
  color: #888888;
}

.file-content .file-body .file-scroll .file-content-inner .card-footer {
  font-size: 11px;
  color: #ababab;
  border-top-width: 0;
  background-color: transparent;
  padding: 10px 0 0;
}

.file-content .file-body .file-scroll .file-content-inner .card-footer span {
  font-size: 12px;
  color: #888888;
}

.account-page .main-wrapper {
  width: 100%;
}

.account-page .main-wrapper .account-content {
  padding: 20px 0;
}

.account-page .main-wrapper .account-content .account-logo {
  margin-bottom: 30px;
  text-align: center;
}

.account-page .main-wrapper .account-content .account-logo img {
  width: 100px;
}

@media (max-width: 575px) {
  .account-page .main-wrapper .account-content .account-logo img {
    width: 70px;
  }
}

.account-page .main-wrapper .account-content .account-box {
  background-color: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  overflow: hidden;
  width: 480px;
  border-radius: 4px;
}

@media (max-width: 991px) {
  .account-page .main-wrapper .account-content .account-box {
    width: 100%;
    margin: 0 0 20px;
  }
}

.account-page .main-wrapper .account-content .account-box .input-block {
  margin-bottom: 25px;
}

.account-page .main-wrapper .account-content .account-box label {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
}

.account-page .main-wrapper .account-content .account-box .form-control {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  height: 46px;
  border-radius: 4px;
}

.account-page .main-wrapper .account-content .account-box #toggle-password {
  cursor: pointer;
  margin-right: 1px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.account-page .main-wrapper .account-content .account-box .account-btn {
  background: #ff9b44;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  border: 0;
  display: block;
  font-size: 22px;
  width: 100%;
  border-radius: 4px;
  padding: 10px 26px;
}

.account-page .main-wrapper .account-content .account-box .account-wrapper {
  padding: 30px;
}

.account-page .main-wrapper .account-content .account-box .account-wrapper .account-footer {
  text-align: center;
}

.account-page .main-wrapper .account-content .account-box .account-wrapper .account-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}

.account-page .main-wrapper .account-content .account-box .account-wrapper .account-subtitle {
  color: #888888;
  font-size: 18px;
  text-align: center;
  margin: 0 0 30px;
}

.account-page .main-wrapper .account-content .account-box .account-wrapper .account-box .input-block {
  margin: 0 0 25px;
}

.account-page .main-wrapper .account-content .account-box .account-wrapper .account-box .input-block label {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 5px;
}

.account-page .main-wrapper .account-content .account-box .account-wrapper .account-box .input-block .form-control {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  height: 46px;
  border-radius: 4px;
}

.account-page .main-wrapper .account-content .account-box .otp-wrap {
  margin-bottom: 30px;
  text-align: center;
}

.account-page .main-wrapper .account-content .account-box .otp-wrap .otp-input {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  height: 70px;
  line-height: 29px;
  margin-right: 15px;
  text-align: center;
  width: 70px;
  border-radius: 4px;
}

@media (max-width: 575px) {
  .account-page .main-wrapper .account-content .account-box .otp-wrap .otp-input {
    height: 50px;
    width: 52px;
    margin: 10px;
  }
}

.account-page .main-wrapper .account-content .account-box .lock-user {
  margin-bottom: 20px;
  text-align: center;
}

.account-page .main-wrapper .account-content .account-box .lock-user img {
  margin-bottom: 15px;
  width: 100px;
}

#loader-wrapper {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#loader-wrapper #loader {
  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;
  margin: -100px 0 0 -100px;
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
}

#loader-wrapper #loader .loader-ellips {
  font-size: 20px;
  position: relative;
  width: 64px;
  margin: 100px auto 0;
}

#loader-wrapper #loader .loader-ellips .loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  position: absolute;
  border-radius: 0.5em;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

#loader-wrapper #loader .loader-ellips .loader-ellips__dot:nth-child(1) {
  background: #404040;
  animation-name: reveal;
  left: 0;
}

#loader-wrapper #loader .loader-ellips .loader-ellips__dot:nth-child(2) {
  background: #ff9b44;
  left: 0;
  animation-name: slide;
}

#loader-wrapper #loader .loader-ellips .loader-ellips__dot:nth-child(3) {
  background: #404040;
  animation-name: slide;
  left: 1.5em;
}

#loader-wrapper #loader .loader-ellips .loader-ellips__dot:nth-child(4) {
  background: #404040;
  animation-name: reveal;
  animation-direction: reverse;
  left: 3em;
}

@keyframes reveal {
  from {
    transform: scale(0.001);
  }

  to {
    transform: scale(1);
  }
}

@keyframes slide {
  to {
    transform: translateX(1.5em);
  }
}

.faq-card .card {
  border-color: #dddddd;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 20px;
  overflow: hidden;
}

.faq-card .card .card-header {
  background-color: #ffffff;
  position: relative;
  padding: 20px;
}

.faq-card .card .card-header h4 {
  margin-bottom: 0;
}

.faq-card .card .card-header h4 a {
  color: #3e3e3e;
  display: block;
  font-size: 20px;
  font-weight: 400;
  padding-right: 60px;
  position: relative;
}

.faq-card .card .card-header h4 a:after {
  font-family: FontAwesome;
  font-size: 19px;
  font-weight: 400;
  position: absolute;
  right: 0;
}

.faq-card .card .card-header h4 a.collapsed:after {
  content: "\f067";
}

.faq-card .card .card-header h4 a:not(.collapsed):after {
  content: "\f068";
}

.view-icons {
  float: right;
  margin-right: 10px;
}

.view-icons .btn {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  color: #888888;
  font-size: 18px;
  min-width: 40px;
  margin: 0 5px 0 0;
  padding: 7px 0 3px 0;
}

.view-icons .btn.active {
  color: #333333;
}

.add-btn {
  background-color: #ff9b44;
  border: 1px solid #ff9b44;
  color: #ffffff;
  float: right;
  font-weight: 500;
  min-width: 140px;
  border-radius: 50px;
}

.add-btn i {
  margin-right: 5px;
}

.add-btn:hover {
  color: #ffffff;
  background-color: #ff9b44;
  border: 1px solid #ff9b44;
}

.avatar-dropdown .dropdown-toggle:after {
  display: none;
}

.profile-widget {
  background-color: #ffffff;
  border: 1px solid #ededed;
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.profile-widget .profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
}

.profile-widget .profile-img .avatar {
  font-size: 24px;
  height: 80px;
  line-height: 80px;
  margin: 0;
  width: 80px;
}

.profile-widget .dropdown.profile-action {
  text-align: right;
  position: absolute;
  top: 10px;
  right: 5px;
}

.profile-widget .dropdown.profile-action .action-icon {
  color: #777;
  font-size: 18px;
  display: inline-block;
}

.profile-widget .dropdown.profile-action .dropdown-toggle::after {
  display: none;
}

.profile-widget .user-name {
  color: #333333;
}

.profile-widget .user-name a {
  color: #333333;
}

.stats-info {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  text-align: center;
  border-radius: 4px;
  margin: 0 0 20px;
  padding: 15px;
}

.stats-info h6 {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 5px;
}

.stats-info h4 {
  font-size: 24px;
  margin-bottom: 0;
}

.stats-info h4 span {
  color: #727272;
  font-size: 12px;
}

.leave-item {
  max-width: 653px;
}

.leave-item .leave-row {
  margin-bottom: 15px;
}

.leave-item .leave-row .leave-left {
  flex: 1 1 auto;
}

.leave-item .leave-row .leave-left .input-box {
  max-width: 410px;
}

.leave-item .leave-row .input-block {
  margin-bottom: 0;
}

.leave-item .leave-right {
  flex: 0 0 200px;
  justify-content: end;
  margin-top: 31px;
  min-height: 44px;
  text-align: right;
}

.leave-item .leave-right .btn+.btn {
  margin-left: 10px;
}

.leave-item .leave-right .leave-edit-btn {
  color: #216ef4;
  background-color: transparent;
  border: 0;
  padding: 0 6px;
  transition: unset;
}

.leave-header {
  color: #2E3842;
  margin-bottom: 20px;
}

.leave-header .title {
  flex: 1 1 auto;
}

.leave-header .leave-action {
  flex: 1 1 auto;
  text-align: right;
}

.half-day {
  width: 15px;
}

.half-day .first-off {
  white-space: pre-wrap;
}

.project-title {
  margin: 0 0 5px;
}

.project-title a {
  color: #333333;
}

.team-members {
  flex-wrap: wrap;
}

.team-members img {
  width: 100%;
}

.team-members a {
  border: 2px solid #ffffff;
  display: block;
  height: 30px;
  overflow: hidden;
  width: 30px;
  border-radius: 100%;
}

.team-members .all-users {
  background-color: #f83f37;
  color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

.profile-action {
  text-align: right;
  position: absolute;
  top: 10px;
  right: 5px;
}

.project-members a {
  margin-left: -10px;
  margin-right: 0;
  position: relative;
}

.project-members a:first-child {
  margin-left: 0;
}

.project-members .team-members li a {
  margin-left: -10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.project-members .team-members li:first-child a {
  margin-left: 0;
}

.project-members.task-followers :nth-child(2) {
  margin-left: 0;
}

.pro-team-members h4 {
  margin-bottom: 0;
}

table .team-members li a {
  margin-left: -10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

table .team-members li:first-child a {
  margin-left: 0;
}

.modal .modal-body .input-block.row {
  margin-left: auto;
}

.header .has-arrow.flag-nav .dropdown-toggle:after {
  position: relative;
  top: -2px;
}

.dropdown .btn-rounded {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.table-avatar a.avatar {
  line-height: normal;
}

.progress-bar.w-40 {
  width: 40%;
}

.progress-bar.w-65 {
  width: 65%;
}

.progress-bar.w-15 {
  width: 15%;
}

.progress-bar.w-88 {
  width: 88%;
}

.progress-bar.w-70 {
  width: 70%;
}

.progress-bar.w-31 {
  width: 31%;
}

.progress-bar.w-62 {
  width: 62%;
}

.progress-bar.w-22 {
  width: 22%;
}

.progress-bar.w-24 {
  width: 24%;
}

.progress-bar.w-30 {
  width: 30%;
}

.progress-bar.w-26 {
  width: 26%;
}

.progress-bar.w-10 {
  width: 10%;
}

.progress-bar.w-21 {
  width: 21%;
}

.progress-bar.w-20 {
  width: 20%;
}

th.width-pixel {
  width: 40px;
}

.btn-close:focus {
  box-shadow: none;
}

.progress.height-five {
  height: 5px;
}

textarea.form-control {
  height: auto;
}

td.tdata-width {
  width: 230px;
}

th.width-thirty {
  width: 30px;
}

.badge.min-w-90 {
  min-width: 90px;
}

td.w-50pixel {
  width: 50px;
}

td.w-300pixel {
  width: 300px;
}

.progress.height-5 {
  height: 5px;
}

.progress-bar.w-73 {
  width: 73%;
}

.progress-bar.height-10 {
  height: 10px;
}

.form-control.width-160 {
  width: 160px;
}

thead .bg-thead {
  background: #f2f2f2;
}

th.width-250 {
  width: 250px;
}

th.width-70 {
  width: 70px;
}

td.width-55 {
  min-width: 55px;
  max-width: 55px;
  width: 55px;
}

a.green-border {
  border: 2px dashed #1eb53a;
}

th.width-64 {
  width: 64px;
}

.board-view-header {
  margin: 0 0 30px;
}

.board-view-header .pro-teams {
  text-align: center;
  margin: 0 20px 15px 0;
}

@media (max-width: 991px) {
  .board-view-header .pro-teams {
    display: none;
  }
}

.board-view-header .pro-teams .avatar-title {
  background-color: #ff9b44;
}

.board-view-header .pro-teams .pro-team-lead h4 {
  font-size: 16px;
  margin: 0 0 0 6px;
}

.board-view-header .pro-teams .pro-team-lead h4 .avatar-group .avatar {
  margin-right: 0;
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  background-color: transparent;
}

.board-view-header .pro-teams .pro-team-lead h4 .avatar-group .avatar .border {
  border: 3px solid #ffffff !important;
}

.board-view-header .pro-teams .pro-team-members {
  margin-left: 15px;
}

.board-view-header .pro-teams .avatar-group .avatar {
  margin: 0 -0.75rem 0 0;
}

.board-view-header .pro-progress-bar .progress {
  width: 100%;
  height: 15px;
  background-color: #e3e3e3;
  box-shadow: unset;
  margin: 0 10px 0 0;
}

.board-view-header .pro-progress-bar h4 {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 13px;
  text-transform: uppercase;
}

.kanban-cont {
  overflow: auto;
  padding: 0 0 20px;
}

.kanban-cont .kanban-list {
  align-self: flex-start;
  min-width: 300px;
  width: 300px;
  margin: 0 30px 0 0;
}

.kanban-cont .kanban-list.kanban-danger {
  background-color: #fef7f6;
}

.kanban-cont .kanban-list.kanban-danger .kanban-header {
  background-color: #ef5350;
}

.kanban-cont .kanban-list.kanban-danger .progress-bar {
  background-color: #ef5350;
}

.kanban-cont .kanban-list .kanban-wrap {
  padding: 20px;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-header {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px 5px;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-header a {
  color: #1f1f1f;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-body {
  font-size: 13px;
  padding: 0 15px 15px;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-info {
  font-size: 12px;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-info .progress {
  width: 100%;
  margin: 0 10px 0 0;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer {
  font-size: 12px;
  margin-top: 10px;
  align-items: end;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer .task-date {
  display: block;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer .task-avatar {
  border-radius: 50%;
  position: relative;
}

.kanban-cont .kanban-list .kanban-wrap .task-board-body .kanban-footer .task-user-count {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: 1px;
}

.kanban-cont .kanban-list .kanban-wrap .card {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  box-shadow: unset;
  margin: 0 0 12px;
}

.kanban-cont .kanban-list .add-new-task {
  text-align: center;
  padding: 0 20px 20px;
}

.kanban-cont .kanban-list .add-new-task a {
  color: #333333;
}

.kanban-cont .kanban-list .kanban-header {
  padding: 10px 20px;
}

.kanban-cont .kanban-list .kanban-header .status-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.kanban-cont .kanban-list .kanban-header .kanban-action>a {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.kanban-cont .kanban-list.kanban-info {
  background-color: #e7f3fe;
}

.kanban-cont .kanban-list.kanban-info .kanban-header {
  background-color: #42a5f5;
}

.kanban-cont .kanban-list.kanban-info .progress-bar {
  background-color: #42a5f5;
}

.kanban-cont .kanban-list.kanban-success {
  background-color: #edf7ee;
}

.kanban-cont .kanban-list.kanban-success .kanban-header {
  background-color: #4caf50;
}

.kanban-cont .kanban-list.kanban-success .progress-bar {
  background-color: #4caf50;
}

.kanban-cont .kanban-list.kanban-warning {
  background-color: #fdfcf3;
}

.kanban-cont .kanban-list.kanban-warning .kanban-header {
  background-color: #ffb300;
}

.kanban-cont .kanban-list.kanban-warning .progress-bar {
  background-color: #ffb300;
}

.kanban-cont .kanban-list.kanban-purple {
  background-color: #f1effd;
}

.kanban-cont .kanban-list.kanban-purple .kanban-header {
  background-color: #7460ee;
}

.kanban-cont .kanban-list.kanban-purple .progress-bar {
  background-color: #7460ee;
}

.kanban-cont .kanban-list.kanban-primary {
  background-color: #fff5ec;
}

.kanban-cont .kanban-list.kanban-primary .kanban-header {
  background-color: #ff9b44;
}

.kanban-cont .kanban-list.kanban-primary .progress-bar {
  background-color: #ff9b44;
}

.chat-main-row {
  overflow: auto;
  padding-bottom: inherit;
  padding-top: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.chat-main-row .chat-main-wrapper {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}

.chat-main-row .chat-main-wrapper .message-view {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  width: 75%;
}

@media (max-width: 991px) {
  .chat-main-row .chat-main-wrapper .message-view.task-view {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .chat-main-row .chat-main-wrapper .message-view.chat-profile-view {
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
    width: 300px;
    display: table-cell;
    margin: 60px -300px 0 0;
    padding: 0 0 60px;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }

  .chat-main-row .chat-main-wrapper .message-view.chat-profile-view.opened {
    margin-right: 0;
  }
}

.chat-main-row .chat-main-wrapper .task-left-sidebar {
  width: 58.3333%;
}

@media (max-width: 991px) {
  .chat-main-row .chat-main-wrapper .task-left-sidebar {
    width: 100%;
  }

  .chat-main-row .chat-main-wrapper .task-left-sidebar.chat-sidebar {
    display: none;
  }
}

.chat-main-row .chat-main-wrapper .task-right-sidebar {
  width: 41.6667%;
}

@media (max-width: 991px) {
  .chat-main-row .chat-main-wrapper .task-right-sidebar {
    z-index: 1041;
    width: 300px;
    display: table-cell;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    margin: 60px -300px 0;
    padding: 0 0 60px;
    position: fixed;
    top: 0;
    right: 0;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .chat-main-row .chat-main-wrapper .task-right-sidebar.opened {
    margin-right: 0;
  }
}

.chat-main-row .chat-main-wrapper .chat-window {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  background-color: #f7f7f7;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .user-details a {
  color: #555;
  text-transform: uppercase;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .user-details .typing-text {
  color: #ff9b44;
  font-size: 12px;
  text-transform: lowercase;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .user-details .last-seen {
  color: #888888;
  display: block;
  font-size: 12px;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
  padding: 0;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .navbar .profile-rightbar {
  display: none;
  color: #bbb;
  font-size: 26px;
  margin-left: 15px;
}

@media (max-width: 991px) {
  .chat-main-row .chat-main-wrapper .chat-window .fixed-header .navbar .profile-rightbar {
    display: block;
  }
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu {
  margin: 1px 0 0;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu li a {
  color: #bbb;
  font-size: 22px;
  line-height: 32px;
  margin: 0 0 0 15px;
  padding: 0;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu li .dropdown-menu {
  left: auto;
  right: 0;
  margin: 15px 0 0;
  padding: 0;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu li .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #283447;
  margin: 0;
  padding: 0 10px;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu li .custom-dropdown-menu {
  margin: 0;
  padding: 0 0 0 0;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu li .custom-dropdown-menu .dropdown-item {
  margin: 0;
  margin: 0;
  padding: 0 10px 0 10px;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu li .dropdown-menu-right a {
  font-size: 14px;
}

.chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu .profile-rightbar {
  display: none;
}

@media (max-width: 991px) {
  .chat-main-row .chat-main-wrapper .chat-window .fixed-header .custom-menu .profile-rightbar {
    display: block;
  }
}

.task-wrapper {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.task-wrapper .task-list-body {
  max-height: 500px;
  overflow: auto;
}

.task-wrapper .task-list-body #task-list {
  border-bottom: 1px solid #eaeaea;
}

.task-wrapper .task-list-body #task-list li {
  margin: 0;
  padding: 0;
}

.task-wrapper .task-list-body #task-list li.completed .task-container {
  background: #f9f9f9;
}

.task-wrapper .task-list-body #task-list li.completed .task-container .complete-btn {
  background: #35BA67 !important;
  border: 1px solid #35BA67 !important;
  color: #ffffff;
}

.task-wrapper .task-list-body #task-list li .task-container {
  display: table;
  background: #ffffff;
  width: 100%;
  border: 1px solid #eaeaea;
  border-bottom: none;
  box-sizing: border-box;
  position: relative;
  padding: 8px 15px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.task-wrapper .task-list-body #task-list li .task-container .task-action-btn {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}

.task-wrapper .task-list-body #task-list li .task-container .task-action-btn.task-check {
  text-align: left !important;
  width: 40px;
}

.task-wrapper .task-list-body #task-list li .task-container .task-action-btn .action-circle {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.task-wrapper .task-list-body #task-list li .task-container .task-action-btn .action-circle.large {
  height: 24px;
  width: 24px;
}

.task-wrapper .task-list-body #task-list li .task-container .task-action-btn .action-circle.large .material-icons {
  font-size: 16px;
  vertical-align: -4px;
}

.task-wrapper .task-list-body #task-list li .task-container .task-label {
  display: table-cell;
  font-weight: 400;
  vertical-align: middle;
  color: #333333;
  word-break: break-all;
}

.task-wrapper .task-list-body #task-list li .task-container .task-btn-right {
  display: none;
}

.task-wrapper .task-list-body #task-list li .task-container .task-btn-right .action-circle.large {
  height: 24px;
  width: 24px;
}

.task-wrapper .task-list-body #task-list li .task-container .task-btn-right .action-circle.large .material-icons {
  font-size: 16px;
  vertical-align: -4px;
  color: #cccccc;
}

.task-wrapper .task-list-body #task-list li .task-container:hover .task-btn-right {
  display: block;
}

.task-wrapper .task-list-body #task-list li.completed .task-container .task-label {
  color: #ccc;
}

.task-wrapper .task-list-footer {
  position: relative;
}

.task-wrapper .task-list-footer .new-task-wrapper {
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  overflow: hidden;
  visibility: hidden;
}

.task-wrapper .task-list-footer .new-task-wrapper .visible {
  max-height: 300px;
  overflow: auto;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #eaeaea;
  border-top: 0;
  height: 45px;
  resize: none;
  margin: 0 0 20px;
  padding: 10px 45px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.task-wrapper .task-list-footer .new-task-wrapper .error-message {
  color: #dc3545;
  font-style: italic;
}

.task-wrapper .task-list-footer .new-task-wrapper .add-new-task-btn {
  background: #ffffff;
  border: 1px solid #cccccc;
}

.task-wrapper .task-list-footer .new-task-wrapper .btn {
  float: right;
  cursor: pointer;
  padding: 6px 12px;
}

.chat-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}

.chat-content-wrap .chat-wrap-inner {
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.chat-content-wrap .chat-wrap-inner .chats {
  padding: 15px 15px 30px;
}

.chat-content-wrap .chat-wrap-inner .task-header {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px 5px;
}

.chat-content-wrap .chat-wrap-inner .task-header .assignee-info {
  border: 1px solid transparent;
  flex: 0 1 180px;
  position: relative;
  border-radius: 10px;
  padding: 5px 10px 5px 5px;
}

.chat-content-wrap .chat-wrap-inner .task-header .assignee-info:hover {
  border-color: #e3e3e3;
}

.chat-content-wrap .chat-wrap-inner .task-header .assignee-info:hover .remove-icon {
  visibility: visible;
}

.chat-content-wrap .chat-wrap-inner .task-header .assignee-info a {
  color: #333333;
}

.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .remove-icon {
  background-color: #e3e3e3;
  border-radius: 50%;
  color: #333333;
  cursor: pointer;
  height: 20px;
  margin-right: 10px;
  transform: translateY(-50%);
  visibility: hidden;
  width: 20px;
  position: absolute;
  top: 50%;
  right: 0;
}

.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .task-head-title {
  color: #a6a6a6;
  font-size: 12px;
}

.chat-content-wrap .chat-wrap-inner .task-header .assignee-info .task-assignee {
  font-size: 13px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-content-wrap .chat-wrap-inner .task-header .task-due-date {
  border: 1px solid transparent;
  flex: 0 0 160px;
  position: relative;
  margin: 0 0 0 10px;
  padding: 5px 10px 5px 5px;
  border-radius: 10px;
}

.chat-content-wrap .chat-wrap-inner .task-header .task-due-date:hover {
  border-color: #e3e3e3;
}

.chat-content-wrap .chat-wrap-inner .task-header .task-due-date:hover .remove-icon {
  visibility: visible;
}

.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #cccccc;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  width: 30px;
}

.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .due-icon span i {
  font-size: 18px;
  position: relative;
  top: 4px;
}

.chat-content-wrap .chat-wrap-inner .task-header .task-due-date a {
  color: #333333;
}

.chat-content-wrap .chat-wrap-inner .task-header .task-due-date .remove-icon {
  background-color: #e3e3e3;
  border-radius: 50%;
  color: #333333;
  cursor: pointer;
  height: 20px;
  visibility: hidden;
  width: 20px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0;
  margin: 0 10px 0 0;
}

.chat-content-wrap .chat-wrap-inner .task-desc {
  align-items: flex-start;
  margin: 5px 0 15px;
}

.chat-content-wrap .chat-wrap-inner .task-desc .task-desc-icon {
  color: #cccccc;
  margin: 5px 0 15px;
}

.chat-content-wrap .chat-wrap-inner .task-desc .task-textarea {
  width: 100%;
}

.chat-content-wrap .chat-wrap-inner .task-information {
  color: #a3a3a3;
  font-size: 11px;
  line-height: 17px;
  min-width: 1px;
  margin-left: 50px;
}

.chat-content-wrap .chat-wrap-inner .task-information .task-info-line {
  overflow-wrap: normal;
}

.chat-content-wrap .chat-wrap-inner .task-information .task-info-line .task-user {
  color: #888888;
  font-weight: 500;
}

.chat-content-wrap .chat-wrap-inner .task-information .task-time {
  display: inline-block;
  padding-left: 10px;
}

.task-chat-contents {
  background-color: #ffffff;
}

.task-chat-contents .chat.chat-left {
  margin: 0 0 20px;
}

.task-chat-contents .completed-task-msg {
  margin: 0 0 30px 50px;
}

.task-chat-contents .completed-task-msg .task-success {
  color: #55ce63;
  font-size: 13px;
}

.task-chat-contents .completed-task-msg .task-time {
  color: #a3a3a3;
  display: inline-block;
  font-size: 11px;
}

.chat-footer {
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
  padding: 15px;
}

.chat-footer .call-duration {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}

@media (max-width: 575px) {
  .chat-footer .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }
}

.chat-footer .task-followers {
  margin-top: 15px;
}

.chat-footer .task-followers .followers-title {
  margin: 5px 10px 0 0;
}

.chat-footer .task-followers .followers-title .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.chat-footer .task-followers .followers-add {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: #cccccc;
  display: inline-block;
  font-size: 20px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 34px;
}

.chat-footer .task-followers .followers-add i {
  line-height: 28px;
}

.chat-footer .message-bar {
  display: table;
  height: 44px;
  position: relative;
  width: 100%;
}

.chat-footer .message-bar .message-inner {
  display: table-row;
  height: 100%;
  width: 100%;
  padding: 0 8px;
}

.chat-footer .message-bar .link {
  color: #777;
  display: table-cell;
  font-size: 20px;
  position: relative;
  vertical-align: middle;
  width: 30px;
}

.chat-footer .message-bar .message-area {
  display: table-cell;
}

.chat-footer .message-bar .message-area .input-group {
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.chat-footer .message-bar .message-area .input-group .form-control {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: none;
  color: #555;
  display: block;
  font-size: 14px;
  height: 44px;
  margin: 0;
  padding: 6px 12px;
  resize: none;
}

.chat-footer .message-bar .message-area .btn {
  height: 44px;
  width: 50px;
}

.chat-footer .btn-custom {
  background: #ff9b44;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  color: #ffffff;
}

@media (max-width: 991px) {
  .leave-box .subtitle {
    display: none;
  }
}

.task-chat-view .chat-left .chat-content {
  border: 0 !important;
  padding: 0 !important;
}

.task-chat-view .chat-left .chat-content p {
  font-size: 13px;
  margin-bottom: 0;
}

.notification-popup {
  z-index: 1051;
  width: 300px;
  display: inline-block;
  background: #1F8FEF;
  border: 1px solid #1082e4;
  color: #ffffff;
  opacity: 0.8;
  position: fixed;
  top: 20px;
  right: 10px;
  border-radius: 2px;
  padding: 20px;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.notification-popup.hide {
  opacity: 0;
  visibility: hidden;
}

.task-assign {
  float: left;
}

.task-assign a {
  float: left;
}

.task-assign .task-complete-btn {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #333333;
  font-size: 14px;
  padding: 6px 10px;
  border-radius: 5px;
}

.search-result {
  margin: 0 0 30px;
}

.search-result u {
  color: #ff9b44;
}

.search-result p {
  color: #777;
  margin-bottom: 0;
}

.search-lists .nav-tabs.nav-tabs-solid {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
}

.main-search {
  margin: 0 0 20px;
}

.small-container {
  max-width: 1100px;
  margin: auto;
  padding: 30px;
}

.small-container .inner-header {
  margin: 0 0 30px;
}

.small-container .inner-content .lead {
  font-size: 20px;
  font-weight: 300;
}

.avatar-dropdown .page-link {
  border: 0;
  width: 25px;
  text-align: center;
  border-radius: 0;
  padding: 10px 5px;
}

.avatar-dropdown .dropdown-menu {
  min-width: unset;
  width: 100px;
  padding: 0;
}

.avatar-dropdown .avatar-group {
  min-height: 88px;
  white-space: normal;
  padding: 2px;
}

.avatar-dropdown .avatar-group .avatar {
  margin: 2px;
}

.avatar-dropdown .avatar-group .avatar-xs+.avatar-xs {
  margin-left: -1.25rem;
}

.avatar-dropdown .avatar-pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.roles-menu {
  margin: 20px 0;
}

@media (max-width: 767px) {
  .roles-menu {
    margin: 15px 0;
  }
}

.roles-menu ul {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
}

.roles-menu ul li {
  display: block;
  position: relative;
}

.roles-menu ul li.active a {
  border-color: #ff9b44;
  color: #ff9b44;
}

.roles-menu ul li a {
  border-left: 3px solid transparent;
  color: #333333;
  display: block;
  position: relative;
  padding: 10px 15px;
}

.roles-menu ul li a:hover {
  background-color: #eeeeee;
  border-color: #ff9b44;
}

.roles-menu ul li a:hover .role-action {
  display: block;
}

.roles-menu ul li .role-action {
  float: right;
  display: none;
}

.roles-menu ul li .role-action .action-circle {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  height: 24px;
  width: 24px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.roles-menu ul li .role-action .action-circle .material-icons {
  font-size: 16px;
  vertical-align: -4px;
  color: #cccccc;
}

.rating-list span {
  display: inline-block;
  margin-right: 4px;
}

.rating-list span a {
  background-color: #cccccc;
  color: #333333;
  width: 50px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  border-radius: 4px;
}

.rating-list span.rating-normal {
  text-align: center;
}

.rating-list span.rating-normal a {
  background-color: #ad0d20;
  color: #ffffff;
}

.rating-list span.rating-bad a {
  background-color: #dccc00;
  color: #ffffff;
}

.rating-list span.rating-good {
  text-align: right;
}

.rating-list span.rating-good a {
  background-color: #00a100;
  color: #ffffff;
}

.rating-list .rating-text {
  font-size: 12px;
  max-width: 157px;
  display: inline-block;
  margin-top: 5px;
}

.custom_radio {
  color: #555;
  display: inline-block;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  margin: 0 0 10px;
  padding: 0 0 0 30px;
}

.custom_radio input {
  position: absolute;
  opacity: 0;
}

.custom_radio input:checked~.checkmark:after {
  opacity: 1;
}

.custom_radio .checkmark {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.custom_radio .checkmark:after {
  display: block;
  content: "";
  opacity: 0;
  width: 12px;
  height: 12px;
  background: #ff9b44;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (max-width: 575px) {
  .contact-cat {
    padding: 0 70px 0 15px;
  }
}

@media (max-width: 575px) {
  .contacts-list {
    padding: 0 70px 0 15px;
  }

  .contacts-list .contact-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: 100%;
  }
}

.job-list {
  background-color: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  display: block;
  margin-bottom: 35px;
  position: relative;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.job-list .job-list-det {
  align-items: flex-start;
  padding: 20px;
}

.job-list .job-list-desc {
  flex: 1 1 0;
}

.job-list h3.job-list-title {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.job-list h4.job-department {
  color: #777;
  font-size: 14px;
  margin-bottom: 0;
}

.job-list .job-types {
  background-color: transparent;
  border: 1px solid #ff9b44;
  color: #ff9b44;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  padding: 6px 12px;
}

.job-list .job-types:hover {
  background: #ff9b44;
  color: #ffffff;
}

.job-list .job-list-footer {
  background-color: #f9f9f9;
  border-radius: 0 0 4px 4px;
  position: relative;
  padding: 20px;
}

.job-list .job-list-footer ul li {
  color: #777;
  display: inline-block;
  margin-right: 14px;
}

.job-list .job-list-footer ul li i {
  color: #777;
  margin-right: 3px;
  position: relative;
}

.page-wrapper.job-wrapper {
  margin: 0 !important;
}

.job-widget {
  background-color: #ffffff;
  border: 1px solid #efefef;
  float: left;
  position: relative;
  width: 100%;
  margin: 0 0 30px;
  padding: 30px;
}

.job-widget h4 {
  color: #585858;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 20px;
}

.job-widget .job-description p {
  color: #727272;
  line-height: 28px;
}

.job-widget .job-title {
  color: #585858;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}

.job-widget .job-dept {
  color: #737882;
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.job-widget .job-post-det {
  display: inline-block;
  margin-bottom: 5px;
  width: 100%;
  color: #737882;
}

.job-widget .job-post-det li {
  float: left;
  list-style: none;
  margin-right: 20px;
}

.job-widget .job-post-det li .text-blue {
  color: #009ce7;
}

.square-list li {
  color: #727272;
  line-height: 24px;
  list-style: none;
  margin: 10px 0 0 20px;
  position: relative;
}

.square-list li:before {
  font-family: fontawesome;
  margin: 0;
  position: relative;
  color: #ff9b44;
  float: left;
  margin-left: -20px;
  display: block;
  content: "\f0c8";
  font-size: 8px;
}

.job-btn {
  border: 2px solid #ff9b44;
  color: #ff9b44;
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.job-btn:hover {
  background-color: #ff9b44;
  color: #ffffff;
  border-color: #ff9b44;
}

.info-list {
  color: #888888;
  padding-top: 20px;
}

.info-list span {
  color: #ff9b44;
  float: left;
  font-size: 20px;
  line-height: 14px;
}

.info-list h5 {
  color: #585858;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 40px;
  text-transform: uppercase;
}

.info-list p {
  line-height: 1.7;
  margin: 0 0 0 40px;
}

.info-list .app-ends {
  color: #46cd38;
  font-size: 13px;
}

.modal button.btn-close {
  background: transparent;
  border: 0;
}

.w-sidebar {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.w-sidebar ul {
  padding: 15px;
}

.w-sidebar ul a {
  color: #333333;
}

@media (max-width: 991px) {
  .message-view .task-view {
    width: 100%;
  }

  .task-chat-view {
    display: none !important;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    right: 0;
    transform: translateX(0px);
    z-index: 1041;
    width: 300px !important;
    position: fixed !important;
    transition: all 0.4s ease 0s;
    margin-right: -300px;
    display: table-cell;
    top: 0;
    padding-bottom: 60px;
    margin-top: 60px;
  }

  .task-chat-view.opened {
    margin-right: 0 !important;
    display: block !important;
  }
}

.sidebar-headerset {
  width: 100%;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F5F5F5;
}

.sidebar-headerset h2 {
  font-size: 24px;
  font-weight: 600;
}

.sidebar-headerset h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.settings-mains {
  padding: 15px;
}

.settings-mains .layout-head {
  margin-bottom: 15px;
}

.settings-mains .layout-head h5 {
  font-size: 16px;
  font-weight: 600;
  color: #272B41;
}

.settings-mains .layout-head h6 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #272B41;
}

#layout-position .radio input+label {
  min-width: 110px;
  border: 0;
}

.bor-rad-50 {
  border-radius: 50px;
}

.bg-sidebarcolor {
  background: #F9F9F9;
  height: 45px;
  display: block;
  border-radius: 5px;
}

.bg-sidebarcolor.bg-darks {
  background: #34444c;
}

.bg-sidebarcolor.bg-gradients {
  background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%);
}

.bg-sidebarcolor.bg-oranges {
  background: #FF9B44;
}

.bg-sidebarcolor.bg-darkset {
  background: #16191C;
}

.account-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.profile-view {
display: flex;
align-items: center;
}

.profile-view .profile-img-wrap {
  height: 120px;
  width: 120px;
}


@media (max-width: 575px) {
  .profile-view .profile-img-wrap {
    position: relative;
    margin: 0 auto;
  }
}

.profile-view .profile-img-wrap img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.profile-view .profile-img {
  width: 120px;
  height: 120px;
}

.profile-view .profile-basic {
  margin-left: 20px;
  padding-right: 50px;
}

@media (max-width: 575px) {
  .profile-view .profile-basic {
    margin-left: 0;
    padding-right: 0;
  }
}

.profile-view .profile-basic .profile-info-left {
  border-right: 2px dashed #cccccc;
}

@media (max-width: 575px) {
  .profile-view .profile-basic .profile-info-left {
    text-align: center;
    padding: 15px 0;
  }
}

@media (max-width: 768px) {
  .profile-view .profile-basic .profile-info-left {
    border-right: none;
    border-bottom: 2px dashed #cccccc;
    margin: 0 0 20px;
    padding: 0 0 20px;
  }
}

.profile-view .profile-basic .profile-info-left .user-name {
  color: #333333;
}

.profile-view .profile-basic .profile-info-left .staff-id {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.profile-view .profile-basic .profile-info-left .staff-msg {
  margin-top: 30px;
}

.profile-view .profile-basic .profile-info-left .staff-msg .btn-custom {
  background: #ff9b44;
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  color: #ffffff;
}

.profile-view .profile-basic .profile-info-left .staff-msg .btn-custom:hover {
  border-color: #ff9b44;
}

.profile-view .pro-edit {
  position: absolute;
  top: 20px;
  right: 20px;
}

.profile-action .dropdown-toggle:after {
  display: none;
}

.edit-icon {
  background-color: #eeeeee;
  border: 1px solid #e3e3e3;
  color: #bbb;
  float: right;
  font-size: 12px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
  border-radius: 24px;
}

.personal-info li {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .personal-info li {
    width: 100%;
  }
}

.personal-info li .title {
  color: #333333;
  float: left;
  font-weight: 500;
  width: 30%;
}

@media (max-width: 575px) {
  .personal-info li .title {
    width: 50%;
    margin: 0;
  }
}

.personal-info li .text {
  color: #888888;
  display: block;
  overflow: hidden;
  width: 70%;
  float: left;
}

.personal-info li .text .avatar-box {
  float: left;
  position: relative;
}

.personal-info li .text .avatar-box .avatar-xs {
  line-height: normal;
}

.delete-icon {
  color: #f83f37;
  float: right;
  font-size: 18px;
}

.experience-box {
  position: relative;
}

.experience-box .experience-list {
  position: relative;
}

.experience-box .experience-list::before {
  background: #dddddd;
  bottom: 0;
  content: "";
  width: 2px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.experience-box .experience-list li {
  position: relative;
}

.experience-box .experience-list li .experience-user {
  background: #ffffff;
  height: 10px;
  width: 10px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  left: 4px;
}

.experience-box .experience-list li .experience-user .before-circle {
  background-color: #dddddd;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.experience-box .experience-list li .experience-content {
  background-color: #ffffff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}

.experience-box .experience-list li .experience-content .timeline-content {
  color: #9e9e9e;
}

.experience-box .experience-list li .experience-content .timeline-content a.name {
  color: #616161;
  font-weight: 700;
}

.experience-box .experience-list li .experience-content .time {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 1.35;
}

.submit-btn {
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
  border-radius: 50px;
}

.profile-img-wrap {
  height: 120px;
  width: 120px;
  background: #ffffff;
  overflow: hidden;
}

@media (max-width: 575px) {
  .profile-img-wrap {
    position: relative;
    margin: 0 auto;
  }
}

.profile-img-wrap.edit-img {
  border-radius: 50%;
  margin: 0 auto 30px;
  position: relative;
}

.profile-img-wrap img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.profile-img-wrap .fileupload {
  background: rgba(33, 33, 33, 0.5);
  border: none;
  padding: 3px 10px;
  border-radius: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.profile-img-wrap .fileupload .btn-text {
  color: #ffffff;
}

.profile-img-wrap .fileupload .upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  opacity: 0;
  margin: 0;
  padding: 5px;
  position: absolute;
  top: -3px;
  right: -3px;
}

.payslip-title {
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.inv-logo {
  height: auto;
  max-height: 100px;
  width: auto;
  margin: 0 0 20px;
}

.invoice-details {
  float: right;
  text-align: right;
}

.topics {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin: 0 0 30px;
  padding: 20px;
}

.topics .topic-title {
  color: #333333;
  margin: 0 0 20px;
  padding: 0 25px 0 0;
  position: relative;
}

.topics .topic-title a {
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}

.topics .topic-title a i {
  color: #ff9b44;
  margin-right: 5px;
}

.topics .topic-title a span {
  color: #ff9b44;
}

.topics .topics-list {
  margin: 0;
  padding: 0;
}

.topics .topics-list li {
  position: relative;
  list-style: none;
  line-height: 2;
}

.topics .topics-list li:before {
  font-family: FontAwesome;
  content: "\f016";
  color: #555;
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.topics .topics-list li a {
  padding-left: 25px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  outline: 0 none;
}

.equal-height-cards .card {
  height: 100%;
}

.equal-height-cards .pricing-box {
  text-align: center;
}

.equal-height-cards .pricing-box ul {
  text-align: left;
}

.equal-height-cards .pricing-box ul li {
  line-height: 35px;
}

.equal-height-cards .pricing-box ul li i {
  color: #26af48;
  margin-right: 5px;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eeeeee;
  text-align: center;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}

.topnav-dropdown-header .notification-title {
  color: #333333;
  display: block;
  float: left;
  font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 11px;
  text-transform: uppercase;
}

.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}

.notifications .notification-list li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}

.notifications .notification-list li a {
  display: block;
  padding: 12px;
  border-radius: 2px;
}

.notifications .notification-list li .noti-title {
  color: #333333;
}

.notifications .notification-list li .noti-details {
  color: #989c9e;
  margin: 0;
}

.notifications .notification-list li p.noti-time {
  margin: 0;
}

.notifications .notification-list li .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}

.notifications .notification-list li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}

.notifications .notification-list li .list-item .list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}

.notifications .notification-list li .list-item .list-body {
  padding: 0 0 0 50px;
}

.notifications .notification-list li .list-item .list-body .message-author {
  color: #333333;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notifications .notification-list li .list-item .list-body .message-time {
  color: #888888;
  float: right;
  font-size: 11px;
}

.notifications .notification-list li .list-item .list-body .message-content {
  color: #333333;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topnav-dropdown-footer {
  border-top: 1px solid #eeeeee;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}

.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333333;
}

.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}

.list-body {
  padding: 0 0 0 50px;
}

.list-body .message-author {
  color: #333333;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-body .message-time {
  color: #888888;
  float: right;
  font-size: 11px;
}

.list-body .message-content {
  color: #333333;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ribbon-primary {
  background: #ff9b44;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #ff9b44;
  border-left-color: #ff9b44;
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #ff9b44;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ff9b44;
}

.ribbon-primary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #43f;
  border-right-color: #43f;
}

.ribbon-primary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #43f;
  border-left-color: #43f;
}

.ribbon-primary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #43f;
  border-right-color: #43f;
}

.ribbon-primary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #43f;
  border-right-color: transparent;
  border-left-color: #43f;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #ff9b44;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #ff9b44;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
  border-right-color: #f73164;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #51bb25;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #dc3545;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #ff9b44;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-light:before {
  border-right-color: #f4f4f4;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #f8d62b;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-secondary:before {
  border-color: #f73164;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #51bb25;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #dc3545;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #ff9b44;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-light:before {
  border-color: #f4f4f4;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-dark:before {
  border-color: #2c323f;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #f8d62b;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
  border-right-color: #ff9b44;
  border-bottom-color: transparent;
}

.ribbon-secondary {
  background: #f73164;
  background: #f73164;
}

.ribbon-secondary.ribbon-corner:before {
  border-top-color: #f73164;
  border-left-color: #f73164;
}

.ribbon-secondary.ribbon-corner.ribbon-right:before {
  border-right-color: #f73164;
  border-left-color: transparent;
}

.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #f73164;
}

.ribbon-secondary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #ec0944;
  border-right-color: #ec0944;
}

.ribbon-secondary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #ec0944;
  border-left-color: #ec0944;
}

.ribbon-secondary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ec0944;
  border-right-color: #ec0944;
}

.ribbon-secondary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #ec0944;
  border-right-color: transparent;
  border-left-color: #ec0944;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
  border-right-color: #f73164;
  border-bottom-color: transparent;
}

.ribbon-success {
  background: #51bb25;
  background: #51bb25;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #51bb25;
  border-left-color: #51bb25;
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #51bb25;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #51bb25;
}

.ribbon-success.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #3f901d;
  border-right-color: #3f901d;
}

.ribbon-success.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #3f901d;
  border-left-color: #3f901d;
}

.ribbon-success.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #3f901d;
  border-right-color: #3f901d;
}

.ribbon-success.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #3f901d;
  border-right-color: transparent;
  border-left-color: #3f901d;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
  border-right-color: #51bb25;
  border-bottom-color: transparent;
}

.ribbon-danger {
  background: #dc3545;
  background: #dc3545;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #dc3545;
  border-left-color: #dc3545;
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #dc3545;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #dc3545;
}

.ribbon-danger.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #bd2130;
  border-right-color: #bd2130;
}

.ribbon-danger.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #bd2130;
  border-left-color: #bd2130;
}

.ribbon-danger.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #bd2130;
  border-right-color: #bd2130;
}

.ribbon-danger.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #bd2130;
  border-right-color: transparent;
  border-left-color: #bd2130;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
  border-right-color: #dc3545;
  border-bottom-color: transparent;
}

.ribbon-info {
  background: #ff9b44;
  background: #ff9b44;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #ff9b44;
  border-left-color: #ff9b44;
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #ff9b44;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ff9b44;
}

.ribbon-info.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #9106e7;
  border-right-color: #9106e7;
}

.ribbon-info.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #9106e7;
  border-left-color: #9106e7;
}

.ribbon-info.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #9106e7;
  border-right-color: #9106e7;
}

.ribbon-info.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #9106e7;
  border-right-color: transparent;
  border-left-color: #9106e7;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-right-color: #ff9b44;
  border-bottom-color: transparent;
}

.ribbon-light {
  background: #f4f4f4;
  background: #f4f4f4;
}

.ribbon-light.ribbon-corner:before {
  border-top-color: #f4f4f4;
  border-left-color: #f4f4f4;
}

.ribbon-light.ribbon-corner.ribbon-right:before {
  border-right-color: #f4f4f4;
  border-left-color: transparent;
}

.ribbon-light.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #f4f4f4;
}

.ribbon-light.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #dbdbdb;
  border-right-color: #dbdbdb;
}

.ribbon-light.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #dbdbdb;
  border-left-color: #dbdbdb;
}

.ribbon-light.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #dbdbdb;
  border-right-color: #dbdbdb;
}

.ribbon-light.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #dbdbdb;
  border-right-color: transparent;
  border-left-color: #dbdbdb;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
  border-right-color: #f4f4f4;
  border-bottom-color: transparent;
}

.ribbon-dark {
  background: #2c323f;
  background: #2c323f;
}

.ribbon-dark.ribbon-corner:before {
  border-top-color: #2c323f;
  border-left-color: #2c323f;
}

.ribbon-dark.ribbon-corner.ribbon-right:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}

.ribbon-dark.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2c323f;
}

.ribbon-dark.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #171a21;
  border-left-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: transparent;
  border-left-color: #171a21;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-bottom-color: transparent;
}

.ribbon-warning {
  background: #f8d62b;
  background: #f8d62b;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #f8d62b;
  border-left-color: #f8d62b;
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #f8d62b;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #f8d62b;
}

.ribbon-warning.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #e8c308;
  border-right-color: #e8c308;
}

.ribbon-warning.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #e8c308;
  border-left-color: #e8c308;
}

.ribbon-warning.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #e8c308;
  border-right-color: #e8c308;
}

.ribbon-warning.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #e8c308;
  border-right-color: transparent;
  border-left-color: #e8c308;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
  border-right-color: #f8d62b;
  border-bottom-color: transparent;
}

.ribbon-wrapper {
  position: relative;
  padding-top: 40px;
}

.ribbon-wrapper .card-body,
.ribbon-vertical-left-wrapper .card-body,
.ribbon-vertical-right-wrapper .card-body {
  position: unset;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: 0px;
  color: #fff;
  z-index: 1;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-vertical-left-wrapper {
  padding-left: 40px;
  position: relative;
}

.ribbon-vertical-left {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-right-wrapper {
  padding-right: 40px;
}

.ribbon-vertical-right {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  top: -2px;
  left: auto;
  right: 12px;
}

.timeline {
  position: relative;
  padding: 20px 0 20px;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #e9ecef;
}

.timeline>li {
  position: relative;
  margin-bottom: 20px;
}

.timeline>li:after {
  clear: both;
  clear: both;
}

.timeline>li>.timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline>li>.timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-top: 8px solid transparent;
  border-right: 0 solid #e9ecef;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #e9ecef;
}

.timeline>li>.timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #fff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
}

.timeline>li>.timeline-badge {
  z-index: 1;
  position: absolute;
  top: 16px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
  content: " ";
  display: table;
}

.timeline-badge.primary {
  background-color: #fb9678;
}

.timeline-badge.success {
  background-color: #00c292;
}

.timeline-badge.warning {
  background-color: #fec107;
}

.timeline-badge.danger {
  background-color: #e46a76;
}

.timeline-badge.info {
  background-color: #03a9f3;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}

.timeline-body>p,
.timeline-body>ul {
  margin-bottom: 0;
}

.timeline-body>p+p {
  margin-top: 5px;
}

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.cd-horizontal-timeline .events {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 30px;
  height: 2px;
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}

.cd-horizontal-timeline .events a {
  padding-bottom: 6px;
  color: #ff9b44;
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  padding-bottom: 15px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}

.cd-horizontal-timeline .events a.selected::after {
  border-color: #ff9b44;
  background-color: #ff9b44;
  border-color: #ff9b44;
}

.cd-horizontal-timeline .events a::after {
  content: "";
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  background-color: #f8f8f8;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}

.cd-horizontal-timeline .events a.older-event::after {
  border-color: #ff9b44;
}

.cd-horizontal-timeline .m-t-40 {
  margin-top: 25px !important;
}

.cd-horizontal-timeline table {
  border-collapse: collapse;
  border-spacing: 0;
}

.cd-horizontal-timeline::before {
  content: "mobile";
  display: none;
}

.cd-horizontal-timeline.loaded {
  opacity: 1;
}

.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.cd-horizontal-timeline .timeline:before {
  content: " ";
  display: none;
  bottom: 0;
  left: 0%;
  width: 0px;
  margin-left: -1.5px;
  background-color: #eeeeee;
}

.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden;
}

.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
}

.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
}

.cd-horizontal-timeline .filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #ff9b44;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}

.cd-horizontal-timeline .events-content li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0 5%;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.cd-horizontal-timeline .events-content li.selected {
  position: relative;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.cd-horizontal-timeline .events-content li>* {
  max-width: 800px;
  margin: 0 auto;
}

.cd-horizontal-timeline .events-content h2 {
  font-weight: 600;
  margin-bottom: 0px;
}

.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto;
}

.cd-horizontal-timeline .events-content em::before {
  content: "- ";
}

.cd-horizontal-timeline .events-content p {
  font-size: 16px;
}

.cd-horizontal-timeline .filling-line,
.cd-horizontal-timeline .events a.selected::after {
  background: #ff9b44;
}

.cd-horizontal-timeline ol,
.cd-horizontal-timeline ul {
  list-style: none;
}

.cd-timeline-navigation a:hover,
.cd-timeline-navigation a:focus {
  border-color: #ff9b44;
}

.cd-horizontal-timeline a,
.cd-horizontal-timeline a:hover,
.cd-horizontal-timeline a:focus {
  color: #ff9b44;
}

.cd-horizontal-timeline blockquote,
.cd-horizontal-timeline q {
  quotes: none;
}

.cd-horizontal-timeline blockquote:before,
.cd-horizontal-timeline blockquote:after,
.cd-horizontal-timeline q:before,
.cd-horizontal-timeline q:after {
  content: "";
  content: none;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #ff9b44;
  border-color: #ff9b44;
}

.no-touch .cd-timeline-navigation a:hover {
  border-color: #7b9d6f;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.cd-timeline-navigation a {
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.cd-timeline-navigation a::after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(../img/arrow.svg) no-repeat 0 0;
}

.cd-timeline-navigation a.prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
  right: 0;
}

.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}

.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
  line-height: 30px;
}

.h-250 {
  height: 250px;
}

.icons-list {
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.icons-list li {
  display: block;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  border-radius: 50px;
  box-shadow: 0px 2px 3px rgb(215, 197, 255);
  border: 1px solid #e6ebf1;
  color: #ff9b44;
  margin: 5px;
}

.custom-file-container {
  box-sizing: border-box;
  position: relative;
  display: block;
}

.custom-file-container label {
  color: #ff9b44;
}

.custom-file-container__custom-file {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  margin-top: 5px;
}

.custom-file-container__custom-file:hover {
  cursor: pointer;
}

.custom-file-container__custom-file__custom-file-input {
  box-sizing: border-box;
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-container__custom-file__custom-file-input:focus~span {
  outline: 1px dotted #dddde0;
  outline: 0px auto -webkit-focus-ring-color;
}

.custom-file-container__custom-file__custom-file-control {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  overflow: hidden;
  line-height: 1.5;
  user-select: none;
  background-clip: padding-box;
  border-radius: 0.25rem;
  height: auto;
  border: 1px solid #f1f2f3;
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  background-color: #f1f2f3;
}

.custom-file-container__custom-file__custom-file-control__button {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  display: block;
  height: auto;
  padding: 10px 16px;
  line-height: 1.25;
  background-color: #bdbdbd;
  color: #ffffff;
  border-left: 1px solid #e0e6ed;
  box-sizing: border-box;
}

.custom-file-container__image-preview {
  box-sizing: border-box;
  margin-top: 54px;
  margin-bottom: 40px;
  height: 250px;
  width: 100%;
  border-radius: 4px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  overflow: auto;
  padding: 15px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom-file-container__image-multi-preview {
  position: relative;
  box-sizing: border-box;
  border-radius: 6px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  float: left;
  margin: 1.858736%;
  width: 29.615861214%;
  height: 90px;
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom-file-container__image-multi-preview__single-image-clear {
  background: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  margin-top: -6px;
  border-radius: 50%;
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
  position: absolute;
  left: -6px;
}

.custom-file-container__image-multi-preview__single-image-clear:hover {
  background: #cbcbbd;
  cursor: pointer;
}

.custom-file-container__image-multi-preview__single-image-clear__icon {
  color: #ff9b44;
  display: block;
  margin-top: -2px;
}

.note-editor.card {
  padding: 0.5rem;
  margin-bottom: 0;
}

.note-toolbar.card-header {
  padding: 0;
  padding-bottom: 0.5rem;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child,
.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
  background-color: #ff9b44;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: #ff9b44;
}

.note-editor.note-frame .note-statusbar .note-resizebar {
  padding-top: 0;
  height: 0;
  width: 100%;
  cursor: ns-resize;
  background: transparent;
  border: 0;
}

.alertify .ajs-footer {
  border-top: 1px solid #eee;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
  font-weight: 500;
  background-color: transparent;
  color: #000;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  color: #ff9b44;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: right;
}

.alertify .ajs-body .ajs-content .ajs-input {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 4px;
  border-radius: 2px;
  border: 1px solid #ccc;
}

.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
  outline: 0;
}

.alertify .ajs-commands {
  right: 4px;
  left: auto;
  margin: -14px 0 0 24px;
}

.alertify .ajs-header {
  border-bottom: 1px solid #eee;
}

.alertify-notifier .ajs-message {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #ffffff;
  text-shadow: none !important;
}

.alertify-notifier .ajs-message.ajs-success {
  background-color: #26af48;
  border-color: #26af48;
}

.alertify-notifier .ajs-message.ajs-error {
  background-color: #f83f37;
  border-color: #f83f37;
}

.alertify-notifier .ajs-message.ajs-warning {
  background-color: #ffbf53;
  border-color: #ffbf53;
}

.alertify-notifier.ajs-right {
  right: 10px;
  left: auto;
}

.alertify-notifier.ajs-right .ajs-message {
  right: -320px;
  left: auto;
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
  right: 290px;
  left: auto;
}

.alertify-notifier.ajs-left {
  left: 10px;
  right: auto;
}

.alertify-notifier.ajs-left .ajs-message {
  left: -300px;
  right: auto;
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
  left: 0;
  right: auto;
}

body[data-layout-mode=dark] .alertify .ajs-dialog {
  background-color: #313533;
}

body[data-layout-mode=dark] .alertify .ajs-body {
  color: #adb5bd;
}

body[data-layout-mode=dark] .alertify .ajs-body .ajs-content .ajs-input {
  color: #adb5bd;
  background-color: #363a38;
  border: 1px solid #3b403d;
}

body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button {
  color: #adb5bd;
}

body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
  color: #ff9b44;
}

body[data-layout-mode=dark] .alertify .ajs-footer,
body[data-layout-mode=dark] .alertify .ajs-header {
  color: #ced4da;
  background-color: #313533;
  border-color: #3b403d;
}

.swal2-actions button {
  margin-right: 10px;
}

.swal2-title {
  font-size: 22px !important;
}

.wizard {
  position: relative;
}

.wizard .nav-tabs {
  position: relative;
  margin-top: 20px;
  border: 0;
}

.wizard .nav-tabs li:after {
  content: "";
  width: 75%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  background: #e9e9ef;
  height: 2px;
  left: -30%;
  top: 38%;
  z-index: 1;
}

@media (max-width: 992px) {
  .wizard .nav-tabs li:after {
    width: 60%;
    left: -22%;
  }
}

@media (max-width: 575px) {
  .wizard .nav-tabs li:after {
    background: transparent;
    display: none;
  }
}

.wizard .nav-tabs li:first-child:after {
  background: transparent;
  display: none;
}

.wizard .nav-item {
  position: relative;
}

.wizard .nav-item .nav-link {
  width: 56px;
  height: 56px;
  content: "";
  position: absolute;
  background: #fff;
  left: 50%;
  color: #5156be;
  top: 50%;
  font-size: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid rgba(81, 86, 190, 0.2);
}

.wizard .nav-item .nav-link.active {
  background: #e9e9ef;
  color: #5156be;
  border: 1px solid rgba(81, 86, 190, 0.2);
}

@media (max-width: 768px) {
  .wizard .nav-item .nav-link {
    background: transparent;
  }
}

.wizard .tab-content {
  padding-top: 50px;
}

.wizard .form-control {
  border-radius: 4px;
}

.wizard .form-select {
  border: 1px solid #E0E0E0;
  box-shadow: none;
  border-radius: 4px;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #878A99;
}

.wizard .form-select:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #878A99;
  border-radius: 4px;
}


/* Attendance */

.attendance-table .custom-table .tick {
  color: green;
  font-weight: 400;
  font-size: 14px;
}

.attendance-table .custom-table .wrong {
  color: #ee0606;
  font-weight: 400;
  font-size: 16px;
}

.attendance-table {
  height: calc(100vh - 290px);
  overflow-x: auto; 
}

.attendance-table table thead{
  position: sticky !important;
  top: 0px;
  z-index: 999;
  border-top: 1px solid white !important;
  background-color: white;
}

.attendance-table table thead th{
  border-top: none !important;
}

.attendance-table table thead th:first-child{
  position: sticky !important;
  left: 0px;
}

.attendance-table table tbody td:first-child{
  position: sticky !important;
  left: 0px;
}



.attendance-table table {
  width: 100%;
  padding: 0;
}

.form-focus .focus-one {
  padding-top: 14px !important;
}

/* End */

/* Project */
.view-top-icon{
  float: right !important;
  position: absolute !important;
  right: 177px;
  top: 100px;
}
.date-control{
  height: 50px !important;
}
/* End */
